import React from 'react'
import { useContext } from 'react'
import UserContext from 'src/contexts/user/UserContext.tsx'
import classNames from 'src/tools/classNames'

type Props = {
  size: number
}

/**
 * Profile picture component.
 * Currently just user's initials.
 */
export default function ProfilePicture({
  size = 24
}: Props) {
  const { user } = useContext<UserContext>(UserContext)
  return (
    <div
      className={classNames(
        "rounded-full bg-primary-rose text-white flex items-center justify-center",
        "transition-all duration-200",
        "ml-2",
      )}
      style={{
        width: size,
        height: size,
        fontSize: size * 0.4
      }}
    >
      {user?.firstName?.length > 0 && user.firstName.charAt(0)}
      {user?.lastName?.length > 0 && user.lastName.charAt(0)}
    </div>
  )
}
