import React, { useEffect, useRef, useState } from "react";
import Modal, { ModalFooter } from "src/components/Modal";
import OrderModalHeader from "../common/OrderModalHeader.tsx";
import Input from "src/components/input/Input.js";
import moment from "moment";
import { TruckIcon } from "lucide-react";
import Button from "src/components/input/Button.js";
import classNames from "src/tools/classNames";
import TiptapEditor, {
  TiptapEditorRef,
} from "src/components/input/RichTextEditor/TiptapEditor.tsx";
import { PhotoIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import Spinner from "src/components/Spinner.js";
import S3, { OrderFileType, S3File } from "src/tools/S3/s3.ts";
import UserManager from "src/tools/UserManager.js";
import {
  MaterialAccuracyStatus,
  Order,
  OrderStatus,
} from "src/hooks/data/orders/useOrders.ts";

type MarkAsDeliveredModalProps = {
  order: Order;
  open: boolean;
  setOpen: (open: boolean) => void;
};

/**
 * Modal for marking an order as delivered from the web app
 * Only READY_TO_DELIVER and IN_TRANSIT orders can be marked as delivered
 * User provites a date and time for the delivery, and whether the material was on time and complete
 * User can upload optional delivery photos, and add notes that have image and file attachments
 *
 * @param order - the order to mark as delivered
 * @param open - whether the modal is open
 * @param setOpen - function to set the modal open state
 */
export default function MarkAsDeliveredModal({
  order,
  open,
  setOpen,
}: MarkAsDeliveredModalProps) {
  const [deliveredDate, setDeliveredDate] = useState<string | null>(null);
  const [deliveredTime, setDeliveredTime] = useState<string | null>(null);
  const [materialOnTime, setMaterialOnTime] = useState<boolean | null>(null);
  const [materialsStatus, setMaterialsStatus] = useState<string>(
    MaterialAccuracyStatus.NONE
  );
  const [usePhotos, setUsePhotos] = useState<boolean>(true);
  const [photos, setPhotos] = useState<S3File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const editorRef = useRef<TiptapEditorRef>(null);

  /**
   * Get the existing delivery images for the order
   */
  useEffect(() => {
    getDeliveryImages();
  }, [order._id]);

  /**
   * Set the usePhotos state to true if there are photos
   */
  useEffect(() => {
    if (photos.length > 0) {
      setUsePhotos(true);
    }
  }, [photos]);

  /**
   * Get the images for the current issue
   *
   * @returns the get and delete presigned urls of the images
   */
  async function getDeliveryImages() {
    try {
      let presignedUrls = await S3.getOrderImageLinks(
        OrderFileType.DELIVERY_IMG,
        order._id
      );
      if (presignedUrls) {
        setPhotos(presignedUrls);
      }
    } catch (err) {
      console.error("error getting images", err);
      return null;
    }
  }

  /**
   * Upload an image to the current issue
   *
   * @param image - the image to upload
   * @returns the get and delete presigned urls of the image
   */
  async function uploadImage(
    image: File,
    type: OrderFileType,
    issueId: string | null = null,
    noteId: string | null = null
  ) {
    let putObjectSignedUrl = await S3.getOrderFileUploadUrl(
      type,
      order._id,
      issueId,
      noteId,
      image.name
    );

    // create a blob from the image
    let blob = new Blob([image], { type: image.type });

    // convert blob to arrayBuffer
    let arrayBuffer = await blob.arrayBuffer();

    // upload image to s3
    if (putObjectSignedUrl !== "") {
      await fetch(putObjectSignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "image/jpeg",
        },
        body: arrayBuffer,
      }).catch((error) => {
        console.error("error uploading image", error);
        return null;
      });

      if (type === OrderFileType.DELIVERY_IMG) {
        getDeliveryImages();
      }
    }
  }

  /**
   * Upload a file to the current issue
   *
   * @param file - the file to upload
   * @returns the get and delete presigned urls of the file
   */
  async function uploadFile(
    file: File,
    type: OrderFileType,
    issueId: string | null = null,
    noteId: string | null = null
  ) {
    let putObjectSignedUrl = await S3.getOrderFileUploadUrl(
      type,
      order._id,
      issueId,
      noteId,
      file.name
    );

    if (putObjectSignedUrl) {
      try {
        await fetch(putObjectSignedUrl, {
          method: "PUT",
          body: file,
        });
      } catch (error) {
        console.error("error uploading file", error);
      }
    }
  }

  /**
   * Open file explorer and allow user to select a file
   */
  async function addFile(type: "image" | "file") {
    // TODO: use ref for a hidden input instead of creating a new one
    const file = document.createElement("input");
    file.type = "file";
    file.accept = type === "image" ? "image/*" : "*/*";
    file.multiple = true;
    file.onchange = async (e) => {
      // get the files
      const newFiles = (e.target as HTMLInputElement).files;
      if (newFiles) {
        // upload the image, if successful, add the image to the images state
        for (const file of newFiles) {
          if (type === "image") {
            uploadImage(file, OrderFileType.DELIVERY_IMG, null, null);
          }
        }
      }
    };
    file.click();
  }

  /**
   * Delete a file from the editor
   *
   * @param deleteObjectSignedUrl - the signed url of the file to delete
   * @param type - the type of file to delete
   */
  async function deleteFile(
    deleteObjectSignedUrl: string,
    type: "image" | "file"
  ) {
    var success = false;
    await fetch(deleteObjectSignedUrl, {
      method: "DELETE",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          // if the response is successful, remove the file from the state
          if (type === "image") {
            setPhotos(
              photos.filter(
                (photo) => photo.deleteObjectSignedUrl !== deleteObjectSignedUrl
              )
            );
          }
        }
      })
      .catch((error) => {
        console.error("error deleting image", error);
      });

    return success;
  }

  /**
   * Update the order with the given materials status and order status
   *
   * @returns "success" if the order was updated successfully, otherwise null
   */
  async function updateOrder() {
    try {
      // update the material status for the driver
      var materialsStatusRes = await UserManager.makeAuthenticatedRequest(
        "/api/orders/update-materials-status",
        "POST",
        {
          orderId: order._id,
          materialsStatus,
          missingOrExtraItems: "",
          note: "",
        }
      );
    } catch (error) {
      console.error("error updating materials status", error);
      return;
    }

    if (materialsStatusRes?.data?.status !== "ok") {
      console.error(
        "error updating materials status",
        materialsStatusRes?.data?.error
      );
      return;
    }

    const deliveryTimestamp = moment(
      `${deliveredDate} ${deliveredTime}`,
      "YYYY-MM-DD HH:mm"
    ).toDate();

    try {
      // update the order status to DELIVERED
      var statusRes = await UserManager.makeAuthenticatedRequest(
        "/api/orders/update-status",
        "POST",
        {
          orderId: order._id,
          newStatus: OrderStatus.DELIVERED,
          deliveryTimestamp: deliveryTimestamp,
        }
      );
    } catch (error) {
      console.error("error updating status", error);
      return;
    }

    if (statusRes?.data?.status === "ok") {
      return "success";
    }
  }

  /**
   * Handle the closing of the modal
   * reset the state
   */
  function handleOnClose() {
    setOpen(false);
    setDeliveredDate(null);
    setDeliveredTime(null);
    setMaterialOnTime(null);
    setMaterialsStatus(null);
    setUsePhotos(false);
    setPhotos([]);
  }

  /**
   * Set the delivered date and time to the current date and time
   */
  function handleSetToNow() {
    setDeliveredDate(moment().format("YYYY-MM-DD"));
    setDeliveredTime(moment().format("HH:mm"));
  }

  /**
   * Handle the submission of the modal
   * update the order and submit the note
   * if the order was updated successfully, submit the note
   */
  async function handleSubmit() {
    const result = await updateOrder();
    // setLoading(true);
    if (editorRef.current && result === "success") {
      await editorRef.current.submit();
    }

    if (result === "success") {
      handleOnClose();
    }
  }

  /**
   * Submit a note to the order with the given html for the TiptapEditor, images, and files
   * upload the images and files to the order and add their filePaths to the note
   *
   * @param html - the html of the note
   * @param images - the images to upload
   * @param files - the files to upload
   */
  async function submitNote(html: string, images: File[], files: File[]) {
    // if html is just whitespace inside of a paragraph tag, and there are no images or files, don't submit the note
    if (
      html.replace(/\s/g, "") === "<p></p>" &&
      !images.length &&
      !files.length
    ) {
      return;
    }

    const imageNames = images.map((image) => image.name);
    const fileNames = files.map((file) => file.name);

    try {
      var res = await UserManager.makeAuthenticatedRequest(
        "/api/orders/add-note",
        "POST",
        {
          orderId: order._id,
          note: html,
          images: imageNames,
          files: fileNames,
        }
      );
    } catch (error) {
      console.error("error submitting note", error);
    }

    if (res?.data?.status === "ok" && res?.data?.note) {
      const noteId = res.data.note._id;
      for (const image of images) {
        await uploadImage(image, OrderFileType.NOTE_IMG, null, noteId);
      }
      for (const file of files) {
        await uploadFile(file, OrderFileType.NOTE_FILE, null, noteId);
      }
    } else {
      console.error("error submitting note", res.data.error);
    }
  }

  const canSubmit =
    deliveredDate &&
    deliveredTime &&
    materialOnTime !== null &&
    materialsStatus !== null &&
    (!usePhotos || photos.length > 0);

  return (
    <Modal open={open} setOpen={handleOnClose} wide>
      <div className="min-w-[620px]">
        <OrderModalHeader order={order} title="Mark as Delivered" />
        <div className="flex flex-col gap-4 py-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center gap-2">
              <TruckIcon className="text-gray-700" />
              <div className="text-lg font-bold align-middle">
                Delivery Time
              </div>
            </div>
            <div className="flex flex-row gap-2">
              <Button
                onClick={handleSetToNow}
                className="!text-primary-green !bg-[#D1FDFF] hover:!bg-[#B5F5F8] shadow-none"
              >
                Set to Now
              </Button>
              <Input
                className="!w-36"
                type="date"
                name="deliveredDate"
                // display in the format "Friday, June 2"
                value={deliveredDate}
                onChange={(value) => {
                  let formatted = value
                    ? moment(value).format("YYYY-MM-DD")
                    : value;
                  setDeliveredDate(formatted);
                }}
              />
              <Input
                className="!w-36"
                type="time"
                name="deliveredTime"
                value={deliveredTime}
                onChange={(value) => {
                  setDeliveredTime(value);
                }}
              />
            </div>
          </div>

          {/* material on time buttons */}
          <div className="flex flex-col items-start gap-2">
            <div className="text-lg font-bold align-middle">
              Was material on time?
            </div>
            <div className="flex flex-row gap-2">
              <Button
                variant="secondary"
                className={classNames(
                  "w-36",
                  materialOnTime ? "!bg-primary-green !text-white" : "!bg-white"
                )}
                onClick={() => setMaterialOnTime(true)}
              >
                Yes
              </Button>
              <Button
                variant="secondary"
                className={classNames(
                  "w-36",
                  materialOnTime === null || materialOnTime
                    ? "!bg-white"
                    : "!bg-primary-rose !text-white"
                )}
                onClick={() => setMaterialOnTime(false)}
              >
                No
              </Button>
            </div>
          </div>

          {/* missing or extra materials buttons */}
          <div className="flex flex-col items-start gap-2">
            <div className="text-lg font-bold align-middle">
              Anything missing or extra?
            </div>
            <div className="flex flex-row gap-2">
              <Button
                variant="secondary"
                className={classNames(
                  "w-36",
                  materialsStatus === MaterialAccuracyStatus.INCOMPLETE
                    ? "!bg-primary-rose !text-white"
                    : "!bg-white"
                )}
                onClick={() =>
                  setMaterialsStatus(MaterialAccuracyStatus.INCOMPLETE)
                }
              >
                Yes
              </Button>
              <Button
                variant="secondary"
                className={classNames(
                  "w-36",
                  materialsStatus === MaterialAccuracyStatus.COMPLETE
                    ? "!bg-primary-green !text-white"
                    : "!bg-white"
                )}
                onClick={() =>
                  setMaterialsStatus(MaterialAccuracyStatus.COMPLETE)
                }
              >
                No
              </Button>
            </div>
          </div>

          {/* delivery photos */}
          <div className="flex flex-col items-start w-full gap-2">
            <div className="flex flex-row justify-between w-full">
              <div className="text-sm font-semibold align-middle">
                Delivery Photos
              </div>
              <div className="flex flex-row items-center gap-2">
                <div className="text-sm font-medium align-middle">
                  I don't want to add photos
                </div>
                <div>
                  <input
                    type="checkbox"
                    className="w-4 h-4 align-middle border-gray-300 rounded text-primary-green focus:ring-primary-green"
                    checked={!usePhotos}
                    onChange={() => {
                      if (photos.length === 0) {
                        setUsePhotos(!usePhotos);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row flex-wrap gap-2 max-h-[300px] overflow-y-auto w-full">
              <AddImageButton onClick={() => addFile("image")} />
              {photos.map((photo) => (
                <ImageDisplay
                  key={photo.getObjectSignedUrl}
                  image={photo}
                  deletable={true}
                  onImageDelete={() =>
                    deleteFile(photo.deleteObjectSignedUrl, "image")
                  }
                  onImageClick={(image) => {
                    window.open(image.getObjectSignedUrl, "_blank");
                  }}
                />
              ))}
            </div>
          </div>

          {/* notes */}
          <div className="flex flex-col items-start gap-2">
            <div className="text-sm font-semibold align-middle">Notes</div>
            <TiptapEditor
              ref={editorRef}
              submitButton={false}
              uploadImagesImmediately={false}
              uploadFilesImmediately={false}
              onSubmit={async (html, images, files) => {
                await submitNote(html, images, files);
              }}
            />
          </div>
        </div>
      </div>
      <ModalFooter>
        <div className="flex items-center justify-end gap-2">
          <Button variant="secondary" onClick={handleOnClose}>
            Cancel
          </Button>
          {!loading ? (
            <Button
              variant="primary"
              disabled={!canSubmit}
              onClick={handleSubmit}
            >
              Mark as Delivered
            </Button>
          ) : (
            <div className="flex items-center pr-7">
              <Spinner size={20} />
            </div>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
}

/**
 * Button for adding an image to the order
 *
 * @param onClick - function to call when the button is clicked
 */
function AddImageButton({ onClick }: { onClick: (type: string) => void }) {
  return (
    <div
      className="relative flex flex-row items-center justify-center gap-2 hover:cursor-pointer group w-36 h-36"
      onClick={() => onClick("image")}
    >
      <PhotoIcon className="w-24 text-gray-300 group-hover:text-gray-200" />
      <div className="absolute transform -translate-x-1/2 bottom-5 left-1/2">
        <PlusCircleIcon className="w-10 h-10 bg-white rounded-full -px-1 text-primary-green group-hover:text-primary-green-300" />
      </div>
    </div>
  );
}

/**
 * Displays a single image in the Photos section.
 */
function ImageDisplay({ image, deletable, onImageDelete, onImageClick }) {
  return (
    <div
      className="relative flex items-center justify-center bg-gray-200 rounded-md cursor-pointer w-36 h-36"
      onClick={() => onImageClick(image)}
    >
      <img
        src={image.getObjectSignedUrl}
        className="object-cover h-36 aspect-[4/3] rounded-md"
      />
      <div
        className={classNames(deletable ? "" : "hidden")}
        onClick={(e) => {
          e.stopPropagation();
          onImageDelete(image);
        }}
      >
        <div className="absolute bottom-0 right-0 items-center justify-center bg-gray-100 opacity-75 h-9 w-9 rounded-tl-md rounded-br-md" />
        <div className="absolute bottom-1.5 right-1.5 cursor-pointer">
          <TrashIcon className="w-6 h-6 text-red-700 hover:text-red-500" />
        </div>
      </div>
    </div>
  );
}
