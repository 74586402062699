import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { Order, Note as NoteType } from "src/hooks/data/orders/useOrders";
import useUsersByIds, { UserMap } from "src/hooks/data/users/useUsersByIds.ts";
import TipTapEditor from "src/components/input/RichTextEditor/TiptapEditor.tsx";
import S3, { S3File } from "src/tools/S3/s3.ts";
import {
  ImageDisplay,
  FileDisplay,
} from "src/components/input/RichTextEditor/Utils.tsx";
import classNames from "src/tools/classNames";
type Props = {
  order: Order;
};

/**
 * Component for displaying a list of notes on an order.
 */
export default function OrderNotes({ order }: Props) {
  const users = useUsersByIds(order?.notes?.map((note) => note.addedBy));

  if (!order.notes.length) {
    return (
      <span className="text-sm italic font-normal text-gray-500">No notes</span>
    );
  }

  const notes: NoteType[] = order.notes;
  notes.sort((a, b) => {
    return new Date(a.addedAt).getTime() - new Date(b.addedAt).getTime();
  });

  return (
    <div className="grid gap-2 grid-cols-[auto,1fr] relative">
      {order.notes.map((note, index) => (
        <Note
          note={note}
          hideLine={index === order.notes.length - 1}
          users={users}
        />
      ))}
    </div>
  );
}

type NoteProps = {
  note: NoteType;
  hideLine?: boolean;
  users: UserMap;
};

/**
 * Displays a single note for an order.
 * Shows user name and timestamp.
 *
 * Shows a dot and line to the left to indicate
 * a timeline.
 */
function Note({ note, hideLine = false, users }: NoteProps) {
  let stamp = note.addedAt ? moment(note.addedAt) : null;

  // Get user name but skeleton load with random length
  // TODO: can probably improve. randomizes each render which doesn't look great
  let userName: ReactNode = (
    <span className="w-10 h-4 bg-gray-300 rounded-full animate-pulse text-white/0">
      {Array(Math.ceil(Math.random() * 4) + 5).fill("a")}
    </span>
  );
  if (users) {
    const userObj = users[note.addedBy];
    userName = userObj ? (
      `${userObj.firstName} ${userObj.lastName.charAt(0)}.`
    ) : (
      <span className="italic"> Missing User</span>
    );
  }

  const [images, setImages] = useState<S3File[]>([]);
  const [files, setFiles] = useState<S3File[]>([]);

  useEffect(() => {
    const fetchImages = async () => {
      const images = await S3.get(note.images.map((image) => image.filePath));
      setImages(images);
    };

    fetchImages();
  }, [note.images]);

  useEffect(() => {
    const fetchFiles = async () => {
      const files = await S3.get(note.files.map((file) => file.filePath));
      setFiles(files);
    };

    fetchFiles();
  }, [note.files]);

  return (
    <div className="grid w-full text-sm font-normal text-gray-500 group col-span-full grid-cols-subgrid">
      {/* Dot */}
      <div className="relative flex flex-col items-center gap-1 top-1">
        <div className="w-3 h-3 bg-gray-500 rounded-full"></div>
        {!hideLine && (
          <div className="w-0.5 grow bg-gray-300 relative top-0.5"></div>
        )}
      </div>
      {/* Text */}
      <div className="max-w-[900px]">
        <p className="text-sm font-normal text-gray-500">
          <span className="font-medium text-gray-900">{userName}</span>
          {" - "}
          {stamp ? (
            <span>
              <span className="group-hover:hidden">{stamp.fromNow()}</span>
              <span className="hidden group-hover:inline">
                {stamp.format("M/D/Y h:mma")}
              </span>
            </span>
          ) : (
            "Missing timestamp"
          )}
        </p>
        <TipTapEditor initialContent={note.note} readOnly={true} />
        <div
          className={classNames(
            "flex flex-row gap-3 w-full overflow-x-auto",
            images.length > 0 || files.length > 0 ? "p-2" : ""
          )}
        >
          {images.map((image) => (
            <ImageDisplay
              key={image.getObjectSignedUrl}
              image={image}
              readOnly
              imageLocation="s3"
            />
          ))}
          {files.map((file) => (
            <FileDisplay
              key={file.getObjectSignedUrl}
              file={file}
              readOnly
              fileLocation="s3"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
