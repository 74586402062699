import React, { useMemo, useState } from "react";
import { AlertType } from "src/components/feedback/Alert";
import Alerts from "src/components/feedback/Alerts.tsx";
import Button from "src/components/input/Button";
import Dropdown from "src/components/input/Dropdown";
import Modal, { ModalFooter } from "src/components/Modal";
import Spinner from "src/components/Spinner";
import {
  Order,
  OrderStatus,
  OrderStatusIcons,
  OrderStatusLabels,
  STATUS_ORDER,
  TimestampTypes,
} from "src/hooks/data/orders/useOrders.ts";
import UserManager from "src/tools/UserManager";
import OrderModalHeader from "../common/OrderModalHeader.tsx";
import { MinorBadge } from "../OrderRowItem.tsx";

type Props = {
  order: Order;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const SUPPORT_EMAIL = "support@toa.energy";

/**
 * Order modal for manually changing an order's status. A simple modal
 * as it just has a dropdown for the new status.
 */
export default function AdminChangeStatusModal({
  order,
  open,
  setOpen,
}: Props) {
  const [alerts, setAlerts] = useState<AlertType[]>([]);
  const [newStatus, setNewStatus] = useState<OrderStatus | null>();
  const [loading, setLoading] = useState(false);

  /**
   * Allow the user to select any status that is before the current status.
   */
  const options = useMemo(getOptions, [order.status]);

  const canConfirm = newStatus != null;

  /**
   * Get list of dropdown options to select from. This is previous statuses.
   * If the order is canceled, any status the order has previously been is given.
   * If the order is not canceled, only statuses before the current status are given based on STATUS_ORDER.
   */
  function getOptions(): { label: string; icon: JSX.Element; value: any }[][] {
    const previousStatuses = getPreviousStatuses();
    return [
      previousStatuses.map((status) => ({
        label: OrderStatusLabels[status],
        icon: OrderStatusIcons[status],
        value: status,
      })),
    ];
  }

  /**
   * Returns a list of previous statuses.
   * If the order is canceled, any status the order has previously been is given.
   * If the order is not canceled, only statuses before the current status are given based on STATUS_ORDER.
   */
  function getPreviousStatuses(): OrderStatus[] {
    // If not canceled, get statuses in STATUS_ORDER up to the current status.
    if (order.status !== OrderStatus.CANCELED) {
      return STATUS_ORDER.slice(0, STATUS_ORDER.indexOf(order.status));
    }

    // If canceled, get all statuses the order has been in.
    else {
      let statuses: OrderStatus[] = [];

      // Go through timestamps of order
      // NOTE: this is O(n^2) but n is small so it's fine
      let timestamps = order.timestamps.filter(
        (t) => t.type === TimestampTypes.STATUS_CHANGED,
      );
      for (let t of timestamps) {
        let newStatus = t.typeInfo?.newStatus as OrderStatus;
        let oldStatus = t.typeInfo?.oldStatus as OrderStatus;

        // Add new status if not already in list
        if (!statuses.includes(newStatus)) {
          statuses.push(newStatus);
        }
        // Add old status if not already in list
        if (!statuses.includes(oldStatus)) {
          statuses.push(oldStatus);
        }
      }

      // Don't change from canceled to canceled
      statuses = statuses.filter((s) => s !== OrderStatus.CANCELED);

      // Sort statuses in chronological order
      statuses.sort(
        (a, b) => STATUS_ORDER.indexOf(a) - STATUS_ORDER.indexOf(b),
      );

      return statuses;
    }
  }

  /**
   * Send request to change status. Displays error alert if failed.
   */
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault(); // Don't reload
    setLoading(true); // Start spinner

    // Make request
    let body = {
      orderId: order._id,
      newStatus: newStatus,
    };
    let error = null;
    try {
      var res = await UserManager.makeAuthenticatedRequest(
        "/api/orders/update-status",
        "POST",
        body,
      );
    } catch (err) {
      error = err;
    }

    // Check if failed
    if (res.data?.status != "ok") {
      error = true;
    }

    // Close or display alert
    if (!error) {
      close();
    } else {
      setLoading(false);
      setAlerts((prev) => {
        return [
          ...prev,
          {
            severity: "error",
            title: "Status Change Failed",
            subtext: `Failed to change status. Please try again. If this continues to happen, please contact us at ${SUPPORT_EMAIL}.`,
            stamp: Date.now(),
          },
        ];
      });
    }
  }

  /**
   * Close the modal.
   */
  function close() {
    setOpen(false);
  }

  return (
    <Modal open={open} setOpen={setOpen} wide>
      {/* Alerts */}
      <Alerts
        alerts={alerts}
        onDismiss={(a: AlertType) => {
          setAlerts((prev) => {
            return prev.filter((alert) => alert.stamp !== a.stamp);
          });
        }}
      />

      {/* Header */}
      <OrderModalHeader
        order={order}
        title="Manual Status Change"
        subtitle="Manually set this order's status to a previous status"
      />

      {/* Selection */}
      <form onSubmit={handleSubmit}>
        {/* Input */}
        <div className="flex gap-8 justify-center p-6">
          <div className="space-y-3">
            <p className="text-sm font-medium text-gray-700 dark:text-gray-200">
              Current Status
            </p>
            <MinorBadge>{OrderStatusLabels[order.status]}</MinorBadge>
          </div>
          <div className="self-center">{"->"}</div>
          <Dropdown
            label="New Status"
            justifyLeft
            options={options}
            selectedValue={newStatus}
            onSelected={(option) => setNewStatus(option.value as OrderStatus)}
          />
        </div>

        {/* Footer */}
        <ModalFooter>
          <div className="flex items-center justify-end gap-2">
            {!loading ? (
              <Button type="submit" variant="primary" disabled={!canConfirm}>
                Confirm
              </Button>
            ) : (
              <div className="flex items-center pr-7">
                <Spinner size={20} />
              </div>
            )}
            <Button variant="secondary" onClick={close}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
}
