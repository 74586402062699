import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

const exampleSearchList = [
  { name: "1Solar", permission: "Forecasting", id: "1" },
  { name: "G3 Solar", permission: "Forecasting", id: "2" },
  { name: "Pure Solar", permission: "Forecasting", id: "3" },
  { name: "2Solar", permission: "Forecasting", id: "4" },
  { name: "G4 Solar", permission: "Forecasting", id: "5" },
  { name: "Polluted Solar", permission: "Forecasting", id: "6" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/**
 *
 * @searchValue - value of the search input
 * @setSearchValue - function to set the search value
 * @searchResults - search results that appear as options in the combobox
 * @onSelectItem - function to call when an item is selected
 * @placeholder - placeholder text for the search input
 *
 * @returns - a combobox search bar component that allows users to search for items and select one
 */
export default function ComboboxSearchBar({
  searchValue,
  setSearchValue = (value) => {},
  searchResults,
  onSelectItem = (item) => {},
  placeholder = null,
}) {
  return (
    <Combobox
      value={searchValue}
      onChange={(value) => {
        onSelectItem(value);
      }}
    >
      <div className="relative mt-1">
        <div
          className={classNames(
            "flex w-full px-2 border rounded-lg lg:ml-0",

            // Light Mode
            "bg-white border-gray-200 focus-within:border-gray-400",

            // Dark Mode
            "dark:bg-gray-700 dark:border-gray-400 dark:focus-within:border-gray-400"
          )}
        >
          <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
            <MagnifyingGlassIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <Combobox.Input
            className="w-full py-2 pl-6 pr-8 text-sm leading-5 text-gray-900 border-none focus:ring-0 placeholder:text-gray-400"
            displayValue={searchValue}
            placeholder={placeholder}
            onChange={(event) => {
              setSearchValue(event.target.value);
            }}
          />
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {searchResults.length === 0 && searchValue !== "" ? (
              <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">
                Nothing found.
              </div>
            ) : (
              searchResults.map((item) => (
                <Combobox.Option
                  key={item._id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-teal-600 text-white" : "text-gray-900"
                    }`
                  }
                  value={item}
                >
                  <span className="block font-normal truncate">
                    {item.name}
                  </span>
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
