import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import Button from "../../components/input/Button";
import SearchBar from "../../components/SearchBar";
import FilterSort from "../../components/FilterSort";
import InputTable from "../../components/input/InputTable";
import EmailInput from "../../components/input/EmailInput";
import Dropdown from "../../components/input/Dropdown";
import UserManager from "../../tools/UserManager";
import Modal from "../../components/Modal";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  tableColumns,
  structureRowData,
  sortUsers,
  sendInvites,
  deleteRow,
  archiveUser,
  updateRole,
  updateLocation,
} from "../../tools/teamManagement";
import { getLocations } from "../../tools/locationManagement";
import Spinner from "src/components/Spinner";

const roleMap = {
  admin: "Admin",
  member: "Member",
  operations: "Operations",
  warehouse: "Warehouse",
  driver: "Driver",
  "install-crew": "Install Crew",
};

/**
 * @returns - a component that allows the user to invite coworkers to the company and manage their roles
 * TODO: refactor to deduplicate code with Roles.js
 */
export default function ManageTeamPage() {
  // get current logged in user
  const [currentUser, setCurrentUser] = useState({});
  // const [currentUsersCompany, setCurrentUsersCompany] = useState({})

  const [users, setUsers] = useState([]);
  const [showAddCoworkersModal, setShowAddCoworkersModal] = useState(false);

  // emails for the invite team members modal
  const [emailsToAdd, setEmailsToAdd] = useState([]);

  // role to be assigned to the new users
  const [role, setRole] = useState("member");

  // error message
  const [error, setError] = useState("");

  const [columns, setColumns] = useState([]);

  // loading state
  const [loading, setLoading] = useState(false);

  // get current user
  useEffect(() => {
    UserManager.getUser()
      .then((user) => {
        setCurrentUser(user.userData);
      })
      .catch((err) => {
        console.log(err);
      });

    tableColumns().then((columns) => {
      setColumns(columns);
    });
  }, []);

  // get current users and invites on page load
  useEffect(() => {
    // get current users and invites
    UserManager.makeAuthenticatedRequest("/api/user/list", "GET").then(
      async (res) => {
        const companyType = await UserManager.getUser().then(
          (user) => user.userData.company.type,
        );

        var locations;
        if (companyType === "distributor") {
          locations = await getLocations(setError);
        }

        // append user invites to users
        let newUsers = [...users];
        res.data.invites?.forEach((invite) => {
          if (!invite.email) {
            return;
          }
          if (newUsers.find((user) => user.email === invite.email)) {
            return;
          }
          newUsers.push(
            structureRowData({
              id: invite.email,
              name: "--",
              email: invite.email,
              status:
                invite.status === "pending" ? "Pending Invite" : invite.status,
              role: roleMap[invite.role] ? roleMap[invite.role] : "None",
              ...(companyType === "distributor" && {
                location: locations.find(
                  (location) => location._id === invite.location,
                )?.name
                  ? locations.find(
                      (location) => location._id === invite.location,
                    )?.name
                  : "None",
              }),
              rowStatus: "readonly",
              menuOptions: [
                {
                  label: "Delete",
                  value: "delete",
                  onSelected: (item) => deleteRow(item, setError),
                },
                { label: "Edit", value: "edit" },
              ],
            }),
          );
        });

        res.data.users.forEach((user) => {
          if (newUsers.find((u) => u.email === user.email)) {
            return;
          }

          newUsers.push(
            structureRowData({
              id: user.email,
              name: user.pending ? "--" : user.firstName + " " + user.lastName,
              email: user.email,
              status: user.pending ? "Pending Invite" : "Active",
              role: roleMap[user.company.role]
                ? roleMap[user.company.role]
                : "None",
              ...(companyType === "distributor" && {
                location: locations.find(
                  (location) => location._id === user.company.location,
                )?.name
                  ? locations.find(
                      (location) => location._id === user.company.location,
                    )?.name
                  : "None",
              }),
              rowStatus: "readonly",
              menuOptions: [
                {
                  label: "Archive",
                  value: "archive",
                  onSelected: (item) => archiveUser(item, setError, setUsers),
                },
                { label: "Edit", value: "edit" },
              ],
            }),
          );
        });

        // sort newUsers, users first, then invites
        newUsers = sortUsers(newUsers);

        setUsers(newUsers);
      },
    );
  }, []);

  /**
   * Updates the given row matching by id.
   * Returns an object with the result list.
   */
  async function saveRow(row) {
    {
      const roleResult = await updateRole(row, setError);
      var locationResult;
      if (currentUser.company?.type === "distributor") {
        locationResult = await updateLocation(row, setError);
      }

      return {
        id: roleResult.id,
        resultList: [
          { column: roleResult.column, value: roleResult.newValue },
          ...(currentUser.company?.type === "distributor"
            ? [
                {
                  column: locationResult.column,
                  value: locationResult.newValue,
                },
              ]
            : []),
        ],
      };
    }
  }

  return (
    <div>
      <Modal open={showAddCoworkersModal} setOpen={setShowAddCoworkersModal}>
        <div className="flex flex-col items-center justify-between w-full">
          <div className="self-end">
            <XMarkIcon
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-300"
              onClick={() => setShowAddCoworkersModal(false)}
            />
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-6">
            <div className="flex flex-col items-start self-start">
              <div className="text-base font-semibold leading-6 text-left align-middle">
                Invite People to TOA
              </div>
              <div className="text-sm font-normal leading-5 text-left text-gray-500 align-middle">
                Add emails and roles individually or in bulk
              </div>
            </div>
            <div className="w-full">
              <EmailInput
                label="Send Invites To:"
                emails={emailsToAdd}
                setEmails={setEmailsToAdd}
                placeholder="example@email.com"
                className="!h-28"
              />
            </div>
            <div className="self-start w-80">
              <Dropdown
                label="Team Role"
                options={[
                  [
                    { value: "admin", label: "Admin" },
                    { value: "member", label: "Member" },
                    ...(currentUser?.company?.type === "installer"
                      ? [{ value: "install-crew", label: "Install Crew" }]
                      : currentUser?.company?.type === "distributor"
                        ? [
                            { value: "operations", label: "Operations" },
                            { value: "warehouse", label: "Warehouse" },
                            { value: "driver", label: "Driver" },
                          ]
                        : []),
                  ],
                ]}
                selectedValue={role}
                wide
                onSelected={(item) => {
                  setRole(item.value);
                }}
              />
            </div>
          </div>
          <div className="flex flex-row justify-end w-full gap-2 mt-4">
            {!loading ? (
              <Button
                variant="primary"
                onClick={async () => {
                  setLoading(true);
                  await sendInvites(
                    users,
                    setUsers,
                    emailsToAdd,
                    setEmailsToAdd,
                    role,
                    setError,
                  );
                  setLoading(false);
                  setShowAddCoworkersModal(false);
                }}
                disabled={emailsToAdd.length === 0}
              >
                Invite Coworkers
              </Button>
            ) : (
              <div className="flex items-center pr-7">
                <Spinner size={20} />
              </div>
            )}
            <Button
              variant="secondary"
              onClick={() => {
                setEmailsToAdd([]);
                setShowAddCoworkersModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <div className="flex flex-col gap-2 mt-4">
        <Card title="Manage Team">
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              {/* 
                                keep this here, will uncomment when searching is implemented
                                TODO: implement searching
                            */}
              {/* <div className='flex flex-row items-center gap-3'>
                                <div className='w-64'>
                                    <SearchBar />
                                </div>
                                <div>
                                    {error && <div className='text-red-500'>{error}</div>}
                                </div>
                            </div> */}
              {/* keep this here, will uncomment when filtering and sorting is implemented */}
              {/* <div className='flex flex-row items-center gap-4'>
                                <FilterSort />
                            </div> */}
            </div>
            <div>
              <InputTable
                tableData={users}
                setTableData={setUsers}
                columns={columns}
                addButtonText={"Add Coworker"}
                outerAddButton={currentUser.company?.role === "admin"}
                innerAddButton={currentUser.company?.role === "admin"}
                addButtonClicked={() => {
                  setError("");
                  setShowAddCoworkersModal(true);
                }}
                addButtons={false}
                height={"calc(100vh - 195px)"}
                editColumnWidth={3}
                editableRows={currentUser.company?.role === "admin"}
                ellipsisDropdown={currentUser.company?.role === "admin"}
                useOnBlur={false}
                onSaveRow={saveRow}
                onCancelRow={() => setError("")}
                onRowLoseFocus={() => setError("")}
                error={error}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
