import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import logo_toa_under_monochrome from "../../assets/logos/lightMode/logo_toa_under_monochrome.svg";
import logo_toa_under_monochrome_white from "../../assets/logos/darkMode/logo_toa_under_monochrome_white.svg";


export default class LoginLayout extends Component {
  render() {

    return (
      <>
        <div className="min-h-screen bg-gray-200 dark:bg-gray-800 min-w-screen">
          <div className="flex flex-col justify-start flex-1 min-h-screen px-6 py-4 md:py-12 lg:px-8">
            <div className="my-8 md:my-12 sm:mx-auto sm:w-full sm:max-w-sm">
              <img
                className="block w-auto h-32 mx-auto dark:hidden"
                src={logo_toa_under_monochrome}
                alt="Toa"
              />
              <img
                className="hidden w-auto h-32 mx-auto dark:block"
                src={logo_toa_under_monochrome_white}
                alt="Toa"
              />
            </div>

            <div className="w-full max-w-2xl mx-auto">
              <Outlet />
            </div>
            <div className="content-end mx-auto grow">
              <div className="flex flex-row gap-2 pt-2 mb-2 text-sm font-normal leading-5 text-gray-600 dark:text-gray-400">
                {/* // TODO: implement links in login layout */}
                {/* <div>
                  Privacy and Terms
                </div>
                <div>
                  |
                </div>
                <div>
                  Contact Us
                </div>
                <div>
                  |
                </div>
                <div>
                  Change Region
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
