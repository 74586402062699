import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Editor } from "@tiptap/react";
import classNames from "src/tools/classNames";
import { ImageDisplay, FileDisplay, Separator, Btn } from "./Utils.tsx";
import Button from "../Button";
import {
  PhotoIcon,
  PaperClipIcon,
  ArrowUpCircleIcon,
} from "@heroicons/react/24/outline";
import { ExpandIcon } from "lucide-react";
import "./TiptapStyle.css";
import S3, { S3File } from "src/tools/S3/s3.ts";

type FooterProps = {
  readOnly: boolean;
  reloadTrigger?: any;
  toggleToolbar: () => void;
  showToolbar: boolean;
  editor: Editor;
  onSubmit: (images: S3File[] | File[], files: S3File[] | File[]) => void;
  useFiles: boolean; // if true, the editor will allow users to upload files and images
  getImages: () => Promise<
    {
      getObjectSignedUrl: string;
      deleteObjectSignedUrl: string;
    }[]
  >;
  getFiles: () => Promise<
    {
      getObjectSignedUrl: string;
      deleteObjectSignedUrl: string;
    }[]
  >;
  uploadImage: (image: File) => Promise<
    {
      getObjectSignedUrl: string;
      deleteObjectSignedUrl: string;
    }[]
  >;
  uploadFile: (file: File) => Promise<
    {
      getObjectSignedUrl: string;
      deleteObjectSignedUrl: string;
    }[]
  >;
  submitButtonText?: string;
  submitButton?: boolean;
  // for uploading immediately or not, sometimes we don't know where to upload to until after the TiptapEditor is submitted
  // for example, in the case of notes, we don't know the note id until after the note is submitted, so we can't upload the images and files immediately
  uploadImagesImmediately?: boolean;
  uploadFilesImmediately?: boolean;
};

/**
 * Footer component for the editor
 *
 * Contains buttons for:
 * - Submit
 * - Expand
 * - Image/File Upload
 * - Mentions
 * - Links
 *
 * @boolean readOnly - whether the editor is read only
 * @any reloadTrigger - when reloadTrigger changes, images and files are reloaded
 * @function toggleToolbar - function to toggle the toolbar visibility
 * @boolean showToolbar - whether the toolbar is visible
 * @Editor editor - the editor instance
 * @function onSubmit - function to handle the submission of the editor
 * @boolean useFiles - whether the editor will allow users to upload files and images
 * @function getImages - function to get images to display
 * @function getFiles - function to get files to display
 * @function uploadImage - function to upload images
 * @function uploadFile - function to upload files
 * @string submitButtonText - text for the submit button
 */

const Footer = forwardRef<{ submitContent: () => void }, FooterProps>(
  (
    {
      readOnly,
      reloadTrigger,
      toggleToolbar,
      showToolbar,
      editor,
      onSubmit,
      useFiles,
      getImages,
      getFiles,
      uploadImage,
      uploadImagesImmediately = true,
      uploadFile,
      uploadFilesImmediately = true,
      submitButtonText = "",
      submitButton = true,
    },
    ref
  ) => {
    const [images, setImages] = useState<S3File[] | File[]>([]);
    const [files, setFiles] = useState<S3File[] | File[]>([]);

    useEffect(() => {
      async function fetchImages() {
        const images = await getImages();
        setImages(images);
      }

      async function fetchFiles() {
        const files = await getFiles();
        setFiles(files);
      }

      if (reloadTrigger) {
        // clear the images and files
        setImages([]);
        setFiles([]);

        fetchImages();
        fetchFiles();
      }
    }, [reloadTrigger]);

    /**
     * Open file explorer and allow user to select an image file
     */
    async function addfile(type: "image" | "file") {
      const file = document.createElement("input");
      file.type = "file";
      file.accept = type === "image" ? "image/*" : "*/*";
      file.multiple = true;
      file.onchange = async (e) => {
        // get the files
        const newFiles = (e.target as HTMLInputElement).files;
        if (newFiles) {
          // upload the image, if successful, add the image to the images state
          for (const file of newFiles) {
            if (type === "image") {
              // if uploadImagesImmediately is true, upload the image and use the presigned urls
              if (uploadImagesImmediately) {
                const presignedUrls = await uploadImage(file);
                if (presignedUrls) {
                  setImages(presignedUrls);
                }
              }
              // if uploadImagesImmediately is false, add the local file to the images state
              else {
                setImages((prevImages) => [...prevImages, file] as File[]);
              }
            } else {
              // if uploadFilesImmediately is true, upload the file and use the presigned urls
              if (uploadFilesImmediately) {
                const presignedUrls = await uploadFile(file);
                if (presignedUrls) {
                  setFiles(presignedUrls);
                }
              }
              // if uploadFilesImmediately is false, add the local file to the files state
              else {
                setFiles((prevFiles) => [...prevFiles, file] as File[]);
              }
            }
          }
        }
      };
      file.click();
    }

    /**
     * Delete a file from the editor
     *
     * @param deleteObjectSignedUrl - the signed url of the file to delete
     * @param type - the type of file to delete
     */
    async function deleteS3File(
      deleteObjectSignedUrl: string,
      type: "image" | "file"
    ) {
      var success = false;
      await fetch(deleteObjectSignedUrl, {
        method: "DELETE",
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            // if the response is successful, remove the file from the state
            if (type === "image") {
              setImages(
                images.filter(
                  (image) =>
                    (image as S3File).deleteObjectSignedUrl !==
                    deleteObjectSignedUrl
                ) as S3File[]
              );
            } else {
              setFiles(
                files.filter(
                  (file) =>
                    (file as S3File).deleteObjectSignedUrl !==
                    deleteObjectSignedUrl
                ) as S3File[]
              );
            }
          }
        })
        .catch((error) => {
          console.error("error deleting image", error);
        });

      return success;
    }

    // Function to handle submission
    const submitContent = () => {
      // Your existing submission logic
      onSubmit(images, files);
      // clear the images and files
      setImages([]);
      setFiles([]);
    };

    // Expose the submitContent method via ref
    useImperativeHandle(ref, () => ({
      submitContent,
    }));

    /**
     * Handle the deletion of a file from the editor
     *
     * @param file - the file to delete
     * @param type - the type of file to delete
     */
    function handleOnDeleteFile(file: S3File | File, type: "image" | "file") {
      if (
        (type === "image" && uploadImagesImmediately) ||
        (type === "file" && uploadFilesImmediately)
      ) {
        deleteS3File((file as S3File).deleteObjectSignedUrl, type);
      } else {
        if (type === "image") {
          setImages(
            images.filter(
              (i) => (i as File).name !== (file as File).name
            ) as File[]
          );
        } else if (type === "file") {
          setFiles(
            files.filter(
              (f) => (f as File).name !== (file as File).name
            ) as File[]
          );
        }
      }
    }

    return (
      <div className="flex flex-col items-start w-full">
        <div
          className={classNames(
            "flex flex-row gap-3 w-full overflow-x-auto",
            images.length > 0 || files.length > 0 ? "p-2" : ""
          )}
        >
          {images?.map((image) => (
            <ImageDisplay
              key={image.getObjectSignedUrl || image.name}
              readOnly={readOnly}
              image={image}
              onDelete={() => handleOnDeleteFile(image, "image")}
              imageLocation={uploadImagesImmediately ? "s3" : "local"}
            />
          ))}
          {files?.map((file) => (
            <FileDisplay
              key={file.getObjectSignedUrl || file.name}
              readOnly={readOnly}
              file={file}
              onDelete={() => handleOnDeleteFile(file, "file")}
              fileLocation={uploadFilesImmediately ? "s3" : "local"}
            />
          ))}
        </div>

        {!readOnly && (
          <div className="flex flex-row items-center justify-between w-full gap-1 pb-1">
            <div className="flex flex-row items-center gap-1">
              <Btn Icon={ExpandIcon} onClick={toggleToolbar} />
              <Separator />
              {useFiles && (
                <>
                  <Btn Icon={PhotoIcon} onClick={() => addfile("image")} />
                  <Btn Icon={PaperClipIcon} onClick={() => addfile("file")} />
                </>
              )}
              {/* TODO: dont use mentions for now, add them later along with notifications */}
              {/* <Btn Icon={AtSymbolIcon}
                            onClick={() => {
                                // add an @ symbol to the editor
                                editor.chain().focus().insertContent('@').run()
                            }}
                        /> */}
              {/* TODO: do we need a button for the link extension? 
                        It already allows us to paste links, 
                        and to convert existing text to a link */}
              {/* <Btn
                            Icon={LinkIcon}
                            onClick={() => editor.chain().focus().toggleLink({ href: "https://google.com", }).run()}
                            active={editor.isActive("link")}
                        /> */}
            </div>

            {submitButton &&
              (submitButtonText ? (
                <Button
                  onClick={() => onSubmit(images, files)}
                  disabled={editor.getHTML() === "<p></p>"}
                  data-disabled={editor.getHTML() === "<p></p>"}
                  variant="primary"
                  className="!py-1 !px-2 !rounded-md mb-1"
                >
                  {submitButtonText}
                </Button>
              ) : (
                <button
                  onClick={() => onSubmit(images, files)}
                  disabled={editor.getHTML() === "<p></p>"}
                  data-disabled={editor.getHTML() === "<p></p>"}
                  className={classNames(
                    "ml-auto text-primary-green hover:text-primary-green-700",
                    "transition-colors",
                    "data-[disabled=true]:text-gray-400"
                  )}
                >
                  <ArrowUpCircleIcon className="w-6 h-6 stroke-2" />
                </button>
              ))}
            {/* Submit */}
          </div>
        )}
      </div>
    );
  }
);

Footer.displayName = "Footer";

export default Footer;
