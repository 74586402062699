import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import OrderList from "src/components/Orders/OrderList.tsx";
import OrderModal, {
  OrderModalData,
} from "src/components/Orders/OrderModal.tsx";
import OrderStatusList from "src/components/Orders/OrderStatusList.tsx";
import OrderTimeline from "src/components/Orders/OrderTimeline.tsx";
import Spinner from "src/components/Spinner";
import Button from "src/components/input/Button";
import GridListToggle from "src/components/input/GridListToggle.tsx";
import MultiSelectListbox from "src/components/input/MultiSelectListbox.tsx";
import Switch from "src/components/input/Switch.tsx";
import useMultiConnectionSelect from "src/hooks/data/connections/useMultiConnectionSelect.ts";
import useMultiLocationSelect from "src/hooks/data/locations/useMultiLocationSelect.ts";
import useMultiMarketSelect from "src/hooks/data/markets/useMultiMarketSelect.ts";
import useOrders, {
  Filters,
  OrderStatus,
} from "src/hooks/data/orders/useOrders.ts";
import useCurrentUser from "src/hooks/data/users/useCurrentUser.ts";
import { User } from "src/hooks/data/users/useUsersByIds";
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext.tsx";
import { LocationType } from "src/hooks/data/locations/useLocations.ts";
import { Connection } from "src/hooks/data/connections/useConnections.ts";
import { STATUS_ORDER } from "src/hooks/data/orders/useOrders.ts";
import { OrderStatusLabels } from "src/hooks/data/orders/useOrders.ts";
import { OrderStatusIcons } from "src/hooks/data/orders/useOrders.ts";
import Card from "src/components/Card";
import SearchBar from "src/components/SearchBar";
import OrderAgreementIcons, {
  AgreementBubble,
} from "src/components/Orders/OrderAgreementIcons.tsx";
import { Link } from "react-router-dom";
import {
  ArrowTopRightOnSquareIcon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import OrderActionButton from "src/components/Orders/common/OrderActionButton.tsx";
import IconDropdown from "src/components/input/IconDropdown";
import OrderNotes from "src/components/Orders/common/OrderNotes.tsx";
import { TimestampTypes } from "src/hooks/data/orders/useOrders.ts";
import AddressHelper from "src/utils/addressHelper.ts";
import SecondaryOrderActions from "src/components/Orders/common/SecondaryOrderActions.tsx";
import useOrderModals from "src/hooks/actions/useOrderModals.tsx";
import classNames from "src/tools/classNames";
import OrderIssues from "src/components/Orders/common/OrderIssues.tsx";
import Badge from "src/components/Badge.tsx";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { AgreementStatus } from "src/hooks/data/orders/useOrders.ts";
import { getAgreementStatus } from "src/components/Orders/OrderAgreementIcons.tsx";
import useMultiDistributorSelect from "src/hooks/data/connections/distributors/useMultiDistributorSelect.ts";
import useMultiInstallerSelect from "src/hooks/data/connections/installers/useMultiInstallerSelect.ts";
import { Installer } from "src/hooks/data/connections/installers/useInstallers.ts";
import { Distributor } from "src/hooks/data/connections/distributors/useDistributors.ts";
import {
  getActualDeliveryDateString,
  getDeliveryDateString,
  MinorBadge,
} from "src/components/Orders/OrderRowItem.tsx";

type Props = {};

type OrderListPageContextType = {
  user: User;

  marketOptions: { label: string; value: string }[];
  selectedMarkets: MarketType[];
  handleSelectedMarketsChange: (newMarketIds: string[]) => void;

  locations: LocationType[];
  selectedLocations: LocationType[];
  handleSelectedLocationsChange: (newLocationIds: string[]) => void;

  distributors: Distributor[];
  selectedDistributors;
  handleSelectedDistributorsChange;

  installers: Installer[];
  selectedInstallers;
  handleSelectedInstallersChange;

  view;
  setView;

  handleCreateOrderBtn;

  statusCounts: {
    [status: string]: number;
  };
  selectedStatuses: OrderStatus[];
  handleSelectedStatusesChange: (newStatuses: OrderStatus[]) => void;

  search: string;
  setSearch: (search: string) => void;
};

const OrderListPageContext = createContext<OrderListPageContextType>({
  user: null,

  marketOptions: [],
  selectedMarkets: [],
  handleSelectedMarketsChange: () => {},

  locations: [],
  selectedLocations: [],
  handleSelectedLocationsChange: () => {},

  distributors: [],
  selectedDistributors: [],
  handleSelectedDistributorsChange: () => {},

  installers: [],
  selectedInstallers: [],
  handleSelectedInstallersChange: () => {},

  view: "list",
  setView: () => {},

  handleCreateOrderBtn: () => {},

  statusCounts: {},
  selectedStatuses: [],
  handleSelectedStatusesChange: () => {},

  search: null,
  setSearch: () => {},
});

/**
 * Page for showing the list of all orders.
 * Displays a list of selectable status filters, a list of orders, and a timeline of order activity.
 * Orders are selectable to filter the timeline.
 * There are also filters for markets/locations and connections.
 * This page works for both installers and distributors as it dynamically changes
 * the market/location filter based on user type.
 */
export default function OrderListPage({}: Props) {
  const user = useCurrentUser();

  // Currently visible modal (null if none) // TODO: change to enum?
  const [modal, setModal] = useState<"create" | null>(null);

  const [selectedStatuses, setSelectedStatuses] = useState<OrderStatus[]>(null);
  const [markets, selectedMarkets, setSelectedMarkets] = useMultiMarketSelect(
    true,
    true
  );
  const [locations, selectedLocations, setSelectedLocations] =
    useMultiLocationSelect(true);

  const [distributors, selectedDistributors, setSelectedDistributors] =
    useMultiDistributorSelect(true);
  const [installers, selectedInstallers, setSelectedInstallers] =
    useMultiInstallerSelect(true);

  const [search, setSearch] = useState<string>();

  // Generate filters object from selected markets, locations, connections, statuses, and search
  const filters: Filters = useMemo<Filters>(() => {
    // If any of the data is not loaded yet, return null so the orders are not fetched.
    // This way the orders aren't fetched without filters. Otherwise orders would be fetchec
    // twice (once without filters and once with filters) which is unnecessary and slow.
    if (
      // TODO: add installers and distributors
      // !connections ||
      !(markets || locations) // Require either markets or locations (based on company type)
    )
      return null;

    let marketIds = selectedMarkets?.map((m) => m._id);
    let locationIds = selectedLocations?.map((m) => m._id);

    // If all markets or locations are selected, don't filter by them
    if (selectedMarkets && markets && selectedMarkets.length === markets.length)
      marketIds = null;
    if (
      selectedLocations &&
      locations &&
      selectedLocations.length === locations.length
    )
      locationIds = null;

    // If searching, ignore other filters
    if (search) {
      return {
        search,
        // All connections
        distributorIds: selectedDistributors?.map((d) => d._id),
        installerIds: selectedInstallers?.map((i) => i._id),
      };
    }

    return {
      marketIds: marketIds,
      locationIds: locationIds,
      distributorIds: selectedDistributors?.map((d) => d._id),
      installerIds: selectedInstallers?.map((i) => i._id),
      search: search,
      statuses: selectedStatuses,
    };
  }, [
    selectedMarkets,
    selectedLocations,
    selectedDistributors,
    selectedInstallers,
    search,
    selectedStatuses,
  ]);

  const [orders, statusCounts, refreshOrders] = useOrders(filters);
  const [selectedOrderId, setSelectedOrderId] = useState<string>(null);
  const [showAgreementHistory, setShowAgreementHistory] =
    useState<boolean>(false);

  // Get selected order
  const { selectedOrder } = useMemo(() => {
    const so = orders?.find((o) => o._id === selectedOrderId);
    return {
      selectedOrder: so,
    };
  }, [orders, selectedOrderId]);

  // When selected order changes, check if it is confirmed
  // Set showAgreementHistory accordingly.
  useEffect(() => {
    let confirmed = false;
    if (selectedOrder) {
      const materialStatus = getAgreementStatus(
        selectedOrder.quote?.agreements?.material
      );
      const dateStatus = getAgreementStatus(
        selectedOrder.quote?.agreements?.deliveryDate
      );
      const amountStatus = getAgreementStatus(
        selectedOrder.quote?.agreements?.amount
      );
      confirmed =
        materialStatus === AgreementStatus.ACCEPTED &&
        dateStatus === AgreementStatus.ACCEPTED &&
        amountStatus === AgreementStatus.ACCEPTED;
    }
    setShowAgreementHistory(!confirmed);
  }, [orders, selectedOrderId]);

  const [modalComponent, runAction] = useOrderModals(
    selectedOrder,
    refreshOrders
  );

  const [view, setView] = useState<"list" | "rows">("list");
  const [hideActivity, setHideActivity] = useState<boolean>(true);

  const DEFAULT_BLANK_ORDER: OrderModalData = {
    installerId:
      user?.company?.type.toLowerCase() === "installer"
        ? user.company.id
        : null,
    contact: {},
    address: {},
  };

  const marketOptions =
    markets?.map((market) => ({ label: market.name, value: market._id })) || [];

  const issueCount = selectedOrder?.issues.filter((s) => !s.resolved).length;

  /**
   * Handles setting the selected markets based on the new market IDs.
   */
  function handleSelectedMarketsChange(newMarketIds: string[]) {
    setSelectedMarkets(markets?.filter((m) => newMarketIds.includes(m._id)));
  }

  /**
   * Handles setting the selected locations based on the new location IDs.
   */
  function handleSelectedLocationsChange(newLocationIds: string[]) {
    setSelectedLocations(
      locations?.filter((m) => newLocationIds.includes(m._id))
    );
  }

  /**
   * Handles setting the selected distributors based on the new IDs.
   */
  function handleSelectedDistributorsChange(newIds: string[]) {
    setSelectedDistributors(
      distributors?.filter((d) => newIds.includes(d._id)),
    );
  }

  /**
   * Handles setting the selected installers based on the new IDs.
   */
  function handleSelectedInstallersChange(newIds: string[]) {
    setSelectedInstallers(installers?.filter((d) => newIds.includes(d._id)));
  }

  /**
   * Handles setting the selected statuses based on the new statuses.
   */
  function handleSelectedStatusesChange(newStatuses: OrderStatus[]) {
    setSelectedStatuses(newStatuses);
  }

  /**
   * Handles opening an order creation modal.
   * Requires the user to be in an installer.
   */
  function handleCreateOrderBtn() {
    setModal("create");
  }

  /**
   * Handle opening/closeing order activity based on order selection
   */
  function handleOrderClick(orderId: string) {
    let newSelectedId = selectedOrderId === orderId ? null : orderId;
    // Toggle selected order
    setSelectedOrderId(newSelectedId);
    setHideActivity(!Boolean(newSelectedId)); // If new selected ID is null, hide activity. Else show.
  }

  // Deselect order and hide activity when filters change
  useEffect(() => {
    setSelectedOrderId(null);
    setHideActivity(true);
  }, [filters]);

  const contextValue: OrderListPageContextType = {
    user,

    marketOptions,
    selectedMarkets,
    handleSelectedMarketsChange,

    locations,
    selectedLocations,
    handleSelectedLocationsChange,

    distributors,
    selectedDistributors,
    handleSelectedDistributorsChange,

    installers,
    selectedInstallers,
    handleSelectedInstallersChange,

    view,
    setView,

    handleCreateOrderBtn,

    statusCounts,
    selectedStatuses,
    handleSelectedStatusesChange,

    search,
    setSearch,
  };

  return (
    <OrderListPageContext.Provider value={contextValue}>
      {modalComponent}
      {/* Main */}
      <div className="flex flex-col flex-1 min-h-0 p-6">
        {/* Toolbar */}
        <Toolbar />

        {/* Rows and History */}
        <div className="flex flex-1 min-h-0 gap-3">
          {/* Rows */}
          <div className="flex-1 h-full min-w-0">
            {orders && (markets || locations) ? ( // TODO: switch to installers and distributors
              // {orders && (markets || locations) && connections ? (
              <Card className="max-w-full !min-w-0 h-full min-h-0">
                <OrderList
                  orders={orders}
                  selectedOrderId={selectedOrderId}
                  onOrderClick={handleOrderClick}
                  markets={markets}
                  locations={locations}
                  distributors={distributors}
                  installers={installers}
                  view={view}
                  refreshOrders={refreshOrders}
                />
              </Card>
            ) : (
              <div className="pt-40">
                <Spinner />
              </div>
            )}
          </div>

          {/* Selected Order Details */}
          {!hideActivity && selectedOrder && (
            <Card className="overflow-y-auto divide-y max-w-[400px]">
              {/* Info */}
              <div>
                <div className="flex items-center gap-1">
                  <Link
                    to={`../details/${selectedOrder._id}`}
                    className="relative bottom-0.5 cursor-pointer text-primary-green hover:text-primary-green-700"
                  >
                    <ArrowTopRightOnSquareIcon className="inline w-5 h-5 stroke-2" />
                  </Link>
                  <p className="font-semibold text-gray-600">
                    {selectedOrder.name ?? (
                      <span className="italic">No Name</span>
                    )}
                  </p>
                  <div className="ml-auto"></div>
                  <OrderActionButton
                    order={selectedOrder}
                    onRunAction={runAction}
                  />
                  <SecondaryOrderActions
                    order={selectedOrder}
                    onRunAction={runAction}
                  />
                </div>

                <div className="py-3 text-sm text-gray-600">
                  <p>{selectedOrder.orderAddress.line1}</p>
                  <p>{selectedOrder.orderAddress.line2}</p>
                  <p>
                    {selectedOrder.orderAddress.city},{" "}
                    {selectedOrder.orderAddress.state}{" "}
                    {selectedOrder.orderAddress.postalCode}
                  </p>
                </div>

                <div className="grid grid-cols-[repeat(2,auto)] items-center gap-x-2 text-sm font-medium text-gray-500 pb-2">
                  {/* Delivery Date */}
                  <div className="flex items-center gap-1">
                    <CalendarIcon className="w-4 h-4 text-gray-600 stroke-2" />
                    <span className="font-semibold">Scheduled:</span>
                  </div>
                  <div className="text-sm font-normal text-gray-500">
                    {getDeliveryDateString(selectedOrder) ?? "--"}
                  </div>

                  {/* Install date */}
                  <div className="flex items-center gap-1">
                    <TruckIcon className="w-4 h-4 text-gray-600 stroke-2" />
                    <span className="font-semibold">Delivered:</span>
                  </div>
                  <div className="text-sm font-normal text-gray-500">
                    {getActualDeliveryDateString(selectedOrder) ?? "--"}
                  </div>
                </div>
              </div>

              {/* Issues */}
              <div className="p-4">
                <div className="flex items-center gap-2 pb-3 font-semibold">
                  <p>Issues</p>
                  {issueCount ? (
                    <div className="w-10 h-5 text-sm font-medium text-center text-white align-middle bg-primary-rose rounded-3xl">
                      {issueCount}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {issueCount ? (
                  <OrderIssues order={selectedOrder} onRunAction={runAction} />
                ) : (
                  <p className="text-sm italic font-normal text-gray-500">
                    No issues
                  </p>
                )}
              </div>

              {/* Agreements */}
              <div className="p-4 space-y-3">
                <div className="flex items-center gap-4">
                  <p className="font-semibold">Agreements</p>
                  <OrderAgreementIcons order={selectedOrder} />
                  <button
                    className="ml-auto cursor-pointer"
                    onClick={() =>
                      setShowAgreementHistory(!showAgreementHistory)
                    }
                  >
                    {showAgreementHistory ? (
                      <ChevronUpIcon className="w-6 h-6" />
                    ) : (
                      <ChevronDownIcon className="w-6 h-6" />
                    )}
                  </button>
                </div>
                {showAgreementHistory && (
                  <OrderTimeline
                    timestampFilter={[TimestampTypes.AGREEMENT_CHANGED]}
                    orders={selectedOrder ? [selectedOrder] : []}
                    hideOrderLink
                  />
                )}
              </div>

              {/* Notes */}
              <div className="p-4">
                <p className="pb-3 font-semibold">
                  Notes ({selectedOrder.notes.length})
                </p>
                <OrderNotes order={selectedOrder} />
              </div>

              {/* Activity */}
              <div className="min-w-0 p-4 overflow-y-auto">
                <h1 className="pb-3 text-base font-semibold">Activity</h1>
                {/* TODO: handle empty message? */}
                <OrderTimeline
                  hideOrderLink
                  orders={selectedOrder ? [selectedOrder] : []}
                />
              </div>
            </Card>
          )}
        </div>
        {/* End Rows and History */}
      </div>
      {/* End Main */}

      {/* Modals */}
      {user && user.company?.type !== "account" && (
        <OrderModal
          showOrderModal={modal === "create"}
          setShowOrderModal={(show) => {
            setModal(show ? "create" : null);
          }}
          data={DEFAULT_BLANK_ORDER}
          onSuccess={() => {
            refreshOrders();
          }}
          allowMarketSelection
        />
      )}
    </OrderListPageContext.Provider>
  );
}

/**
 * Displays the toolbar for the order list page.
 * This includes filters for statuses, markets/locations, connections, and search.
 * It also holds the view toggle (row/list) and new order button.
 */
function Toolbar() {
  const {
    user,
    marketOptions,
    selectedMarkets,
    handleSelectedMarketsChange,

    locations,
    selectedLocations,
    handleSelectedLocationsChange,

    distributors,
    selectedDistributors,
    handleSelectedDistributorsChange,

    installers,
    selectedInstallers,
    handleSelectedInstallersChange,

    view,
    setView,

    handleCreateOrderBtn,

    statusCounts,
    selectedStatuses,
    handleSelectedStatusesChange,

    search,
    setSearch,
  } = useContext(OrderListPageContext);

  // Generate array of status options for dropdown
  // - Label is the status label
  // - Display is icon, status label, and count
  // - Value is the status value
  const getStatusOption = (os: OrderStatus, faded: boolean = false) => {
    const Icon = OrderStatusIcons[os];
    return {
      label: OrderStatusLabels[os],
      display: (
        <div
          className={classNames(
            "flex items-center gap-2 w-full",

            !faded ? "" : "text-gray-500 dark:text-gray-400"
          )}
        >
          {Icon && <Icon className="w-6 h-6 stroke-2" />}
          <div>{OrderStatusLabels[os]}</div>
          <div className="ml-4 text-right grow">{statusCounts[os]}</div>
        </div>
      ),
      value: os,
    };
  };
  // TODO: move to useOrders or some constant?
  const statusOptions = useMemo(() => {
    return [
      [
        getStatusOption(OrderStatus.WAITING_FOR_QUOTE),
        getStatusOption(OrderStatus.WAITING_FOR_QUOTE_APPROVAL),
        getStatusOption(OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT),
        getStatusOption(OrderStatus.CONFIRMED),
      ],
      [
        getStatusOption(OrderStatus.READY_TO_PACKAGE),
        getStatusOption(OrderStatus.PACKAGING),
        getStatusOption(OrderStatus.PACKAGED),
      ],
      [
        getStatusOption(OrderStatus.READY_TO_DELIVER),
        getStatusOption(OrderStatus.IN_TRANSIT),
      ],
      [
        getStatusOption(
          OrderStatus.DELIVERED,
          !selectedStatuses || selectedStatuses.length == 0,
        ),
        getStatusOption(
          OrderStatus.CANCELED,
          !selectedStatuses || selectedStatuses.length == 0,
        ),
      ],
    ];
  }, [selectedStatuses]);

  const distributorOptions = useMemo(() => {
    return (
      distributors?.map((distributor) => ({
        label: distributor.name,
        value: distributor._id,
      })) || []
    );
  }, [distributors]);

  const installerOptions = useMemo(() => {
    return (
      installers?.map((installer) => ({
        label: installer.name,
        value: installer._id,
      })) || []
    );
  }, [installers]);

  const selectedDistributorIds = useMemo(() => {
    return selectedDistributors?.map((d) => d._id) || [];
  }, [selectedDistributors]);

  const selectedInstallerIds = useMemo(() => {
    return selectedInstallers?.map((d) => d._id) || [];
  }, [selectedInstallers]);

  /**
   * Passes the status change up to the parent context.
   * This will update the selected statuses filter.
   */
  function handleStatusChange(newStatuses: string[]) {
    // If all selected, use `null` instead of array of statuses
    // if (newStatuses.length === statusOptions.length) newStatuses = null;
    handleSelectedStatusesChange(newStatuses as OrderStatus[]);
  }

  return (
    <div className="flex items-center gap-3 pb-2">
      {/* Statuses */}
      <MultiSelectListbox
        itemType="Status"
        options={statusOptions}
        selectedOptionsValues={selectedStatuses ?? []}
        onChange={handleStatusChange}
        noneTextOverride="Active Orders"
        noneButtonText="Active"
        minimizeChecks
      />

      {/* Markets */}
      {user?.company?.type === "installer" ? (
        <MultiSelectListbox
          itemType="Market"
          options={marketOptions}
          selectedOptionsValues={selectedMarkets?.map((m) => m._id) || []}
          onChange={handleSelectedMarketsChange}
        />
      ) : (
        <MultiSelectListbox
          itemType="Location"
          options={
            locations?.map((location) => {
              let name = location.name;

              // If user is account user, append distributor name
              if (user.company?.type === "account" && location.company?.name) {
                name += ` (${location.company?.name})`;
              }
              return {
                label: name,
                value: location._id,
              };
            }) || []
          }
          selectedOptionsValues={selectedLocations?.map((m) => m._id) || []}
          onChange={handleSelectedLocationsChange}
        />
      )}

      {/* Distributors */}
      {user?.company?.type != "distributor" && (
        <MultiSelectListbox
          itemType="Distributor"
          options={distributorOptions}
          selectedOptionsValues={selectedDistributorIds}
          onChange={handleSelectedDistributorsChange}
        />
      )}

      {/* Installers */}
      {user?.company?.type != "installer" && (
        <MultiSelectListbox
          itemType="Installer"
          options={installerOptions}
          selectedOptionsValues={selectedInstallerIds}
          onChange={handleSelectedInstallersChange}
        />
      )}

      {/* Search */}
      <div>
        <SearchBar value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>

      <div className="ml-auto" />

      {/* View */}
      <GridListToggle
        options={["list", "rows"]}
        selectedOption={view}
        onChange={setView}
      />

      {/* TODO: Settings */}
      {/* <Cog6ToothIcon className="w-6 h-6" /> */}

      {/* Order Creation */}
      {user?.company?.type !== "account" && (
        <Button variant="primary" onClick={handleCreateOrderBtn}>
          New Order
        </Button>
      )}
    </div>
  );
}
