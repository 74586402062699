import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";

/**
 * Hook to get issue types for an order.
 * Returns the issue types and a function to get them.
 * Order ID is required to get the issue types.
*/
export default function useIssueTypes(orderId: string, useArchived = false) {
    const [issueTypes, setIssueTypes] = useState<{ [key: string]: string } | null>(null);

    async function getIssueTypes() {
        UserManager.makeAuthenticatedRequest(
            '/api/company-settings/issue-types?orderId=' + orderId + '&useArchived=' + useArchived,
            'GET',
        )
            .then(response => {
                if (response.data.status === "ok") {
                    setIssueTypes(response.data.issueTypes.reduce((acc, curr) => {
                        acc[curr.value] = curr.label;
                        return acc;
                    }, {}));
                } else {
                    setIssueTypes(null);
                }
            })
            .catch(error => {
                console.error(error);
                setIssueTypes(null);
            });
    }

    useEffect(() => {
        getIssueTypes();
    }, [orderId]);

    return [issueTypes, getIssueTypes] as const;
}
