import { useEffect, useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Input({
  value: v,
  label = undefined,
  labelSettings = {
    onLeft: false,
  },
  type = "text",
  placeholder = "",
  required = false,
  autoComplete = undefined,
  highlightOnEmpty = false,
  className = "",
  onChange = (value) => {},
  onBlur = (value) => {},
  min = undefined,
  max = undefined,
  step = undefined,
  name = undefined,
  hideArrows = false,
  pattern = undefined,
  icon = undefined,
  stopPropagation = false,
}) {
  const [value, setValue] = useState(v);

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setValue(v);
  }, [v]);

  function handleChange(e) {
    onChange(e.target.value);
    setValue(e.target.value);
  }

  function handleBlur(e) {
    onBlur(e.target.value);
    setFocused(false);
  }

  var input = (
    <input
      onFocus={() => setFocused(true)}
      onBlur={handleBlur}
      onKeyDown={(e) => {
        // Space key wasn't working on this component inside of the CustomizableDropdown component
        // This is a workaround to fix that issue
        if (stopPropagation) {
          e.stopPropagation();
        }
      }}
      required={required}
      autoComplete={autoComplete}
      value={value}
      type={type}
      id={name ?? type}
      className={classNames(
        "block w-full rounded-md border-0 px-2 py-1.5 shadow-sm",

        // Hide arrow for number inputs
        // References:
        // - https://stackoverflow.com/questions/71296535/how-to-remove-arrow-on-input-type-number-with-tailwind-css
        // - https://www.bobby.sh/how-to-remove-arrow-on-input-type-number-with-tailwind-css
        type === "number" && hideArrows
          ? "[-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none"
          : "",

        // Light Mode
        "text-gray-900",
        // Dark Mode
        "dark:text-gray-200 dark:bg-gray-700",

        "placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6 checked:bg-primary-rose focus:checked:bg-primary-rose invalid:ring-primary-rose",
        value || !highlightOnEmpty
          ? "ring-1 ring-inset ring-gray-300"
          : "ring-1 ring-inset ring-secondary-orange",
        className
      )}
      placeholder={placeholder}
      onChange={handleChange}
      min={min}
      max={max}
      step={step}
      name={name}
      pattern={pattern}
    />
  );

  if (type === "submitIcon") {
    input = (
      <div
        className={classNames(
          "flex flex-row items-center justify-between gap-2",
          "block w-full rounded-md border-0 px-2 py-1.5 shadow-sm",
          // Light Mode
          "text-gray-900",
          // Dark Mode
          "dark:text-gray-200 dark:bg-gray-700",
          "placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6 checked:bg-primary-rose focus:checked:bg-primary-rose invalid:ring-primary-rose",
          focused
            ? "ring-2 ring-inset ring-primary-green"
            : "ring-1 ring-inset ring-gray-300",
          className
        )}
      >
        <input
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
          required={required}
          autoComplete={autoComplete}
          value={value}
          type={type}
          id={name ?? type}
          className={classNames(
            "block w-full border-0",

            // Light Mode
            "text-gray-900",
            // Dark Mode
            "dark:text-gray-200 dark:bg-gray-700",

            "placeholder:text-gray-400 focus:outline-none"
          )}
          placeholder={placeholder}
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
          name={name}
          pattern={pattern}
        />
        {icon}
      </div>
    );
  }

  if (type === "textarea") {
    input = (
      <textarea
        onFocus={() => setFocused(true)}
        required={required}
        autoComplete={autoComplete}
        value={value}
        id={name ?? type}
        rows="3"
        className={classNames(
          "block w-full rounded-md border-0 px-2 py-1.5 shadow-sm",

          // Light Mode
          "text-gray-900",
          // Dark Mode
          "dark:text-gray-200 dark:bg-gray-700",

          "ring-1 ring-inset focus:ring-2 focus:ring-inset",
          "focus:ring-primary-green sm:text-sm sm:leading-6 placeholder:text-gray-400 invalid:ring-primary-rose",
          value || !highlightOnEmpty
            ? "ring-gray-300"
            : "ring-secondary-orange",
          className
        )}
        placeholder={placeholder}
        onChange={handleChange}
        name={name}
        pattern={pattern}
      />
    );
  }

  if (type === "checkbox") {
    input = (
      <div className="flex flex-row items-center gap-3">
        <div>
          <input
            onFocus={() => setFocused(true)}
            id={name ?? type}
            name={name}
            type="checkbox"
            className="w-4 h-4 border-gray-300 rounded text-primary-green focus:ring-primary-green"
            checked={value}
            onChange={(e) => {
              e.target.value = e.target.checked;
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "flex gap-2",

        // Label left
        labelSettings?.onLeft || type === "checkbox"
          ? "flex-row items-center"
          : "flex-col"
      )}
    >
      {label && (
        <label
          htmlFor={name ?? type}
          className={classNames(
            "block text-sm font-medium text-gray-700 dark:text-gray-200",
            type === "checkbox" ? "order-last" : ""
          )}
        >
          {label}
          {required && <RequiredAsterisk />}
        </label>
      )}
      {input}
    </div>
  );
}

/**
 * Small asterisk to indicate required fields
 * Has a tooltip that says "Field required"
 */
export function RequiredAsterisk() {
  return (
    <span className="text-primary-rose" title="Field required">
      *
    </span>
  );
}
