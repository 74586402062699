import React, { createContext, useContext, useReducer } from "react";
import { Distributor } from "src/hooks/data/connections/distributors/useDistributors";
import { Installer } from "src/hooks/data/connections/installers/useInstallers";
import { User } from "src/hooks/data/users/useUsersByIds";

export type UserContextType = {
  user: User | null;
  company: Company | null;
  reloadUser: () => void;
  reloadCompany: () => void;
};

export type Company = Installer | Distributor | Account;

export type Account = {
  _id: string;
  name: string;
  distributors: string[] | Distributor[];
};

const UserContext = createContext<UserContextType>({
  user: null,
  company: null,
  reloadUser: () => {},
  reloadCompany: () => {},
});

export default UserContext;
