import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import { BetaBadge } from 'src/components/Badge.tsx';
import SubNav from 'src/components/nav/SubNav'
import TopBar from 'src/components/nav/TopBar'

type Props = {}

/**
 * Layout for the Labor page.
 * Handles subnav and topbar.
 */
export default function Layout({ }: Props) {
  const [subNavTabs, setSubNavTabs] = React.useState([
    {
      name: 'Job Forecasts',
      href: 'market-forecasts',
      current: true
    },
    {
      name: 'Crew Planner',
      href: 'crew-planner',
      current: false
    },
  ]);

  const location = useLocation();

  /**
   * Selects a tab in the subnav based on the tab name.
   * Sets the `current` field on the subnav items.
   */
  function selectTab(tabName: string) {
    setSubNavTabs(subNavTabs.map(tab => {
      return {
        ...tab,
        current: tab.name === tabName
      }
    }))
  }


  // Set the current tab based on the location pathname
  useEffect(() => {
    const path = location.pathname;
    for (let tab of subNavTabs) {
      if (path.includes(tab.href)) {
        selectTab(tab.name);
        break;
      }
    }
  }, [location.pathname])

  return (
    <div>
      <TopBar>
        <div className="flex items-center gap-3">
          Labor
          <BetaBadge />
        </div>
      </TopBar>

      {/* Tabs */}
      <div className="px-6 pt-3">
        <SubNav
          tabs={subNavTabs}
          onSelected={selectTab}
        />
      </div>

      {/* Outlet for nested routes */}
      <Outlet />
    </div>
  )
}
