import React, { useState, useEffect } from "react";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import UserManager from "../../tools/UserManager";
import Spinner from "../../components/Spinner";
import Card from "../../components/Card";
import Input from "../../components/input/Input";
import toa_semi_bold from "../../assets/logos/lightMode/toa_semi-bold.svg";
import toa_semi_bold_white from "../../assets/logos/darkMode/toa_semi-bold_white.svg";
import useTheme from "../../hooks/styling/useTheme";
import axios from "axios";

export default function ResetPassword() {
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const [tokenVerified, setTokenVerified] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();

  const [searchParams] = useSearchParams();
  let email = searchParams.get("email");
  let token = searchParams.get("token");

  // on page load
  useEffect(() => {
    setLoading(true);
    UserManager.getUser()
      .then((user) => {
        if (user.loggedIn) {
          navigate("/app");
        }
      })
      .catch((err) => {
        setLoading(false);
      });

    let url =
      "/api/user/verify-forgot-pass-token" + `?email=${email}&token=${token}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.status === "ok") {
          setTokenVerified(true);
        } else {
          //TODO handle error
          setTokenVerified(false);
          setError("Invalid token");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError("Invalid token");
        setLoading(false);
      });
  }, []);

  var submitBtn = loading ? (
    <div style={{ position: "relative", minHeight: 40 }}>
      <Spinner />
    </div>
  ) : (
    <button
      type="submit"
      className="flex w-full justify-center rounded-md bg-primary-green px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-green"
    >
      Reset Password
    </button>
  );

  /**
   *
   */
  async function resetPassword(event) {
    event.preventDefault(); // Don't refresh/redirect from form

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setLoading(true);
    await axios
      .post("/api/user/reset-password", {
        token: token,
        password: password,
        confirmPassword: confirmPassword,
      })
      .then((response) => {
        if (response.data.status == "ok") {
          console.log("Password reset successful");
          // redirect to login page
          navigate("/login");
        } else {
          let msg =
            "That password is not secure enough. Password must be at least 8 characters long, have: at least 1 uppercase letter, at least 1 lowercase letter, a number, and a special character ($@#!&).";
          setError(msg);
          console.log(response.data.error);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(false);
  }

  if (tokenVerified) {
    return (
      <>
        <div className="flex flex-col pb-6">
          <div className="mt-2.5">
            <div className="flex flex-row mx-2 px-2 py-2.5 items-center justify-center flex-wrap gap-y-2">
              <div className="text-4xl font-semibold leading-10 text-center text-gray-700 align-middle text-nowrap dark:text-gray-50">
                Reset Password for
              </div>
              <img
                className="flex-none w-auto justify-self-start mx-[5px] pl-[5px] h-7"
                src={theme === "dark" ? toa_semi_bold_white : toa_semi_bold}
                alt="Toa"
              />
            </div>
          </div>
          <div className="px-2 my-2 pb-2.5">
            <div className="text-sm font-medium leading-7 text-center text-gray-900 align-middle dark:text-gray-200">
              You're resetting the password for{" "}
              <span className="font-bold"> {email} </span>
            </div>
          </div>
        </div>
        <form className="max-w-xs mx-auto space-y-6" onSubmit={resetPassword}>
          <div>
            <div className="mt-2">
              <Input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder="Password"
                label={"Password"}
                value={password}
                required
                onChange={setPassword}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <div className="mt-2">
              <Input
                id="confirm-password"
                name="confirm-password"
                type="password"
                autoComplete="current-password"
                placeholder="Confirm password"
                label={"Confirm password"}
                value={confirmPassword}
                required
                onChange={setConfirmPassword}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          {/* Error Message */}
          <div className="text-red-500">{error}</div>

          <div>{submitBtn}</div>
        </form>
      </>
    );
  } else {
    return (
      <Card className="flex flex-col items-center justify-center bg-white dark:!bg-gray-700 gap-3">
        <div className="text-xl font-medium leading-7 text-center text-gray-900 align-middle dark:text-gray-200 text-pretty">
          The link you used is invalid or expired
        </div>
        <div className="mb-1 text-sm font-normal leading-5 text-center text-pretty">
          <span
            className="font-semibold cursor-pointer text-primary-rose hover:text-primary-rose-400"
            onClick={() => navigate("/login")}
          >
            Back to login
          </span>
        </div>
      </Card>
    );
  }
}
