import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Button from "src/components/input/Button";
import UserContext, {
  UserContextType,
} from "src/contexts/user/UserContext.tsx";
import useIssueTypes from "src/hooks/data/orders/useIssues.ts";
import { IssueSeverity } from "src/hooks/data/orders/useOrders.ts";
import { Order } from "src/hooks/data/orders/useOrders.ts";
import classNames from "src/tools/classNames";
import StringHelper from "src/utils/stringHelper.ts";
import { OrderAction } from "./OrderActionButton.tsx";

type Props = {
  order: Order;
  onRunAction: (action: OrderAction, opts?: any) => void;
};

/**
 * Shows a list of issues for an order.
 * Resolved issues are hidden by default, but can be shown by clicking a button.
 * Clicking on an issue runs [onRunAction] so that it can be handled by the parent component.
 * The parent component likely uses the `useOrderModals` hook to handle the action.
 *
 * Props:
 * - order: Order - The order to display issues for
 * - onRunAction: (action: OrderAction, opts?: any) => void - Function to run an order action
 */
export default function OrderIssues({ order, onRunAction }: Props) {
  const [showResolvedIssues, setShowResolvedIssues] = useState<boolean>(false);

  // Company
  const { company, reloadCompany } = useContext<UserContextType>(UserContext);

  const [issues, setIssues] = useState(
    order.issues.filter((issue) => issue.visibility.includes(company?._id)),
  );

  const [issueTypes, getIssueTypes] = useIssueTypes(order._id, true);

  // Get issues and issue types
  useEffect(() => {
    setIssues(
      // only use issues that company has access to
      order.issues.filter((issue) => issue.visibility.includes(company?._id)),
    );
    getIssueTypes();
  }, [order, company]);
  return (
    <>
      <div className="flex flex-col items-start justify-start w-full divide-y divide-gray-100">
        {issues?.map(
          (issue, i) =>
            // only show unresolved issues
            !issue.resolved && (
              <Issue
                key={i}
                issue={issue}
                issueTypesMap={issueTypes}
                runAction={onRunAction}
              />
            ),
        )}
      </div>
      {showResolvedIssues ? (
        <div>
          <div className="py-2 text-sm font-normal">Resolved</div>
          <div className="flex flex-col items-start justify-start w-full divide-y divide-gray-100">
            {issues?.map(
              (issue, i) =>
                // only show unresolved issues
                issue.resolved && (
                  <Issue
                    key={i}
                    issue={issue}
                    issueTypesMap={issueTypes}
                    runAction={onRunAction}
                  />
                ),
            )}
          </div>
        </div>
      ) : (
        // only show the button if there are resolved issues
        issues?.filter((issue) => issue.resolved).length > 0 && (
          <div
            className="flex flex-col items-start justify-start w-full gap-2 py-2 text-sm font-normal cursor-pointer text-primary-green hover:text-primary-green-700"
            onClick={() => {
              setShowResolvedIssues(true);
            }}
          >
            ... see resolved issues
          </div>
        )
      )}
    </>
  );
}

/**
 * Displays a single issue in the Issues section.
 */
function Issue({ issue, issueTypesMap, runAction }) {
  // format createdAt like 9/10 11:33 PM
  const createdAt = moment(issue.createdAt).format("M/D h:mm A");

  return (
    <>
      <div
        className="flex flex-row items-start justify-start w-full gap-2 py-2 cursor-pointer hover:bg-gray-100"
        onClick={() => {
          // open IssueDetailsModal
          // setIssueDetailsModalOpen(true);
          runAction(OrderAction.VIEW_ISSUES, { issue });
        }}
      >
        {issue.resolved ? (
          <CheckCircleIcon
            className={classNames("w-8 h-8 text-primary-green")}
          />
        ) : (
          <ExclamationTriangleIcon
            className={classNames(
              "w-8 h-8",
              issue.severity === IssueSeverity.CRITICAL && "text-primary-rose",
              issue.severity === IssueSeverity.MAJOR && "text-secondary-orange",
              issue.severity === IssueSeverity.MINOR && "text-gray-400",
            )}
          />
        )}
        <div className="flex flex-col items-start justify-start w-full gap-1">
          <div className="">
            <div className="mr-2 inline px-1.5 overflow-hidden text-sm font-semibold text-gray-700 bg-gray-300 border border-gray-600 rounded-md max-w-52 min-w-32 text-ellipsis text-nowrap">
              {issueTypesMap?.[issue.issueType] ?? "--"}
            </div>
            <div className="inline text-base font-normal text-gray-500">
              {issue.description ? (
                <span title={issue.description}>
                  {StringHelper.truncate(issue.description, 90)}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center justify-start gap-6">
              <div className="text-xs font-normal text-gray-400">
                {createdAt}
              </div>
              <div className="text-xs font-normal text-primary-green">
                {issue?.replies?.length ?? 0}{" "}
                {issue?.replies?.length === 1 ? "Reply" : "Replies"}
              </div>
            </div>
            {!issue.resolved && (
              <div className="pr-3">
                <Button
                  variant="primary"
                  className="!text-xs !font-medium !py-0.5 !px-2 !rounded"
                  onClick={() => {}}
                >
                  Resolve
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
