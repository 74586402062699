import React, { useState } from "react";
import classNames from "src/tools/classNames";
import { FileIcon } from "lucide-react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import "./TiptapStyle.css";
import Modal from "src/components/Modal";
import { S3File } from "src/tools/S3/s3";

// Create an enum for file/image locations
export enum FileLocation {
  S3 = "s3",
  LOCAL = "local",
}

type ImageDisplayProps = {
  readOnly: boolean;
  image: S3File | File;
  onDelete?: () => void;
  imageLocation?: FileLocation;
};

/**
 * ImageDisplay component for the editor
 *
 * Displays an image in the editor
 *
 * @param readOnly - whether the editor is read only, if true, the image will not be deletable
 * @param image - the image to display
 * @param onDelete - function to delete the image
 */
export function ImageDisplay({
  readOnly,
  image,
  onDelete,
  imageLocation,
}: ImageDisplayProps) {
  const [open, setOpen] = useState(false);

  const imageUrl =
    imageLocation === FileLocation.S3
      ? (image as S3File).getObjectSignedUrl
      : URL.createObjectURL(image as File);

  return (
    <div className="w-16 h-16">
      <Modal open={open} setOpen={setOpen} transparent={true}>
        <img src={imageUrl} className="object-cover max-h-screen rounded-lg" />
      </Modal>
      <div
        className="relative w-16 h-16 border border-gray-200 rounded-lg cursor-pointer group hover:border-gray-300 hover:shadow-md"
        onClick={() => {
          setOpen(true);
        }}
      >
        {/* display the image */}
        <img src={imageUrl} className="object-cover w-full h-full rounded-lg" />
        {!readOnly && (
          <div className="absolute items-center justify-center hidden w-6 h-6 text-red-500 bg-gray-100 border border-gray-300 rounded-full cursor-pointer group-hover:flex -top-2 -right-2">
            <XMarkIcon
              className="w-4 h-4"
              strokeWidth={2.5}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

type FileDisplayProps = {
  readOnly: boolean;
  file: S3File | File;
  onDelete?: () => void;
  fileLocation?: FileLocation;
};

/**
 * FileDisplay component for the editor
 *
 * Displays a file in the editor
 *
 * @param readOnly - whether the editor is read only, if true, the file will not be deletable
 * @param file - the file to display
 * @param deleteFile - function to delete the file
 *
 * TODO: combine this with the ImageDisplay component to reduce code duplication
 */
export function FileDisplay({
  readOnly,
  file,
  onDelete,
  fileLocation,
}: FileDisplayProps) {
  const fileUrl =
    fileLocation === FileLocation.S3
      ? (file as S3File).getObjectSignedUrl
      : URL.createObjectURL(file as File);
  // unencode the file name
  const fileName =
    fileLocation === FileLocation.S3
      ? decodeURIComponent(
          (file as S3File).getObjectSignedUrl.split("/").pop().split("?")[0]
        )
      : (file as File).name;

  const fileExtension =
    fileLocation === FileLocation.S3
      ? (file as S3File).getObjectSignedUrl
          .split("/")
          .pop()
          .split("?")[0]
          .split(".")
          .pop()
          .toUpperCase()
      : (file as File).name.split(".").pop().toUpperCase();

  return (
    <div
      className="relative flex flex-row items-center justify-start w-56 h-16 gap-2 px-2 bg-gray-100 border border-gray-200 rounded-lg cursor-pointer group hover:border-gray-300 hover:shadow-md"
      onClick={() => {
        window.open(fileUrl, "_blank");
      }}
    >
      <div className="flex items-center justify-center w-12 h-12 text-white rounded-lg bg-primary-green">
        <FileIcon className="w-6 h-6" />
      </div>
      <div className="flex flex-col items-start justify-center gap-1 w-36">
        <div className="w-full overflow-hidden text-sm text-nowrap overflow-ellipsis">
          {fileName}
        </div>
        <div className="text-xs">{fileExtension}</div>
      </div>
      {!readOnly && (
        <div className="absolute items-center justify-center hidden w-6 h-6 text-red-500 bg-gray-100 border border-gray-300 rounded-full cursor-pointer group-hover:flex -top-2 -right-2">
          <XMarkIcon
            className="w-4 h-4"
            strokeWidth={2.5}
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          />
        </div>
      )}
    </div>
  );
}

/**
 * Separator component for the editor
 *
 * Displays a separator between the buttons in the toolbar and footer
 */
export function Separator() {
  return <div className="h-4 border-r-2"></div>;
}

/**
 * Button component for the toolbar and footer
 *
 * @param Icon - the icon to display
 * @param onClick - function to handle the click event
 * @param className - the class name of the button
 * @param children - the children to display inside the button
 * @param active - whether the button is active
 */
export function Btn({
  Icon,
  onClick = () => {},
  className = "",
  children = <></>,
  active = false,
}) {
  return (
    <button
      onClick={onClick}
      data-active={active}
      className={classNames(
        className,
        "p-1",
        "hover:bg-gray-300 dark:hover:bg-gray-600 rounded",
        "data-[active=true]:bg-gray-300 dark:data-[active=true]:bg-gray-600"
      )}
    >
      {Icon && <Icon className="h-[18px] w-[18px] stroke-2" />}
      {children}
    </button>
  );
}
