import { CalendarIcon, TableCellsIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React from "react";
import { MinorBadgeVariant } from "src/components/Orders/OrderRowItem.tsx";
import {
  AgreementStatus,
  Order,
  OrderStatus,
  OrderStatusLabels,
  TimestampTypes,
} from "src/hooks/data/orders/useOrders.ts";
import classNames from "../classNames";

/**
 * Gets the status of an order.
 */
export function getStatusDisplayInfo(
  order: Order,
  companyType: "installer" | "distributor"
): {
  label: string;
  variant: MinorBadgeVariant;
} {
  const waitingOnInstaller =
    order.quote?.agreements.deliveryDate.installer ===
      AgreementStatus.PENDING ||
    order.quote?.agreements.material.installer === AgreementStatus.PENDING ||
    order.quote?.agreements.amount?.installer === AgreementStatus.PENDING;
  const waitingOnDistributor =
    order.quote?.agreements.deliveryDate.distributor ===
      AgreementStatus.PENDING ||
    order.quote?.agreements.material.distributor === AgreementStatus.PENDING ||
    order.quote?.agreements.amount?.distributor === AgreementStatus.PENDING;

  /**
   * Special status variants for different user types
   * This is used to emphasize status badges with different colors.
   * For example, for a distributor, WAITING_FOR_QUOTE
   * as orange/yellow shows more urgency as it pops out to the user.
   */
  const SpecialStatusVariant = {
    installer: {
      [OrderStatus.WAITING_FOR_QUOTE_APPROVAL]: MinorBadgeVariant.WARNING,
      [OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT]: waitingOnInstaller
        ? MinorBadgeVariant.WARNING
        : MinorBadgeVariant.DEFAULT,
      [OrderStatus.DELIVERED]: MinorBadgeVariant.SUCCESS,
    },
    distributor: {
      [OrderStatus.WAITING_FOR_QUOTE]: MinorBadgeVariant.WARNING,
      [OrderStatus.CONFIRMED]: MinorBadgeVariant.WARNING,
      [OrderStatus.PACKAGED]: MinorBadgeVariant.WARNING,
      [OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT]: waitingOnDistributor
        ? MinorBadgeVariant.WARNING
        : MinorBadgeVariant.DEFAULT,
      [OrderStatus.DELIVERED]: MinorBadgeVariant.SUCCESS,
    },
  };

  /**
   * Special status labels for different user types
   * This is used to override the default status labels
   * Useful for showing additional emphasis to the user
   * For example, for a distributor, "Needs Quote" instead
   * of "Waiting for Quote" shows more urgency.
   */
  const SpecialStatusLabels = {
    installer: {
      [OrderStatus.WAITING_FOR_QUOTE_APPROVAL]: "Needs Approval",
    },
    distributor: {
      [OrderStatus.WAITING_FOR_QUOTE]: order.quoteRequired
        ? "Needs Quote"
        : "Needs Review",
      [OrderStatus.CONFIRMED]: "Needs Pick Ticket",
      [OrderStatus.PACKAGED]: "Needs Driver",
    },
  };

  // --- Basic status label and variant from mappings --- //
  let label =
    SpecialStatusLabels[companyType]?.[order.status] ??
    OrderStatusLabels[order.status];
  let variant = SpecialStatusVariant[companyType]?.[order.status];
  if (variant instanceof Function) variant = variant(order);
  if (!variant) variant = MinorBadgeVariant.DEFAULT;

  // --- Special cases for actions and variants --- //
  // Need to confirm reschedule
  if (
    // Before CONFIRMED (ignore CANCELED)
    ![
      OrderStatus.WAITING_FOR_QUOTE,
      OrderStatus.WAITING_FOR_QUOTE_APPROVAL,
      OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT,

      OrderStatus.CANCELED,
    ].includes(order.status) &&
    // PENDING date
    order.quote?.agreements.deliveryDate[companyType] ===
      AgreementStatus.PENDING
  ) {
    label = "Needs Date Approval";
    variant = MinorBadgeVariant.WARNING;
  }

  return {
    label: label,
    variant: variant,
  };
}

/**
 * Formats orders for the Delivery Scorecard table.
 *
 * @param {Array} orders - list of orders
 * @param {Array} markets - list of markets
 * @returns {Array} - formatted orders
 */
export function formatOrdersForTable(orders, markets) {
  return orders?.map((o, i) => {
    // Build city state string
    var address = null;
    if (
      o.orderAddress?.line1 ||
      o.orderAddress?.line2 ||
      o.orderAddress?.city ||
      o.orderAddress?.state
    ) {
      address = `${o.orderAddress?.line1 ? o.orderAddress?.line1 : ""}${
        o.orderAddress?.line2 ? " " + o.orderAddress?.line2 : ""
      }${o.orderAddress?.city ? ", " + o.orderAddress?.city : ""}${
        o.orderAddress?.state ? ", " + o.orderAddress?.state : ""
      }`;
      // trim outer spaces and commas twice
      address = address
        .trim()
        .replace(/^,|,$/g, "")
        .trim()
        .replace(/^,|,$/g, "");
    }

    var deliveryDeadline = `${moment(
      o?.requestedDelivery?.deliveryWindow?.end
    ).format("ddd, MMM D @ h:mm A")}`;

    // get the most recent timestamp where TimestampTypes.STATUS_CHANGED and newStatus of DELIVERED, sorted by timestamp.stamp
    const mostRecentDeliveredTimestamp = o.timestamps
      .filter((t) => {
        return (
          t.type === TimestampTypes.STATUS_CHANGED &&
          t.typeInfo.newStatus === OrderStatus.DELIVERED
        );
      })
      .sort((a, b) => {
        return a.stamp - b.stamp;
      })
      .pop();

    var deliveryTimestamp = o.deliveryTimestamp
      ? o.deliveryTimestamp
      : mostRecentDeliveredTimestamp?.stamp;

    var deliveryTimestampString = null;
    var timeStatus = null;

    if (deliveryTimestamp) {
      // format t.stamp like "Thur, June 2 @ 10:00 AM"
      deliveryTimestampString = moment(deliveryTimestamp).format(
        "ddd, MMM D @ h:mm A"
      );

      // check if the timestamp is within the delivery window
      if (
        (o?.requestedDelivery?.deliveryWindow?.end &&
          deliveryTimestamp <= o.requestedDelivery.deliveryWindow.end) ||
        (!o?.requestedDelivery?.deliveryWindow?.end &&
          o?.requestedDelivery?.deliveryWindow?.start &&
          deliveryTimestamp <= o?.requestedDelivery?.deliveryWindow?.start)
      ) {
        timeStatus = "On Time";
      } else {
        timeStatus = "Late";
      }
    } else {
      deliveryTimestampString = "--";
      timeStatus = "--";
    }

    var materialStatus = "--";

    var driverMaterialAccuracy = o.materialAccuracy.driver.status;
    var installerMaterialAccuracy = o.materialAccuracy.installer.status;

    // if either driver or installer material accuracy is "INCOMPLETE", then the material status is "Incomplete"
    // else if either is "COMPLETE", then the material status is "Complete"
    // else material status is "--"
    if (
      driverMaterialAccuracy === "INCOMPLETE" ||
      installerMaterialAccuracy === "INCOMPLETE"
    ) {
      materialStatus = "Incomplete";
    } else if (
      driverMaterialAccuracy === "COMPLETE" ||
      installerMaterialAccuracy === "COMPLETE"
    ) {
      materialStatus = "Complete";
    }

    // Get market
    var market = markets?.find((m) => m._id === o.marketId);
    // TODO: handle null markets

    const installDate = o?.installationDate
      ? moment(o.installationDate).utc().format("ddd, MMM D")
      : null;

    // Return formatted job
    var formattedOrder = {
      orderId: o._id,
      jobName: o.jobName,
      link: {
        href: `/app/orders/details/${o._id}`,
      },
      address: address,
      deliveryDeadline: deliveryDeadline,
      requestedDeliveryStart: o?.requestedDelivery?.deliveryWindow?.start,
      requestedDeliveryEnd: o?.requestedDelivery?.deliveryWindow?.end,
      deliveryTime: deliveryTimestampString,
      installDate: installDate,
      timeStatus: timeStatus,
      materialStatus: materialStatus,
      orderName: o.name,
      marketName: market?.name,
      statusIcons: (
        <div className="flex flex-row items-center justify-center gap-2">
          <div
            className={classNames(
              "flex items-center justify-center rounded-full w-7 h-7",
              timeStatus === "On Time" ? "bg-primary-green" : "bg-primary-rose"
            )}
          >
            <CalendarIcon className="w-4 h-4 text-white stroke-2" />
          </div>
          <div
            className={classNames(
              "flex items-center justify-center rounded-full w-7 h-7",
              materialStatus === "Complete"
                ? "bg-primary-green"
                : "bg-primary-rose"
            )}
          >
            <TableCellsIcon className="w-4 h-4 text-white stroke-2" />
          </div>
        </div>
      ),
    };

    return formattedOrder;
  });
}
