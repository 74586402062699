import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useDistributors, { Distributor } from "./useDistributors.ts";

/**
 * Returns the visible distributors of the user's company along with holding state
 * for selected distributors.
 *
 * Before distributors are fetched, returns null for installers and selected.
 */
export default function useMultiDistributorSelect(
  defaultAll: boolean = false,
): [Distributor[], Distributor[], Dispatch<SetStateAction<Distributor[]>>] {
  const distributors = useDistributors();
  const [selected, setSelected] = useState();
  useEffect(() => {
    if (distributors && defaultAll) {
      setSelected(distributors);
    }
  }, [distributors, defaultAll]);
  return [distributors, selected, setSelected];
}
