import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import Badge from "src/components/Badge.tsx";
import UserManager from "../UserManager";
import SimpleOrderStatuses from "src/components/Orders/common/SimpleOrderStatuses.tsx";
import SimpleOrderInstallDates from "src/components/Orders/common/SimpleOrderInstallDates.tsx";

/**
 * Formats jobs for the jobs table.
 *
 * @param {Array} jobs - list of jobs
 * @param {Array} stages - list of stages
 * @param {Array} markets - list of markets
 * @returns {Array} - formatted jobs
 */
export function formatJobsForTable(jobs, stages, markets) {
  if (!stages) return null;

  return jobs?.map((j, i) => {
    // Build city state string
    var cityState = null;
    if (j.address?.city || j.address?.state) {
      cityState = `${j.address?.city}${j.address?.city ? ", " : ""}${
        j.address?.state
      }`;
    }

    // Order job stages based on stages order
    var jobStages = [];
    for (let k = 0; k < stages.length; k++) {
      let stage = stages[k];
      // var jobStage = j.stages.find((s) => s.stage === stage._id); // DEPRECATED
      // jobStage.name = stage.name;
      let stageDate = j.stageMap[stage._id];
      jobStages.push({
        stage: stage._id,
        date: stageDate,
        name: stage.name,
      });
    }

    // TODO: rework for stage map

    // Get the stage after the last stage with a date
    // If all stages have dates, TODO: figure out
    var stage = null;
    if (jobStages.length) {
      stage = jobStages[0]; // Default is first stage
      // TODO: switch this
      for (let k = jobStages.length - 1; k >= 0; k--) {
        // If stage has a date, set stage to successor (i.e. stage without a date)
        if (jobStages[k].date) {
          // If last stage has a date, set stage to completed
          if (k === jobStages.length - 1) {
            stage = {
              name: (
                <CheckIcon className="w-4 h-4 stroke-2 text-primary-green" />
              ),
              completedDate: jobStages[k].date,
            };
            break;
          }
          stage = jobStages[k + 1];
          stage.started = jobStages[k].date;
          break;
        }
      }
    }

    // Get market
    var market = markets?.find((m) => m._id === j.market);
    // TODO: handle null markets

    // Get days since created
    // Get first stage date
    var firstStageDate = null;
    for (let _stage in stages) {
      if (j.stageMap[_stage._id]) {
        firstStageDate = j.stageMap[_stage._id];
        break;
      }
    }

    // Calculate days since sold (if complete use completed date instead of today)
    let soldDate = j.stageMap[stages[0]._id]
      ? moment.utc(j.stageMap[stages[0]._id])
      : null;
    var endDate = stage.hasOwnProperty("completedDate")
      ? moment.utc(stage.completedDate)
      : moment.utc();
    var daysSinceCreated = endDate.diff(firstStageDate || soldDate, "days");

    // Get days in stage
    if (stage && stage.started) {
      var stageDate = moment.utc(stage.started);
      var daysInStage = endDate.diff(stageDate, "days");
    }

    // Return formatted job
    var formattedJob = {
      cityState: cityState,
      name: j.name,
      systemSize: formatKW(j.systemSize),
      stage: j.isCanceled ? "Canceled" : stage?.name,
      stageAndDays:
        stage?.name && daysInStage
          ? `${stage.name} | ${daysInStage} / ${daysSinceCreated || "-"} Day${
              daysSinceCreated != 1 ? "s" : ""
            }`
          : stage?.name || "",
      market: market?.name,
      daysSinceCreated: daysSinceCreated || null,
      daysInStage: daysInStage || null,
      importedId: j.importedId,
      cycleTime: Math.round(j.cycleTime),
      stage1Date: j.date1 ? moment.utc(j.date1).format("M/D/YY") : "",
      closeDate: j.closeDate ? moment.utc(j.closeDate).format("M/D/YY") : "",
      completed: j.completed ? true : false,
      completedIcon: j.completed ? (
        <CheckIcon className="w-4 h-4 stroke-2 text-primary-green" />
      ) : (
        <XMarkIcon className="w-4 h-4 text-red-500 stroke-2" />
      ),
      installDate: moment.utc(stage?.completedDate).format("M/D/YY"),
      soldDate: j.stageMap[stages[0]._id]
        ? moment.utc(j.stageMap[stages[0]._id]).format("M/D/YY")
        : "-",
      link: {
        href: `/app/jobs/${j._id}`,
        target: "_blank",
      },
      orderCount: j.orders?.length || 0,
      orderCountDisplay: j.orders?.length ? (
        <div className="flex">
          <div className="bg-gray-300 text-gray-900 text-sm rounded-full px-5 h-6 flex items-center">
            {j.orders?.length}
          </div>
        </div>
      ) : (
        "--"
      ),
      orderStatus: <SimpleOrderStatuses orders={j.orders} />,
      orderInstallDate: <SimpleOrderInstallDates orders={j.orders} />,
    };

    // Add time in each stage (for each stage, time since previous stage)
    for (let k = 1; k < jobStages.length; k++) {
      let stage = jobStages[k];
      var prevStage = jobStages[k - 1];

      // If has previous stage, calculate time in stage
      if (prevStage.date) {
        var date = stage.date || moment.utc().startOf("day");

        stage.timeInStage = moment(date)
          .utc()
          .startOf("day")
          .diff(moment.utc(prevStage.date).startOf("day"), "days");
      } else {
        stage.timeInStage = null;
      }

      formattedJob[stage.stage] = stage.timeInStage;
    }

    return formattedJob;
  });
}

/**
 * Converts a number to a KW string
 */
export function formatKW(num) {
  let string =
    Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(num) + "W";

  string = string.replace("K", "k"); // Use lowercase k
  string = string.replace(/([0-9,.]+)([a-zA-Z]+)/, "$1 $2"); // Add space between number and unit
  return string;
}

const dateFilter = moment
  .utc()
  .subtract(12, "months")
  .startOf("month")
  .toISOString();

/**
 * Retrieves jobs, stages, and pipeline stats.
 *
 * @param {Number} page - page number
 * @param {String} stageId - stage ID
 * @param {Array} marketIds - market IDs
 * @param {String} search - search string
 * @param {String} date - date filter
 * @param {Function} setJobs - set jobs function
 * @param {Function} setStages - set stages function
 * @param {Function} setCycleTimeStats - set cycle time stats function
 * @param {Function} setConversionStats - set conversion stats function
 * @param {Function} setInstallStats - set installs per month stats function
 * @param {Function} setSoldStats - set sold per month stats function
 * @param {Function} setPagination - set pagination function
 * @param {Object} pagination - pagination object
 */
export function retrieveJobs(
  page,
  stageId,
  marketIds,
  search,
  date = dateFilter,
  setJobs,
  setStages,
  setCycleTimeStats,
  setConversionStats,
  setInstallStats,
  setSoldStats,
  setPagination,
  pagination,
  hideJobsWithOrders = false,
) {
  // TODO: URI encode searches?

  var jobQuery =
    `?page=${page || pagination.currentPage}` +
    `&pageSize=${pagination.itemsPerPage}` +
    (search ? "" : `&excludeCompleted=true`) +
    (search ? "" : `&onlyActive=true`) +
    `${stageId ? `&stage=${stageId}` : ""}` +
    `${marketIds && marketIds.length ? `&markets=${marketIds}` : ""}` +
    `${search ? `&search=${search}` : ""}` +
    `${hideJobsWithOrders ? `&hideJobsWithOrders=true` : ""}`;

  // Query jobs
  UserManager.makeAuthenticatedRequest(
    `/api/jobs/installer/find${jobQuery}`,
    "GET",
  )
    .then((res) => {
      var status = res.data.status;
      if (status === "ok") {
        var { count: jobCount, page: currPage, jobs: jobList } = res.data;

        // Update jobs
        setJobs(jobList);

        // Update pagination
        setPagination({
          ...pagination,
          currentPage: currPage,
          totalItems: jobCount,
        });
      }
    })
    .catch((err) => {
      console.error(err);
    });

  // Query stages
  var stageQuery =
    `?includeStats=true` +
    `${marketIds && marketIds.length ? `&markets=${marketIds}` : ""}` +
    `${search ? `&search=${search}` : ""}` +
    `${date ? `&date=${date}` : ""}`;
  // TODO: figure out why the date filter made conversion rate show up. was 0 without date
  UserManager.makeAuthenticatedRequest(
    `/api/stages/installer/find${stageQuery}`,
    "GET",
  )
    .then((res) => {
      var status = res.data.status;
      if (status === "ok") {
        var { stages, stats } = res.data;

        // Weave stats into stages
        for (var i = 0; i < stages.length; i++) {
          stages[i] = {
            ...stages[i],
            ...stats[i],
          };
        }
        setStages(stages);
      }
    })
    .catch((err) => {
      console.error(err);
    });

  // Query pipeline stats (cycle time, conversion rate, installs per month, sold per month)
  var query =
    `?` +
    `${marketIds && marketIds.length ? `&market=${marketIds}` : ""}` +
    `${search ? `&search=${search}` : ""}` +
    `${stageId ? `&stage=${stageId}` : ""}` +
    `${date ? `&date=${date}` : ""}`;
  UserManager.makeAuthenticatedRequest(
    `/api/jobs/installer/pipeline-stats${query}`,
    "GET",
  )
    .then((res) => {
      var status = res.data.status;
      if (status === "ok") {
        var { cycleTimes, conversionRates, installsPerMo, soldPerMo } =
          res.data;

        // Cycle time
        var {
          avgCycleTime,
          jobCount: cycleTimeJobCount,
          minCycleTime,
          maxCycleTime,
          stdevCycleTime,
          summedCycleTime,
          byMonth: cycleTimeByMonth,
          byMarket: cycleTimeByMarket,
          last30: last30CycleTime,
          last90: last90CycleTime,
        } = cycleTimes;

        // Sort cycle time by month
        cycleTimeByMonth.sort((a, b) => {
          // Sort by year first then month
          if (a._id.year < b._id.year) return -1;
          if (a._id.year > b._id.year) return 1;
          return a._id.month - b._id.month;
        });

        // Update cycle time stats
        setCycleTimeStats({
          avgCycleTime,
          minCycleTime,
          maxCycleTime,
          stdevCycleTime,
          summedCycleTime,
          jobCount: cycleTimeJobCount,
          byMonth: cycleTimeByMonth,
          byMarket: cycleTimeByMarket,
          last30: last30CycleTime,
          last90: last90CycleTime,
        });

        // Conversion rate
        var {
          conversionRate: conversionRateStat,
          completedJobs,
          jobCount: convJobCount,
          cutoffDate,
          byMonth: convByMonth,
          byMarket: conByMarket,
        } = conversionRates;

        // Sort conversion rate by month
        convByMonth.sort((a, b) => {
          // Sort by year first then month
          if (a._id.year < b._id.year) return -1;
          if (a._id.year > b._id.year) return 1;
          return a._id.month - b._id.month;
        });

        // Update conversion rate stats
        setConversionStats({
          conversionRate: conversionRateStat,
          completedJobs,
          jobCount: convJobCount,
          cutoffDate,
          byMonth: convByMonth,
          byMarket: conByMarket,
        });

        // Installs per month
        var {
          avgInstallsPerMo,
          installsByMo,
          last30: last30Installs,
          last90: last90Installs,
        } = installsPerMo;

        // Presorted

        // Update installs per month stats
        setInstallStats({
          avgInstallsPerMo,
          installsByMo,
          last30: last30Installs,
          last90: last90Installs,
        });

        // Sold per month
        var {
          avgSoldPerMo,
          soldByMo,
          last30: last30Sold,
          last90: last90Sold,
        } = soldPerMo;

        // Presorted

        // Update sold per month stats
        setSoldStats({
          avgSoldPerMo,
          soldByMo,
          last30: last30Sold,
          last90: last90Sold,
        });
      }
    })
    .catch((err) => {
      console.error(err);
    });
}
