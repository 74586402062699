import { PlusCircleIcon } from "@heroicons/react/20/solid";
import {
  HomeIcon,
  TrashIcon,
  TruckIcon,
  XMarkIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { UploadIcon, WarehouseIcon } from "lucide-react";
import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Card from "src/components/Card";
import DownloadOrderImages from "src/components/download/DownloadOrderImages.tsx";
import Button from "src/components/input/Button";
import Dropdown from "src/components/input/Dropdown";
import SimpleEdit from "src/components/input/helpers/SimpleEdit.tsx";
import Input from "src/components/input/Input";
import Modal from "src/components/Modal";
import TopBar from "src/components/nav/TopBar";
import OrderActionButton, {
  OrderAction,
} from "src/components/Orders/common/OrderActionButton.tsx";
import OrderIssues from "src/components/Orders/common/OrderIssues.tsx";
import OrderNotes from "src/components/Orders/common/OrderNotes.tsx";
import SecondaryOrderActions from "src/components/Orders/common/SecondaryOrderActions.tsx";
import StatusDisplay from "src/components/Orders/common/StatusDisplay.tsx";
import AddNoteModal from "src/components/Orders/modals/AddNoteModal.tsx";
import {
  AgreementBubble,
  AgreementTypes,
  getAgreementStatus,
} from "src/components/Orders/OrderAgreementIcons.tsx";
import {
  getActualDeliveryDateString,
  getDeliveryDateString,
  MinorBadge,
} from "src/components/Orders/OrderRowItem.tsx";
import OrderTimeline from "src/components/Orders/OrderTimeline.tsx";
import Spinner from "src/components/Spinner";
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext";
import UserContext, {
  UserContextType,
} from "src/contexts/user/UserContext.tsx";
import useOrderModals from "src/hooks/actions/useOrderModals.tsx";
import useDistributors, {
  Distributor,
} from "src/hooks/data/connections/distributors/useDistributors.ts";
import useInstallers from "src/hooks/data/connections/installers/useInstallers.ts";
import useS3URLs from "src/hooks/data/files/useS3.ts";
import { LocationType } from "src/hooks/data/locations/useLocations.ts";
import useLocationsByDistributor from "src/hooks/data/locations/useLocationsByDistributor.ts";
import useMarketsByInstaller from "src/hooks/data/markets/useMarketsByInstaller.ts";
import useIssueTypes from "src/hooks/data/orders/useIssues.ts";
import useOrder from "src/hooks/data/orders/useOrder.ts";
import { Order } from "src/hooks/data/orders/useOrders.ts";
import { Truck } from "src/hooks/data/trucks/useTrucks.ts";
import useTrucksByDistributor from "src/hooks/data/trucks/useTrucksByDistributor.ts";
import useUsersByCompany from "src/hooks/data/users/useUsersByCompany.ts";
import useUsersByIds, { User } from "src/hooks/data/users/useUsersByIds.ts";
import classNames from "src/tools/classNames";
import S3, { OrderFileType, S3File } from "src/tools/S3/s3.ts";
import UserManager from "src/tools/UserManager";
import AddressHelper from "src/utils/addressHelper.ts";
import { Installer } from "src/hooks/data/connections/installers/useInstallers.ts";

type Props = {};

type OrderDetailsContextType = {
  order: Order | null;
  setOrder: React.Dispatch<React.SetStateAction<Order | null>>;

  bomLink: string | null;
  quoteLink: string | null;
  pickTicketLink: string | null;

  user: User | null;
  companyType: "installer" | "distributor" | "account" | null;

  distributor: Distributor | null;
  installer: Installer | null;

  market: MarketType | null;
  markets: MarketType[] | null;

  location: LocationType | null;
  locations: LocationType[] | null;

  sharedWith: User[] | null;
  installerUsers: User[] | null;

  trucks: Truck[] | null;

  runAction?: (a: OrderAction, opts?: any) => void;
  reloadOrder?: () => void;
};

const OrderDetailsContext = createContext<OrderDetailsContextType>({
  order: null,
  setOrder: () => {},

  bomLink: null,
  quoteLink: null,
  pickTicketLink: null,

  user: null,
  companyType: null,

  distributor: null,
  installer: null,

  market: null,
  markets: null,

  location: null,
  locations: null,

  sharedWith: null,
  installerUsers: null,

  trucks: null,

  runAction: (a: OrderAction, opts: any = {}) => {},
  reloadOrder: () => {},
});

/**
 * Page for viewing details of a single order. Currently displays:
 * - Metadata like dates, address, contact, PO/SO#, etc.
 * - Notes
 * - Activity timeline
 * - Attachments
 * - Photos
 *
 * Still needs:
 * - Verification
 * - Issues
 */
export default function OrderDetailsPage({}: Props) {
  // ---------------------- //
  // --- Hooks & States --- //
  // ---------------------- //

  // Page params
  const params = useParams();

  // Order
  const orderId = params?.id;
  const [orderHook, reloadOrder] = useOrder(orderId);
  const [order, setOrder] = useState<Order | null>(orderHook);

  // Links
  const bomLinkArr = useS3URLs(
    order?.files.billOfMaterials.at(-1)?.filePath
      ? [order.files.billOfMaterials.at(-1)?.filePath]
      : []
  );
  const quoteLinkArr = useS3URLs(
    order?.quote?.file?.filePath ? [order.quote.file.filePath] : []
  );
  const pickTicketLinkArr = useS3URLs(
    order?.files.pickTickets.at(-1)?.filePath
      ? [order.files.pickTickets.at(-1)?.filePath]
      : []
  );
  const bomLink = bomLinkArr?.length ? bomLinkArr[0].getObjectSignedUrl : null;
  const quoteLink = quoteLinkArr?.length
    ? quoteLinkArr[0].getObjectSignedUrl
    : null;
  const pickTicketLink = pickTicketLinkArr?.length
    ? pickTicketLinkArr[0].getObjectSignedUrl
    : null;

  // Images
  const [packagingImages, setPackagingImages] = useState<S3File[]>([]);
  const [deliveryImages, setDeliveryImages] = useState<S3File[]>([]);
  const [installerVerificationImages, setInstallerVerificationImages] =
    useState<S3File[]>([]);
  const [otherImages, setOtherImages] = useState<S3File[]>([]);

  // Additional Attachments
  const [additionalAttachments, setAdditionalAttachments] = useState<S3File[]>(
    []
  );

  // User
  const { user } = useContext<UserContext>(UserContext);
  const companyType = user?.company?.type;

  // Shared with users
  const usersMap = useUsersByIds(
    order
      ? [
          order?.primaryContactId,
          order?.installerOnSiteLeadId,
          ...order.sharedWithIds,
        ]
      : []
  );
  const users = usersMap ? Object.values(usersMap) : [];

  // Installer users (for onsite lead)
  const installerUsers = useUsersByCompany(order?.installerId, "installer");

  // Trucks
  const trucks = useTrucksByDistributor(order?.distributorId);

  // Market
  const markets = useMarketsByInstaller(order?.installerId);
  const market = markets?.find((m) => m._id === order?.marketId);

  // Location
  const locations = useLocationsByDistributor(order?.distributorId);
  const location = locations?.find((l) => l._id === order?.locationId);

  // Installer and Distributor
  const distributors = useDistributors();
  const distributor = distributors?.find((d) => d._id === order?.distributorId);

  const installers = useInstallers();
  const installer = installers?.find((i) => i._id === order?.installerId);

  // Order modals/actions
  const [orderModals, runAction] = useOrderModals(order, () => {
    reloadOrder();
  });

  // --------------- //
  // --- Effects --- //
  // --------------- //

  // Update order state when hook updates
  useEffect(() => {
    setOrder(orderHook);
  }, [orderHook]);

  // retrieve all images and attachments for the order
  useEffect(() => {
    if (order) {
      S3.getOrderImageLinks(OrderFileType.PACKAGING_IMG, order._id)
        .then((res) => {
          setPackagingImages(res);
        })
        .catch((err) => {
          console.error(err);
        });

      S3.getOrderImageLinks(OrderFileType.DELIVERY_IMG, order._id)
        .then((res) => {
          setDeliveryImages(res);
        })
        .catch((err) => {
          console.error(err);
        });

      S3.getOrderImageLinks(OrderFileType.INSTALLER_VERIF_IMG, order._id)
        .then((res) => {
          setInstallerVerificationImages(res);
        })
        .catch((err) => {
          console.error(err);
        });

      // TODO: restructure S3 a little bit to include an "other_images" folder
      // TODO: also add a folder for quotes
      // TODO: consider renaming "delivery_images" to "delivery_verification_images", and "packaging_images" to "packaging_verification_images" to match with the "installer_verification_images" folder
      S3.getOrderImageLinks(OrderFileType.OTHER_IMG, order._id)
        .then((res) => {
          setOtherImages(res);
        })
        .catch((err) => {
          console.error(err);
        });

      getAdditionalAttachments();
    }
  }, [order]);

  // ----------------- //
  // --- Functions --- //
  // ----------------- //

  /**
   * Get presigned URLs for additional attachments in the order.
   */
  async function getAdditionalAttachments() {
    const filePaths = order.files.additionalFiles?.map(
      (file) => file?.filePath
    );

    setAdditionalAttachments(await S3.get(filePaths));
  }

  // ----------------------- //
  // --- Special Renders --- //
  // ----------------------- //

  // Navigate to order list if no order found
  if (!orderId) {
    return <Navigate to="../orders" />;
  }

  // Loading state
  if (!order) {
    return (
      <div className="flex items-center h-screen">
        <Spinner />
      </div>
    );
  }

  // -------------- //
  // --- Consts --- //
  // -------------- //

  const orderContextValue: OrderDetailsContextType = {
    order,
    setOrder: setOrder,

    bomLink,
    quoteLink,
    pickTicketLink,

    user: user,
    companyType: companyType,

    distributor: distributor,
    installer: installer,

    market: market,
    markets: markets,

    location: location,
    locations: locations,

    sharedWith: users,
    installerUsers: installerUsers,

    trucks: trucks,

    runAction: runAction,
    reloadOrder: reloadOrder,
  };

  //  Check if there is a previous page to navigate back to
  const hasPrevPage = window.history.length > 1;

  return (
    <OrderDetailsContext.Provider value={orderContextValue}>
      <TopBar>
        <div className="flex items-center justify-between grow">
          <p>{order.name}</p>
          {hasPrevPage && (
            <Link
              to={-1} // Go back to previous page
              className="text-gray-500 hover:text-gray-700"
            >
              <XMarkIcon className="w-6 h-6" />
            </Link>
          )}
        </div>
      </TopBar>
      <div className="flex flex-col px-8 py-6 lg:flex-row lg:flex-wrap gap-x-5 gap-y-3">
        {/* Full Width Top Section */}
        <div className="w-full">
          <Metadata />
        </div>

        {/* 2/3 Width Left Section */}
        <div className="flex flex-col lg:basis-1/2 lg:grow gap-y-3">
          <Attachments
            additionalAttachments={additionalAttachments}
            setAdditionalAttachments={setAdditionalAttachments}
          />
          <Photos
            packagingImages={packagingImages}
            deliveryImages={deliveryImages}
            installerVerificationImages={installerVerificationImages}
            otherImages={otherImages}
          />
          {/* TODO: verification & issues? */}
        </div>

        {/* 1/3 Width Left Section */}
        <div className="flex flex-col grow lg:basis-1/3 gap-y-3">
          <Issues />
          <Notes />
          <Activity />
        </div>
      </div>

      {orderModals}
    </OrderDetailsContext.Provider>
  );
}

/**
 * Displays the main large card with info about the order.
 * This includes:
 * - Delivery date
 * - Install date
 * - Material (BOM & Quote)
 * - Quote amount
 * - Address
 * - Contact info
 * - PO#, SO#, Loan #
 * - Shared with list
 * - Main action
 *
 * Still needs:
 * - TOA ID#
 * - Actions
 * - Issue count
 */
function Metadata({}) {
  const {
    order,
    setOrder,
    bomLink,
    quoteLink,
    pickTicketLink,
    user,
    companyType,
    distributor,
    installer,
    market,
    markets,
    location,
    locations,
    sharedWith,
    installerUsers,
    trucks,
    runAction,
  } = useContext<OrderDetailsContextType>(OrderDetailsContext);

  const deliveryString = getDeliveryDateString(order);
  const deliveredString = getActualDeliveryDateString(order);
  const installString = order.installationDate
    ? moment(order.installationDate).utc().format("ddd M/D")
    : null;

  const deliveryDateStatus = getAgreementStatus(
    order.quote?.agreements?.deliveryDate
  );
  const materialStatus = getAgreementStatus(order.quote?.agreements?.material);
  const amountStatus = getAgreementStatus(order.quote?.agreements?.amount);

  // ------------- //
  // --- State --- //
  // ------------- //

  const [newName, setNewName] = useState<string>(order.name);
  const [newContact, setNewContact] = useState<{
    name: string;
    phone: string;
    email: string;
  }>({
    name: order.contact?.name || "",
    phone: order.contact?.phone || "",
    email: order.contact?.email || "",
  });
  const [newMarket, setNewMarket] = useState<string>(order.marketId);
  const [newLocation, setNewLocation] = useState<string>(order.locationId);
  const [newPO, setNewPO] = useState<string>(order.poNumber || "");
  const [newSO, setNewSO] = useState<string>(order.soNumber || "");
  const [newAddress, setNewAddress] = useState<{
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postalCode: number;
  }>(order.orderAddress);
  const [newOnSiteLead, setNewOnSiteLead] = useState<string>(
    order.installerOnSiteLeadId
  );
  const [newTruck, setNewTruck] = useState<string>(order.truck);

  // ----------------- //
  // --- Functions --- //
  // ----------------- //

  /**
   * Makes the server request to PUT `value` into `order[field]`.
   * If the request fails, `onFail` is called.
   */
  async function put(field: string, value: any, onFail: () => void) {
    try {
      var res = await UserManager.makeAuthenticatedRequest(
        "/api/orders/update-fields",
        "PUT",
        {
          orderId: order._id,
          updates: [{ field, value }],
        }
      );
    } catch (err) {
      onFail();
      return;
    }

    // Update order state if successful
    if (res?.data?.status === "ok") {
      setOrder((prev: Order) => {
        if (prev) {
          return {
            ...prev,
            [field]: value,
          };
        }
        return prev;
      });
    }
    // Otherwise, call onFail
    else {
      onFail();
    }
  }

  // --- Name --- //

  /**
   * Update the name field on the order
   */
  function saveName() {
    put("name", newName, resetName);
  }

  /**
   * Reset the name input to match the order's value
   */
  function resetName() {
    setNewName(order.name);
  }

  // --- Place --- //

  /**
   * Update the market field on the order
   */
  function saveMarket() {
    put("marketId", newMarket, resetMarket);
  }

  /**
   * Reset the market input to match the order's value
   */
  function resetMarket() {
    setNewMarket(order.marketId);
  }

  /**
   * Update the location field on the order
   */
  function saveLocation() {
    put("locationId", newLocation, resetLocation);
  }

  /**
   * Reset the location input to match the order's value
   */
  function resetLocation() {
    setNewLocation(order.locationId);
  }

  // --- Address --- //

  /**
   * Update the order's address
   */
  function saveAddress() {
    put("orderAddress", newAddress, resetAddress);
  }

  /**
   * Reset the address inputs to match the order's values
   */
  function resetAddress() {
    setNewAddress(order.orderAddress);
  }

  // --- Contact --- //

  /**
   * Update the contact field on the order
   */
  function saveContact() {
    put("contact", newContact, resetContact);
  }

  /**
   * Reset the contact inputs to match the order's values
   */
  function resetContact() {
    setNewContact({
      name: order.contact?.name || "",
      phone: order.contact?.phone || "",
      email: order.contact?.email || "",
    });
  }

  // --- PO/SO/Loan --- //

  /**
   * Update the PO # field on the order
   */
  function savePO() {
    put("poNumber", newPO, resetPO);
  }

  /**
   * Reset the PO input to match the order's value
   */
  function resetPO() {
    setNewPO(order.poNumber || "");
  }

  /**
   * Update the SO # field on the order
   */
  function saveSO() {
    put("soNumber", newSO, resetSO);
  }

  /**
   * Reset the SO input to match the order's value
   */
  function resetSO() {
    setNewSO(order.soNumber || "");
  }

  // --- Onsite Lead --- //

  /**
   * Update the order's on site lead by ID
   */
  function saveOnSiteLead() {
    put("installerOnSiteLeadId", newOnSiteLead, resetOnSiteLead);
  }

  /**
   * Reset the on site lead input to match the order's value
   */
  function resetOnSiteLead() {
    setNewOnSiteLead(order.installerOnSiteLeadId);
  }

  // --- Truck --- //

  /**
   * Update the order's truck by ID
   */
  function saveTruck() {
    put("truck", newTruck, resetTruck);
  }

  /**
   * Reset the truck input to match the order's value
   */
  function resetTruck() {
    setNewTruck(order.truck);
  }

  return (
    <Card>
      {/* Top Section */}
      <div className="flex items-center gap-3 pb-3 border-b border-gray-300">
        {distributor && (
          <MinorBadge>
            <span className="text-xl font-semibold">{distributor?.name}</span>
          </MinorBadge>
        )}
        {installer && (
          <MinorBadge>
            <span className="text-xl font-semibold">{installer?.name}</span>
          </MinorBadge>
        )}
        {/* TODO: TOA ID# */}
        <h1 className="text-xl font-semibold leading-7">
          <SimpleEdit
            // disabled={companyType !== "installer"}
            states={{
              base: order.name,
              editing: (
                <Input
                  value={newName}
                  placeholder="Order Name"
                  onChange={(v) => setNewName(v)}
                />
              ),
            }}
            onSave={saveName}
            onCancel={resetName}
          />
        </h1>
        <h1 className="text-lg font-normal leading-7 text-gray-600">
          {order.poNumber}
        </h1>
        <StatusDisplay order={order} companyType={user?.company?.type} />
        <div className="ml-auto">
          <SecondaryOrderActions
            order={order}
            onRunAction={runAction}
            hideViewOrder
          />
        </div>
      </div>

      {/* Main Section */}
      <div className="flex flex-wrap gap-8 pt-3">
        {/* TODO: improve layout. Want to truncate filename so it doesn't affect layout */}
        {/* Left Section */}
        <div className="grow">
          {/* Market/Location & Connection  */}
          <div className="flex items-center justify-between pb-3 border-b border-gray-300">
            <div className="grid grid-cols-[repeat(2,auto)] items-center gap-x-4 px-6 text-sm font-medium text-gray-500">
              {/* Delivery Date */}
              <div className="flex items-center gap-1">
                <CalendarIcon className="w-4 h-4 text-gray-600 stroke-2" />
                <span className="font-semibold">Scheduled:</span>
              </div>
              <div>{deliveryString ?? "--"}</div>

              {/* Install date */}
              <div className="flex items-center gap-1">
                <TruckIcon className="w-4 h-4 text-gray-600 stroke-2" />
                <span className="font-semibold">Delivered:</span>
              </div>
              <div>{deliveredString ?? "--"}</div>
            </div>

            <OrderActionButton order={order} onRunAction={runAction} />
          </div>

          {/* Properties */}
          <div className="pt-3 grid grid-cols-[repeat(4,auto)] gap-x-10 col-span-2 text-sm font-medium text-gray-500">
            {/* Left */}
            <div className="grid col-span-2 grid-cols-subgrid">
              <Property
                label="Address"
                className="px-4 mx-4"
                value={
                  <SimpleEdit
                    states={{
                      base: AddressHelper.toString(order.orderAddress)
                        ?.split("\n")
                        .map((line, i) => <div key={i}>{line}</div>),
                      editing: (
                        <div className="grid grid-cols-4 gap-0.5 font-normal">
                          <div className="col-span-full">
                            <Input
                              placeholder="Address Line 1"
                              value={newAddress.line1}
                              onChange={(v) =>
                                setNewAddress((prev) => ({ ...prev, line1: v }))
                              }
                            />
                          </div>
                          <div className="col-span-full">
                            <Input
                              placeholder="Address Line 2"
                              value={newAddress.line2}
                              onChange={(v) =>
                                setNewAddress((prev) => ({
                                  ...prev,
                                  line2: v || undefined,
                                }))
                              }
                            />
                          </div>
                          <div className="col-span-2">
                            <Input
                              placeholder="City"
                              value={newAddress.city}
                              onChange={(v) =>
                                setNewAddress((prev) => ({ ...prev, city: v }))
                              }
                            />
                          </div>
                          <Input
                            placeholder="State"
                            value={newAddress.state}
                            onChange={(v) =>
                              setNewAddress((prev) => ({ ...prev, state: v }))
                            }
                          />
                          <Input
                            placeholder="Postal Code"
                            value={newAddress.postalCode}
                            onChange={(v) =>
                              setNewAddress((prev) => ({
                                ...prev,
                                postalCode: v,
                              }))
                            }
                          />
                        </div>
                      ),
                    }}
                    onSave={saveAddress}
                    onCancel={resetAddress}
                  />
                }
              />
              <Property
                className="px-4 mx-4 border-t border-gray-300"
                label="Market"
                value={
                  <SimpleEdit
                    disabled={companyType !== "installer"}
                    states={{
                      base: market?.name ?? <span className="italic">--</span>,
                      editing: (
                        <Dropdown
                          placeholder="Select Market"
                          options={[
                            markets?.map((p) => ({
                              label: p.name,
                              value: p._id,
                            })) ?? [],
                          ]}
                          selectedValue={newMarket}
                          onSelected={(option) => setNewMarket(option.value)}
                        />
                      ),
                    }}
                    onSave={saveMarket}
                    onCancel={resetMarket}
                  />
                }
              />
              <Property
                label="Contact Info"
                className="px-4 mx-4 border-t border-gray-300"
                value={
                  <SimpleEdit
                    states={{
                      base: (
                        <div>
                          <div>
                            {order.contact?.name || (
                              <span className="italic">No name</span>
                            )}
                          </div>
                          <div>
                            {order.contact?.phone || (
                              <span className="italic">No phone</span>
                            )}
                          </div>
                          <div>
                            {order.contact?.email || (
                              <span className="italic">No email</span>
                            )}
                          </div>
                        </div>
                      ),
                      editing: (
                        <div className="font-normal space-y-0.5">
                          <Input
                            value={newContact.name}
                            placeholder="Name"
                            onChange={(v) =>
                              setNewContact((prev) => ({ ...prev, name: v }))
                            }
                          />
                          <Input
                            type="tel"
                            value={newContact.phone}
                            placeholder="Phone"
                            onChange={(v) =>
                              setNewContact((prev) => ({ ...prev, phone: v }))
                            }
                          />
                          <Input
                            type="email"
                            value={newContact.email}
                            placeholder="Email"
                            onChange={(v) =>
                              setNewContact((prev) => ({ ...prev, email: v }))
                            }
                          />
                        </div>
                      ),
                    }}
                    onSave={saveContact}
                    onCancel={resetContact}
                  />
                }
              />
              <Property
                label="Onsite Lead"
                className="px-4 mx-4 border-t border-gray-300"
                value={
                  <SimpleEdit
                    states={{
                      base: installerUsers ? (
                        installerUsers.find(
                          (u) => u._id === order.installerOnSiteLeadId
                        )?.email ?? <span className="italic">--</span>
                      ) : (
                        <span className="italic animate-pulse">Loading</span>
                      ),
                      editing: (
                        <Dropdown
                          className="font-normal"
                          justifyLeft
                          wide
                          placeholder="Select an Onsite Lead"
                          options={[
                            installerUsers?.map((u) => ({
                              label: u.email,
                              value: u._id,
                            })),
                          ]}
                          onSelected={(selected) => {
                            setNewOnSiteLead(selected.value);
                          }}
                          selectedValue={newOnSiteLead}
                        />
                      ),
                    }}
                    disabled={companyType !== "installer"}
                    onSave={saveOnSiteLead}
                    onCancel={resetOnSiteLead}
                  />
                }
              />
            </div>

            {/* Right */}
            <div className="grid col-span-2 grid-cols-subgrid">
              <Property
                label="Delivery Date"
                className="px-4 mx-4 border border-gray-300 bg-gray-50 rounded-t-md"
                icon={
                  <AgreementBubble
                    status={deliveryDateStatus}
                    icon={AgreementTypes.DELIVERY_DATE}
                  />
                }
                value={deliveryString ?? "--"}
              />
              <Property
                label="Material"
                className="px-4 mx-4 border-gray-300 bg-gray-50 border-x"
                icon={
                  <AgreementBubble
                    status={materialStatus}
                    icon={AgreementTypes.MATERIAL}
                  />
                }
                value={
                  <>
                    <a
                      className={
                        bomLink
                          ? "max-w-full text-primary-green hover:text-primary-green-700 cursor-pointer flex flex-row flex-shrink-0 gap-2"
                          : "text-gray-500"
                      }
                      href={bomLink}
                      target="_blank"
                    >
                      {order.files.billOfMaterials.length > 0 ? "BOM" : "--"}
                    </a>
                  </>
                }
              />
              <Property
                label="Amount"
                className="px-4 mx-4 border border-gray-300 bg-gray-50 rounded-b-md"
                icon={
                  <AgreementBubble
                    status={amountStatus}
                    icon={AgreementTypes.AMOUNT}
                  />
                }
                value={
                  order.quote?.value
                    ? Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(order.quote.value)
                    : "--"
                }
              />
              <Property
                label="Distributor Location"
                className="px-4 pt-6 mx-4 border-gray-300"
                value={
                  <SimpleEdit
                    disabled={companyType !== "distributor"}
                    states={{
                      base: location?.name ?? (
                        <span className="italic">--</span>
                      ),
                      editing: (
                        <Dropdown
                          placeholder="Select Location"
                          options={[
                            locations?.map((p) => ({
                              label: p.name,
                              value: p._id,
                            })) ?? [],
                          ]}
                          selectedValue={newLocation}
                          onSelected={(option) => setNewLocation(option.value)}
                        />
                      ),
                    }}
                    onSave={saveLocation}
                    onCancel={resetLocation}
                  />
                }
                icon={<WarehouseIcon className="w-5 h-5" />}
              />
              <Property
                label="Driver Truck"
                className="px-4 mx-4 border-t border-gray-300"
                value={
                  <SimpleEdit
                    states={{
                      base: trucks ? (
                        trucks.find((t) => t._id === order.truck)?.name ?? (
                          <span className="italic">--</span>
                        )
                      ) : (
                        <span className="italic animate-pulse">Loading</span>
                      ),
                      editing: (
                        <Dropdown
                          placeholder="Select Truck"
                          options={[
                            trucks?.map((p) => ({
                              label: p.name,
                              value: p._id,
                            })) ?? [],
                          ]}
                          selectedValue={newTruck}
                          onSelected={(option) => setNewTruck(option.value)}
                        />
                      ),
                    }}
                    onSave={saveTruck}
                    onCancel={resetTruck}
                    disabled={companyType !== "distributor"}
                  />
                }
                icon={<TruckIcon className="w-5 h-5 stroke-2" />}
              />
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="space-y-8 basis-1/3">
          {/* Shared With Users */}
          <div>
            <CardTitle>Shared With:</CardTitle>
            <div className="flex flex-wrap gap-2">
              {sharedWith?.map((user) => (
                <div
                  className="text-sm font-medium text-gray-700 bg-gray-50 border-gray-200 rounded-md border px-1 py-0.5"
                  key={user._id}
                >
                  {user.email}
                </div>
              ))}
            </div>
          </div>

          {/* TODO: diff name for this section? */}
          <div>
            <CardTitle>Document References</CardTitle>
            <div className="grid grid-cols-2 text-sm font-medium text-gray-500 divide-y divide-gray-300">
              <Property
                label="PO #"
                value={
                  <SimpleEdit
                    states={{
                      base: order.poNumber ?? "--",
                      editing: (
                        <Input
                          className="font-normal"
                          value={newPO}
                          placeholder="PO #"
                          onChange={(v) => setNewPO(v)}
                        />
                      ),
                    }}
                    onSave={savePO}
                    onCancel={resetPO}
                    disabled={companyType !== "installer"}
                  />
                }
              />
              <Property
                label="SO #"
                value={
                  <SimpleEdit
                    states={{
                      base: order.soNumber ?? "--",
                      editing: (
                        <Input
                          className="font-normal"
                          value={newSO}
                          placeholder="SO #"
                          onChange={(v) => setNewSO(v)}
                        />
                      ),
                    }}
                    onSave={saveSO}
                    onCancel={resetSO}
                    disabled={companyType !== "distributor"}
                  />
                }
              />
              <Property label="Loan #" value={"--"} />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

/**
 * Displays required attachments as well as any additional attachments.
 */
function Attachments({ additionalAttachments, setAdditionalAttachments }) {
  const { user, order, bomLink, quoteLink, pickTicketLink, runAction } =
    useContext<OrderDetailsContextType>(OrderDetailsContext);
  const companyType = user?.company?.type;

  const bomFileName = decodeURIComponent(
    bomLink?.split("?")[0]?.split("/")?.pop() || ""
  );
  const quoteFileName = decodeURIComponent(
    quoteLink?.split("?")[0]?.split("/")?.pop() || ""
  );
  const pickTicketFileName = decodeURIComponent(
    pickTicketLink?.split("?")[0]?.split("/")?.pop() || ""
  );

  /**
   * Deletes an additional attachment from the order, removing it from both S3 and the order object in the database.
   */
  async function deleteAdditionalAttachment(file) {
    const filePath = file.getObjectSignedUrl.split("?")[0].split(".com/").pop();

    const response = await UserManager.makeAuthenticatedRequest(
      `/api/orders/delete-attachment`,
      "DELETE",
      {
        orderId: order._id,
        filePath: filePath,
        deleteUrl: file.deleteObjectSignedUrl,
        attachmentType: "additional",
      }
    );

    if (response.data.status === "ok") {
      setAdditionalAttachments((prev) =>
        prev.filter((f) => f.getObjectSignedUrl !== file.getObjectSignedUrl)
      );
    } else {
      console.error("Error deleting attachment", response.data);
    }
  }

  /**
   * When the upload button is clicked, run the upload pick ticket action.
   */
  function handleUploadClick() {
    runAction(OrderAction.UPLOAD_PICK_TICKET);
  }

  return (
    <Card>
      <CardTitle>Attachments</CardTitle>
      <div>
        {/* BOM */}
        {bomLink && (
          <div className="flex items-center justify-start gap-2 py-2">
            <CheckCircleIcon className="w-6 h-6 text-primary-green" />
            <div className="text-sm font-semibold text-gray-500">BOM</div>
            <div className="overflow-hidden text-sm font-normal text-gray-600 text-nowrap overflow-ellipsis max-w-96">
              {bomFileName}
            </div>
            <a
              className="text-sm font-semibold text-primary-green hover:text-primary-green-700"
              href={bomLink}
              target="_blank"
            >
              View
            </a>
          </div>
        )}
        {/* Quote */}
        {quoteLink && (
          <div className="flex items-center justify-start gap-2 py-2">
            <CheckCircleIcon className="w-6 h-6 text-primary-green" />
            <div className="text-sm font-semibold text-gray-500">Quote</div>
            <div className="overflow-hidden text-sm font-normal text-gray-600 text-nowrap overflow-ellipsis">
              {quoteFileName}
            </div>
            <a
              className="text-sm font-semibold text-primary-green hover:text-primary-green-700"
              href={quoteLink}
              target="_blank"
            >
              View
            </a>
          </div>
        )}
        {/* Pick Ticket */}
        {pickTicketLink && (
          <div className="flex items-center justify-start gap-2 py-2">
            <CheckCircleIcon className="w-6 h-6 text-primary-green" />
            <div className="text-sm font-semibold text-gray-500">
              Pick Ticket
            </div>
            <div className="overflow-hidden text-sm font-normal text-gray-600 text-nowrap overflow-ellipsis">
              {pickTicketFileName}
            </div>
            <a
              className="text-sm font-semibold text-primary-green hover:text-primary-green-700"
              href={pickTicketLink}
              target="_blank"
            >
              View
            </a>
            {companyType === "distributor" && (
              <button className="ml-auto mr-6" onClick={handleUploadClick}>
                <UploadIcon className="w-5 h-5 text-gray-600 hover:text-gray-800" />
              </button>
            )}
          </div>
        )}
      </div>
      {additionalAttachments?.length > 0 && (
        <div className="w-1/2">
          <div className="py-2 text-sm font-medium text-gray-500">
            Additional Attachments
          </div>
          <div className="divide-y divide-gray-300">
            {additionalAttachments.map((file, i) => (
              <div className="flex flex-row items-center justify-between">
                <div
                  key={i}
                  className="flex items-center justify-start gap-2 px-2 py-2"
                >
                  <div className="text-sm font-normal text-gray-600">
                    {decodeURIComponent(
                      file.getObjectSignedUrl.split("?")[0].split("/")?.pop() ||
                        ""
                    )}
                  </div>
                  <a
                    className="text-sm font-semibold text-primary-green hover:text-primary-green-700"
                    href={file.getObjectSignedUrl}
                    target="_blank"
                  >
                    View
                  </a>
                </div>
                <div
                  className="px-2"
                  onClick={() => {
                    deleteAdditionalAttachment(file);
                  }}
                >
                  <TrashIcon className="w-5 h-5 text-red-700 cursor-pointer hover:text-red-500" />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Card>
  );
}

/**
 * Modal for viewing images in full screen.
 * Displays a single image.
 *
 * @param image - S3File object representing the image to display
 * @param open - boolean representing whether the modal is open
 * @param setOpen - function to set the open state of the modal
 */
function ViewImageModal({ image, open, setOpen }) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex items-center justify-end w-full">
        <button onClick={() => setOpen(false)} className="pb-4">
          <XMarkIcon className="w-6 h-6 text-gray-500 hover:text-gray-700" />
        </button>
      </div>
      <img src={image?.getObjectSignedUrl} className="w-full" />
    </Modal>
  );
}

/**
 * Displays a single image in the Photos section.
 */
function ImageDisplay({ image, deletable, onImageDelete, onImageClick }) {
  return (
    <div
      className="relative flex items-center justify-center bg-gray-200 rounded-md cursor-pointer h-28"
      onClick={() => onImageClick(image)}
    >
      <img
        src={image.getObjectSignedUrl}
        className="object-cover h-28 aspect-[4/3] rounded-md"
      />
      <div className={classNames(deletable ? "" : "hidden")}>
        <div className="absolute bottom-0 right-0 items-center justify-center bg-gray-100 opacity-75 h-9 w-9 rounded-tl-md rounded-br-md" />
        <button
          onClick={() => onImageDelete(image)}
          className="absolute bottom-1.5 right-1.5 cursor-pointer"
        >
          <TrashIcon className="w-6 h-6 text-red-700 hover:text-red-500" />
        </button>
      </div>
    </div>
  );
}

/**
 * Displays warehouse, delivery, installer verification, and other photos.
 */
function Photos({
  packagingImages,
  deliveryImages,
  installerVerificationImages,
  otherImages,
}) {
  const { user: currentUser, order } =
    useContext<OrderDetailsContextType>(OrderDetailsContext);

  const [viewImageModalOpen, setViewImageModalOpen] = useState(false);
  const [viewingImage, setViewingImage] = useState<S3File | null>(null);

  return (
    <Card>
      <ViewImageModal
        image={viewingImage}
        open={viewImageModalOpen}
        setOpen={setViewImageModalOpen}
      />
      <CardTitle>
        <div className="flex justify-between">
          <p>Photos</p>
          <DownloadOrderImages
            order={order}
            group={null} // All images
          />
        </div>
      </CardTitle>
      <div>
        {/* Packaging Images */}
        {packagingImages?.length > 0 && (
          <div>
            <div className="flex items-center w-full gap-2 py-2 my-3 border-b border-gray-300">
              <div>Packaging Verification Photos</div>
              <DownloadOrderImages
                order={order}
                group={OrderFileType.PACKAGING_IMG}
              />
            </div>
            <div className="flex flex-wrap gap-3">
              {packagingImages.map((img, i) => (
                <ImageDisplay
                  key={i}
                  image={img}
                  deletable={currentUser?.company?.type === "distributor"}
                  onImageDelete={(image) => {
                    // TODO: implement delete functionality
                  }}
                  onImageClick={(image) => {
                    setViewingImage(image);
                    setViewImageModalOpen(true);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        {/* Delivery Images */}
        {deliveryImages?.length > 0 && (
          <div>
            <div className="flex items-center w-full gap-2 py-2 my-3 border-b border-gray-300">
              <div>Delivery Verification Photos</div>
              <DownloadOrderImages
                order={order}
                group={OrderFileType.DELIVERY_IMG}
              />
            </div>
            <div className="flex flex-wrap gap-3">
              {deliveryImages.map((img, i) => (
                <ImageDisplay
                  key={i}
                  image={img}
                  deletable={currentUser?.company?.type === "distributor"}
                  onImageDelete={(image) => {
                    // TODO: implement delete functionality
                  }}
                  onImageClick={(image) => {
                    setViewingImage(image);
                    setViewImageModalOpen(true);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        {/* Installer Verification Images */}
        {installerVerificationImages?.length > 0 && (
          <div>
            <div className="flex items-center w-full gap-2 py-2 my-3 border-b border-gray-300">
              <div>Installer Verification Photos</div>
              <DownloadOrderImages
                order={order}
                group={OrderFileType.INSTALLER_VERIF_IMG}
              />
            </div>
            <div className="flex flex-wrap gap-3">
              {installerVerificationImages.map((img, i) => (
                <ImageDisplay
                  key={i}
                  image={img}
                  deletable={currentUser?.company?.type === "installer"}
                  onImageDelete={(image) => {
                    // TODO: implement delete functionality
                  }}
                  onImageClick={(image) => {
                    setViewingImage(image);
                    setViewImageModalOpen(true);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        {/* Other Images */}
        {/* TODO: need to adjust filepath convention for other photos */}
        {otherImages?.length > 0 && (
          <div>
            <div className="flex items-center w-full gap-2 py-2 my-3 border-b border-gray-300">
              <div>Other Photos</div>
              <DownloadOrderImages
                order={order}
                group={OrderFileType.OTHER_IMG}
              />
            </div>
            <div className="flex flex-wrap gap-3">
              {otherImages.map((img, i) => (
                <ImageDisplay
                  key={i}
                  image={img}
                  deletable={currentUser?.company?.type === "distributor"}
                  onImageDelete={(image) => {
                    // TODO: implement delete functionality
                  }}
                  onImageClick={(image) => {
                    setViewingImage(image);
                    setViewImageModalOpen(true);
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}

/**
 * Displays a timeline of notes for the order.
 */
function Notes({}) {
  const { order, setOrder } =
    useContext<OrderDetailsContextType>(OrderDetailsContext);

  const [showModal, setShowModal] = useState<boolean>(false);

  /**
   * Adds the note to the order's notes array.
   */
  function handleNoteAdd(note) {
    if (!setOrder) return;

    setOrder((prev) => {
      if (prev) {
        return {
          ...prev,
          notes: [note, ...prev.notes],
        };
      }
      return prev;
    });
  }

  return (
    <>
      <Card className="">
        <CardTitle>
          <div className="flex items-center justify-between">
            <p>Notes ({order.notes?.length ?? 0})</p>
            <Button variant="secondary" onClick={() => setShowModal(true)}>
              <div className="flex items-center gap-1 -mx-2">
                <PlusCircleIcon className="w-6 h-6 text-primary-green" />
                <p>Add Note</p>
              </div>
            </Button>
          </div>
        </CardTitle>
        <OrderNotes order={order} />
      </Card>
      {/* TODO: move to useOrderModals */}
      <AddNoteModal
        order={order}
        open={showModal}
        setOpen={setShowModal}
        onNoteAdded={handleNoteAdd}
      />
    </>
  );
}

/**
 * Displays unresolved and resolved issues for the order.
 * TODO: use runAction to open the modals
 */
function Issues({}) {
  const { order, setOrder, reloadOrder, runAction } =
    useContext<OrderDetailsContextType>(OrderDetailsContext);

  const [showResolvedIssues, setShowResolvedIssues] = useState<boolean>(false);

  // Company
  const { user, company, reloadCompany } =
    useContext<UserContextType>(UserContext);

  const [issues, setIssues] = useState(
    order.issues.filter((issue) => issue.visibility.includes(company?._id))
  );

  const [issueTypes, getIssueTypes] = useIssueTypes(order._id, true);

  // Get issues and issue types
  useEffect(() => {
    setIssues(
      // only use issues that company has access to
      order.issues.filter((issue) => issue.visibility.includes(company?._id))
    );
    getIssueTypes();
  }, [order, company]);

  return (
    <>
      <Card>
        <CardTitle>
          <div className="flex items-center justify-between">
            <div className="flex flex-row items-center gap-3">
              <p>Issues</p>
              {issues?.filter((issue) => issue.resolved === false).length >
              0 ? (
                <div className="w-10 h-5 text-sm font-medium text-center text-white align-middle bg-primary-rose rounded-3xl">
                  {issues?.filter((issue) => issue.resolved === false).length ??
                    0}
                </div>
              ) : (
                <div className="w-10 h-5 text-sm font-medium text-center text-gray-400 align-middle text-nowrap">
                  {"(No Issues)"}
                </div>
              )}
            </div>
            {user && user.company?.type !== "account" && (
              <Button
                variant="secondary"
                onClick={() => runAction(OrderAction.ADD_ISSUE)}
              >
                <div className="flex items-center gap-1 -mx-2">
                  <PlusCircleIcon className="w-6 h-6 text-primary-green" />
                  <p>Add Issue</p>
                </div>
              </Button>
            )}
          </div>
        </CardTitle>
        <OrderIssues order={order} onRunAction={runAction} />
      </Card>
    </>
  );
}

/**
 * Displays a timeline of activity for the order.
 */
function Activity({}) {
  const { order } = useContext<OrderDetailsContextType>(OrderDetailsContext);
  return (
    <Card>
      <CardTitle>Activity</CardTitle>
      <OrderTimeline hideOrderLink orders={[order]} />
    </Card>
  );
}

/**
 * Handles styling for card titles throughout the page.
 */
function CardTitle({ children }) {
  return <h2 className="pb-3 text-base font-semibold">{children}</h2>;
}

// TODO: edit functionality for properties? callback prop?
/**
 * Displays a single property in a grid format.
 * Each property takes up 2 columns: 1 for the label, 1 for the value.
 * Handles styling.
 */
function Property({ label, value, className = "", icon = null }) {
  return (
    <div
      className={classNames(
        "grid items-center justify-center col-span-2 py-2 grid-cols-subgrid",
        className
      )}
    >
      <div className="flex flex-row items-center justify-start gap-2">
        {icon && icon}
        {label}
      </div>
      <div className="font-bold">{value}</div>
    </div>
  );
}
