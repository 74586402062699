import React from 'react'
import { useContext } from 'react'
import UserContext from 'src/contexts/user/UserContext.tsx'
import classNames from 'src/tools/classNames'

type Props = {
  size: number
}

/**
 * Company picture component.
 * Currently just company's initials.
 */
export default function CompanyPicture({
  size = 24
}: Props) {
  const { company } = useContext<UserContext>(UserContext)
  let initials = company?.name?.split(" ").map((n) => n.charAt(0)).join("");
  return (
    <div
      className={classNames(
        "rounded-full bg-primary-green text-white flex items-center justify-center",
        "transition-all duration-200",
        "ml-2",
      )}
      style={{
        width: size,
        height: size,
        fontSize: size * 0.4
      }}
    >
      {initials}
    </div>
  )
}
