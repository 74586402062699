import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";
export type Installer = {
  _id: string;
  name: string;
};

/**
 * Returns the visible installers of the user's company.
 * For example, gets connected installers if the user
 * is within a distributor. If the user is within an account,
 * this gets all distributors' installers. Etc.
 *
 * Before installers are fetched, returns null.
 */
export default function useInstallers() {
  const [installers, setInstallers] = useState<Installer[]>();

  // Get installers on load
  useEffect(() => {
    UserManager.makeAuthenticatedRequest("/api/installers/list")
      .then((response) => {
        if (response.data.status === "ok") {
          setInstallers(response.data.installers);
        } else {
          console.error("Failed to get installers", response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return installers;
}
