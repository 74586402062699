import React, { useContext, useState } from 'react'
import Card from 'src/components/Card'
import Switch from 'src/components/input/Switch.tsx'
import UserContext from 'src/contexts/user/UserContext.tsx';
import classNames from 'src/tools/classNames';
import UserManager from 'src/tools/UserManager';

type Props = {}

/**
 * Page for user to define their notification settings.
 */
export default function UserNotificationsPage({ }: Props) {

  const { user, reloadUser } = useContext<UserContext>(UserContext);

  // TODO: loading state?
  if (!user.settings) { return; }

  let notifs = user.company.type === "installer" ? {
    // --- INSTALLER --- //
    email: [
      { label: "New Quote", info: { category: "order", notification: "newQuote" } },
      { label: "Reschedule", info: { category: "order", notification: "reschedule" } },
      { label: "Agreement Changes", info: { category: "order", notification: "agreementChange" } },
      { label: "New Issues", info: { category: "order", notification: "newIssue" } },
      { label: "Deliveries", info: { category: "order", notification: "delivery" } },
    ]
  } : {
    // --- DISTRIBUTOR --- //
    email: [
      { label: "New Order", info: { category: "order", notification: "newOrder" } },
      { label: "Reschedule", info: { category: "order", notification: "reschedule" } },
      { label: "Agreement Changes", info: { category: "order", notification: "agreementChange" } },
      { label: "New Issues", info: { category: "order", notification: "newIssue" } },
    ]
  }

  let emailNotifs = user.settings.notifications.all.email;

  /**
   * Update the overall method setting
   * E.g. if any emails at all should be sent
   */
  async function updateMethod(method: string, value: boolean) {
    let body = {
      category: "all",
      value: { [method]: value }
    }
    try {
      var res = await UserManager.makeAuthenticatedRequest("/api/user-settings/notification", "POST", body);
      reloadUser();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <Card>
        <div className="text-lg font-semibold flex justify-between mb-5">
          <p>Email Notifications</p>
          <Switch
            checked={emailNotifs}
            setChecked={(v) => updateMethod("email", v)}
          />
        </div>
        <div className="divide-y">
          {
            notifs.email.map((notif) => {

              return <NotifToggle
                key={notif.label}
                label={notif.label}
                info={notif.info}
                method="email"
                disabled={!emailNotifs}
              />
            }
            )
          }
        </div>
      </Card>
    </div>
  )
}

/**
 * Switch component handling updating backend with settings and displaying
 * switch checked or not based on user settings value.
 * `info` is an object with category and notification representing which notif
 * setting. E.g. {category:"order",notification:"newOrder"}.
 */
function NotifToggle({ label, info, method, disabled = false }) {
  const { user, reloadUser } = useContext<UserContext>(UserContext);
  let { category, notification } = info;
  let checked = user.settings.notifications[category][notification][method];

  /**
    * Send request to update user settings
    */
  async function handleToggle(value: boolean) {
    let body = {
      category,
      notification,
      value: { [method]: value }
    }
    try {
      var res = await UserManager.makeAuthenticatedRequest("/api/user-settings/notification", "POST", body);
      reloadUser();
    } catch (error) {
      console.error(error);
    }
  }

  return <div
    data-disabled={disabled}
    className={classNames(
      "flex justify-between p-2 pr-0",
      "transition-all data-[disabled=true]:text-gray-500 data-[disabled=true]:bg-gray-100 dark:data-[disabled=true]:bg-gray-800"
    )}
  >
    <p>{label}</p>
    <Switch
      checked={checked}
      disabled={disabled}
      setChecked={handleToggle}
    />
  </div>
}
