import React, { useEffect, useState } from 'react'
import Modal, { ModalFooter } from 'src/components/Modal';
import { Order, OrderStatusLabels } from 'src/hooks/data/orders/useOrders.ts';
import OrderModalHeader from '../common/OrderModalHeader.tsx';
import Button from 'src/components/input/Button.js';
import { addressToString, getDeliveryDateString, MinorBadge } from '../OrderRowItem.tsx';
import classNames from 'src/tools/classNames.js';
import OrderNotes from '../common/OrderNotes.tsx';
import Input from 'src/components/input/Input';
import { HomeIcon, TruckIcon } from '@heroicons/react/24/outline';
import AddressHelper from 'src/utils/addressHelper.ts';
import { dateToString } from '../common/DeliveryDateInput.tsx';
import moment from 'moment';
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts';
import useConnections from 'src/hooks/data/connections/useConnections.ts';
import useMarkets from 'src/hooks/data/markets/useMarkets.ts';
import useLocations from 'src/hooks/data/locations/useLocations.ts';
import SwitchCase from 'src/components/utils/SwitchCase.tsx';
import UserManager from 'src/tools/UserManager';
import Spinner from 'src/components/Spinner';
import { Note } from 'src/hooks/data/orders/useOrders';
import StatusDisplay from '../common/StatusDisplay.tsx';
import Dropdown from 'src/components/input/Dropdown.js';
import InfoTooltip from 'src/components/InfoTooltip.js';
import CustomizableDropdown from 'src/components/input/CustomizableDropdown.tsx';
import { IssueSeverity, severityMap } from 'src/hooks/data/orders/useOrders.ts';

type Props = {
    order: Order;
    open: boolean;
    setOpen: (open: boolean) => void;
}

/**
 * Order action modal with a simple input for adding a note to the order.
 */
export default function AddIssueModal({
    open, setOpen, order
}: Props) {

    // -------------- //
    // --- States --- //
    // -------------- //

    const [loading, setLoading] = useState<boolean>(false);
    const [issueType, setIssueType] = useState<string>("");
    const [visibility, setVisibility] = useState<string>("external");
    const [severity, setSeverity] = useState<string>("");
    const [blocking, setBlocking] = useState<boolean>(false);
    const [description, setDescription] = useState<string>("");

    // ------------- //
    // --- Hooks --- //
    // ------------- //

    const user = useCurrentUser();
    const companyType = user?.company?.type;

    const connections = useConnections(); // TODO: only get specific one? pass from parent?
    let connectionId = null;
    switch (companyType) {
        case "installer":
            connectionId = order.distributorId;
            break;
        case "distributor":
            connectionId = order.installerId;
            break;
    }
    const connectionName = connections
        ?.find(c => c.id === connectionId)
        ?.name;

    const markets = useMarkets()
    const marketName = markets?.find(m => m._id === order.marketId)?.name;

    const locations = useLocations();
    const locationName = locations?.find(l => l._id === order.locationId)?.name;

    const canSubmit = issueType && description && visibility && severity;

    const severityOptions = [[
        { 
            label: severityMap[IssueSeverity.CRITICAL],
            value: IssueSeverity.CRITICAL
        },
        { 
            label: severityMap[IssueSeverity.MAJOR],
            value: IssueSeverity.MAJOR
        },
        { 
            label: severityMap[IssueSeverity.MINOR],
            value: IssueSeverity.MINOR
        },
    ]]

    // ------------------- //
    // --- Use Effects --- //
    // ------------------- //

    // Reset note when modal is closed
    useEffect(() => {
        if (!open) {
            setDescription("");
        }
    }, [open])

    // ----------------- //
    // --- Functions --- //
    // ----------------- //

    /**
     * Submits the issue to the server.
     */
    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (!description) return; // Required field

        setLoading(true);

        let body = {
            orderId: order._id,
            issueType: issueType,
            visibility: visibility === "external" ? [order.distributorId, order.installerId] : [user?.company?.id],
            severity: severity,
            blocking: blocking,
            description: description,
        }

        try {
            var res = await UserManager.makeAuthenticatedRequest("/api/orders/add-issue", "POST", body);
        } catch (error) {
            console.error(error);
        }

        // Callback if issue was added
        if (res.data.status === "ok") {

        }

        setLoading(false)
        close()
    }

    /**
      * Close the modal
      */
    function close() {
        setOpen(false);
        setDescription("");
        setIssueType("");
        setVisibility("external");
        setSeverity("");
        setBlocking(false);
        setLoading(false);
    }

    /**
     * Update the note
     */
    function handleNoteChange(value: string) {
        setDescription(value)
    }

    /**
     * Gets the issue type options from the company settings.
     * TODO: move issue type options into a new component
     */
    async function getIssueTypeOptions() {
        const res = await UserManager.makeAuthenticatedRequest("/api/company-settings/issue-types", "GET")
        return res?.data?.issueTypes || []
    }

    /**
     * Creates a new issue type option in the company settings.
     */
    function createIssueTypeOption(issueTypeOption) {
        return UserManager.makeAuthenticatedRequest("/api/company-settings/issue-types", "POST", issueTypeOption)
    }

    /**
     * Archives an issue type option in the company settings.
     */
    async function archiveIssueTypeOption(issueTypeOption) {
        return await UserManager.makeAuthenticatedRequest("/api/company-settings/issue-types", "DELETE", { value: issueTypeOption })
    }

    // -------------- //
    // --- Consts --- //
    // -------------- //

    const deliveryString = getDeliveryDateString(order)
    const installString = order.installationDate ? moment(order.installationDate).utc().format('ddd M/D') : null

    const secondaryDropdownOptions = [
        { label: "Edit", value: "edit", onSelected: (value) => console.log("Edit", value) },
        { label: "Archive", value: "archive", onSelected: archiveIssueTypeOption },
    ]

    // -------------- //
    // --- Render --- //
    // -------------- //

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            wide
        >
            <form
                onSubmit={handleSubmit}
                className=""
            >
                <OrderModalHeader
                    order={order}
                    title="New Issue"
                    subtitle="Describe Order Issue"
                />
                <div className="divide-y w-[550px]">
                    {/* Input & Notes */}
                    <Section>
                        <div
                            className='flex flex-col gap-4 pb-3 pr-1'
                        >
                            <div
                                className='flex flex-row items-start justify-between gap-2'
                            >
                                <div
                                    className='pt-2 text-sm font-medium'
                                >
                                    Issue Type
                                </div>

                                <div

                                >
                                    <CustomizableDropdown
                                        onChange={(dropdownOption) => {
                                            setIssueType(dropdownOption.value)
                                        }}
                                        wide
                                        className='w-[420px]'
                                        fetchDropdownOptionsRequest={getIssueTypeOptions}
                                        createNewDropdownOptionRequest={createIssueTypeOption}
                                        secondaryDropdownOptions={secondaryDropdownOptions}
                                        dropdownType={"Issue Type"}
                                    />
                                </div>
                            </div>
                            <div
                                className='flex flex-row items-start justify-between gap-2'
                            >
                                <div
                                    className='pt-2 text-sm font-medium'
                                >
                                    Description
                                </div>
                                <div className="w-[420px]">
                                    <Input
                                        className='h-16 resize-none'
                                        type="textarea"
                                        placeholder="Add a description..."
                                        value={description}
                                        onChange={handleNoteChange}
                                    />
                                </div>
                            </div>
                            <div
                                className='flex flex-row items-start justify-between gap-2'
                            >
                                <div
                                    className='pt-2 text-sm font-medium'
                                >
                                    Visibility
                                </div>
                                <Dropdown
                                    className='w-[420px]'
                                    wide
                                    options={[[
                                        { label: "Visible to Both Teams", value: "external" },
                                        { label: "Internal Team Only", value: "internal" },
                                    ]]}
                                    onSelected={(selection) => setVisibility(selection.value)}
                                    selectedValue={visibility}
                                />
                            </div>
                            <div
                                className='flex flex-row items-start justify-between gap-2'
                            >
                                <div
                                    className='pt-2 text-sm font-medium'
                                >
                                    Severity
                                </div>
                                <div
                                    className='w-[420px] flex flex-row items-center justify-between gap-2'
                                >
                                    <Dropdown
                                        className='w-[250px]'
                                        wide
                                        options={severityOptions}
                                        onSelected={(selection) => setSeverity(selection.value)}
                                        selectedValue={severity}
                                    />
                                    <div
                                        className='flex flex-row items-center gap-4'
                                    >
                                        <div
                                            className='flex flex-row items-center gap-2'
                                        >
                                            <div
                                                className='text-sm font-medium'
                                            >
                                                Blocking
                                            </div>
                                            <InfoTooltip
                                                justify='right'
                                                verticalAlign='top'
                                                text="Blocking issues prevent the order from progressing to the next stage."
                                            />
                                        </div>
                                        <input
                                            type="checkbox"
                                            className="w-4 h-4 border-gray-300 rounded text-primary-green focus:ring-primary-green"
                                            checked={blocking}
                                            onChange={() => setBlocking(!blocking)}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Section>
                </div>
                {/* Footer */}
                <ModalFooter>
                    <div className="flex items-center justify-end gap-2">
                        <Button
                            variant="secondary"
                            onClick={close}
                        >
                            Cancel
                        </Button>
                        {
                            !loading ? <Button
                                variant="primary"
                                type="submit"
                                disabled={!canSubmit}
                            >
                                Submit Issue
                            </Button>
                                : <div className="flex items-center pr-7">
                                    <Spinner size={20} />
                                </div>
                        }
                    </div>
                </ModalFooter>
            </form>
        </Modal>
    )
}

type SectionProps = {
    children?: React.ReactNode
    noTopPadding?: boolean
}

/**
  * Styles a section of the modal
  */
function Section({ children, noTopPadding = false }: SectionProps) {
    return <div
        className={classNames(
            noTopPadding ? "pb-4" : "py-4",
        )}
    >
        {children}
    </div>
}

/**
  * Super simple loading component.
  * Pulses "Loading"
  * TODO: move to a shared with OrderModalHeader
  */
function Loading({ }) {
    return <span className="animate-pulse">Loading</span>
}
