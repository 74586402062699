import React, { useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react'
import TopBar from "../components/nav/TopBar";
import MultiSelectListbox from "../components/input/MultiSelectListbox.tsx";
import Row from "../components/SalesForecast/Row.tsx";
import Button from "../components/input/Button";
import Modal from "../components/Modal";
import SubmitSalesForecastModal from 'src/components/SalesForecast/SubmitSalesForecastModal.tsx';
import UserManager from 'src/tools/UserManager';
import { get } from 'jquery';
import useJobAvailabilityBreakdown, { AvailabilityBreakdown, arrayToWeekMap, weekMapToArray } from 'src/hooks/data/forecast/useJobAvailabilityBreakdown.ts';
import useMultiMarketSelect from 'src/hooks/data/markets/useMultiMarketSelect.ts';
import { MarketType } from 'src/contexts/forecast/history/ForecastHistoryContext.tsx';
import moment from 'moment';
import debounce from 'src/tools/debounce.js';
import { BetaBadge } from 'src/components/Badge.tsx';

const exampleFutureData = [
  {
    week: "6/30",
    forecast: 10,
    readyToInstall: 0,
  },
  {
    week: "7/7",
    forecast: 20,
    readyToInstall: 10,
  },
  {
    week: "7/14",
    forecast: 10,
    readyToInstall: 5,
  },
  {
    week: "7/21",
    forecast: 20,
    readyToInstall: 10,
  },
  {
    week: "7/28",
    forecast: 10,
    readyToInstall: 5,
  },
  {
    week: "8/4",
    forecast: 20,
    readyToInstall: 10,
  },
  {
    week: "8/11",
    forecast: 10,
    readyToInstall: 5,
  },
  {
    week: "8/18",
    forecast: 20,
    readyToInstall: 10,
  },
  {
    week: "8/25",
    forecast: 10,
    readyToInstall: 5,
  },
  {
    week: "9/1",
    forecast: 20,
    readyToInstall: 10,
  },
  {
    week: "9/8",
    forecast: 10,
    readyToInstall: 5,
  },
  {
    week: "9/15",
    forecast: 20,
    readyToInstall: 10,
  },
  {
    week: "9/22",
    forecast: 10,
    readyToInstall: 5,
  },
  {
    week: "9/29",
    forecast: 20,
    readyToInstall: 10,
  },
  {
    week: "10/6",
    forecast: 10,
    readyToInstall: 5,
  },
  {
    week: "10/13",
    forecast: 20,
    readyToInstall: 10,
  }
];

const tempForecastData = [
  {
    forecast: 0,
  },
  {
    forecast: 0,
  },
  {
    forecast: 0,
  },
  {
    forecast: 0,
  },
  {
    forecast: 0,
  },
  {
    forecast: 0,
  }
]

const exampleHistoricalData = [
  { actual: 0, forecast: 10, week: "5/19" },
  { actual: 10, forecast: 20, week: "5/26" },
  { actual: 5, forecast: 10, week: "6/2" },
  { actual: 10, forecast: 20, week: "6/9" },
  { actual: 5, forecast: 10, week: "6/16" },
  { actual: 10, forecast: 20, week: "6/23" }
]

const exampleMultiMarketData = {
  "total": {
    "numberOfMarkets": 3,
    "future": exampleFutureData,
    "historical": exampleHistoricalData
  },
  "market1": {
    "marketName": "OH - Columbus",
    "future": exampleFutureData,
    "historical": exampleHistoricalData
  },
  "market2": {
    "marketName": "OH - Cleveland",
    "future": exampleFutureData,
    "historical": exampleHistoricalData
  },
  "market3": {
    "marketName": "OH - Cincinnati really long market name",
    "future": exampleFutureData,
    "historical": exampleHistoricalData
  },
}

/**
 * This component renders the sales forecast page.
 * This page allows for the user to input a sales forecast
 * by week and by market. It also has a total row and 
 * some other stats like historical values.
 */
export default function SalesForecastPage() {

  const [salesForecastData, setSalesForecastData] = useState({});
  const [last6WeeksSales, setLast6WeeksSales] = useState({});
  const [last6WeeksForecast, setLast6WeeksForecast] = useState({})
  const [last6WeeksTotalSales, setLast6WeeksTotalSales] = useState([]);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [totalFutureData, setTotalFutureData] = useState([]);

  // NOTE: note deconstructing (i.e. [m, s, setS]) to avoid refactoring to handle null instead of []
  const marketHook = useMultiMarketSelect(true);
  const markets = marketHook[0] ?? [];
  const selectedMarkets = marketHook[1];
  const setSelectedMarkets = marketHook[2];

  const selectedMarketIds = selectedMarkets?.map((market) => market._id) || [];
  const setSelectedMarketIds = (values: string[]) => {
    setSelectedMarkets(markets?.filter((market) => values.includes(market._id)) || []);
  }

  const jobAvailabilityBreakdown: AvailabilityBreakdown = useJobAvailabilityBreakdown(selectedMarketIds);

  // Get historical sales and current sales forecast for selected markets
  // Done when selected markets changes
  useEffect(() => {
    if (selectedMarketIds.length > 0) {
      getHistoricalSales(selectedMarketIds);
      getCurrentSalesForecast();
    }
  }, [selectedMarkets]); // Depend on markets not IDs as markets is the state and IDs follow

  // when salesForecastData is updated, calculate total forecast and readyToInstall for all markets
  useEffect(() => {
    sumTotalFutureData();
  }, [salesForecastData, jobAvailabilityBreakdown?.availableJobs, jobAvailabilityBreakdown?.installsByWeek]);

  // when last6WeeksSales is updated, for each market, calculate total actual and total forecast, grouped by week
  useEffect(() => {
    var totalHistorical = [];
    // for each market
    for (var marketId in last6WeeksSales) {
      var marketData = last6WeeksSales[marketId];
      marketData.forEach((weekData) => {
        // if totalHistorical doesnt have the week, add a week with the correct week string and 0 actual and forecast
        // then for all weeks, add the actual and forecast to the correct week
        if (!totalHistorical.some((week) => week.week === weekData.week)) {
          totalHistorical.push({ week: weekData.week, actual: 0, forecast: 0 });
        }
        totalHistorical.find((week) => week.week === weekData.week).actual += weekData.actual;
        totalHistorical.find((week) => week.week === weekData.week).forecast += weekData.forecast;


      });

      // Historical planned
      let plannedJobs_hist = jobAvailabilityBreakdown?.historicalForecastInstalls?.[marketId];
      if (plannedJobs_hist) {
        for (let i = 0; i < totalHistorical.length; i++) {
          if (!totalHistorical[i].historicalInstallForecast) totalHistorical[i].historicalInstallForecast = 0;
          totalHistorical[i].historicalInstallForecast += plannedJobs_hist[i] || 0;
        }
      }
    }

    // For each market, add historical forecast
    for (let marketId of selectedMarketIds) {
      let forecast = last6WeeksForecast[marketId] ?? [];
      if (forecast.length > 6) forecast = forecast.slice(-6); // [1,2,3,4,5,6,7] -> [2,3,4,5,6,7]
      if (forecast.length < 6) forecast = [...Array(6 - forecast.length).fill(0), ...forecast]; // [1,2,3] -> [0,0,0,1,2,3]

      for (let i = 0; i < forecast.length; i++) {
        if (!totalHistorical[i]) continue;
        totalHistorical[i].forecast += forecast[i];
      }
    }

    setLast6WeeksTotalSales(totalHistorical);
  }, [last6WeeksSales, jobAvailabilityBreakdown?.historicalForecastInstalls, last6WeeksForecast]);


  /**
   * Sums the forecasts for display in the total row
   */
  function sumTotalFutureData() {
    var totalFuture = [];
    selectedMarketIds.forEach((marketId) => {
      salesForecastData[marketId].forEach((weekData, i) => {
        if (totalFuture.length <= i) {
          totalFuture.push({ week: weekData.week, forecast: 0, readyToInstall: 0 });
        }
        totalFuture[i].forecast += weekData.forecast;
        totalFuture[i].readyToInstall += weekData.installsAvailable;
      });


      // Sum planned jobs and installs available
      // TODO: global num weeks?

      // Future planned
      let plannedWeekMap = jobAvailabilityBreakdown?.installsByWeek?.[marketId];
      let plannedJobs = weekMapToArray(plannedWeekMap, 16, moment().utc().startOf("week").toDate());
      for (let i = 0; i < totalFuture.length; i++) {
        if (!totalFuture[i].installForecast) totalFuture[i].installForecast = 0;
        totalFuture[i].installForecast += plannedJobs[i] || 0;
      }

      let availableJobs = jobAvailabilityBreakdown?.availableJobs?.[marketId];
      if (availableJobs) {
        for (let i = 0; i < totalFuture.length; i++) {
          if (!totalFuture[i].available) totalFuture[i].available = { includingSales: 0, excludingSales: 0 };
          totalFuture[i].available.includingSales += availableJobs[i]?.includingSales || 0;
          totalFuture[i].available.excludingSales += availableJobs[i]?.excludingSales || 0;
        }
      }
    });

    setTotalFutureData(totalFuture);
  }


  /**
   * Fetches historical sales data for the given markets
   */
  function getHistoricalSales(marketIds) {
    UserManager.makeAuthenticatedRequest(
      "/api/sales/last-6-weeks?marketIds=" + marketIds.join(","),
      "GET"
    ).then((res) => {
      if (res.data.status === "ok") {
        setLast6WeeksSales(res.data.last6WeeksSales);
      }
      else {
        console.error("Error fetching sales");
      }
    })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * Fetches the current sales forecast for the selected markets
   */
  function getCurrentSalesForecast() {
    UserManager.makeAuthenticatedRequest(
      "/api/sales/forecast/get?marketIds=" + selectedMarketIds.join(","),
      "GET",
    ).then((res) => {
      if (res.data.status === "ok") {
        // for each selectedMarketId, create an array of {week, forecast, installsAvailable} from res.data.currentForecasts
        // if the market is not in the response, use zeros for the forecast and installsAvailable
        var newSalesForecastData = {};
        var newLast6WeeksForecast = {};
        selectedMarketIds.forEach((marketId) => {
          var marketForecastData = res.data.currentForecasts[marketId] || [];
          // from marketForecastData, merge inputSalesforecast, weeks, and installsAvailable into one object for each week
          var inputSalesForecast = marketForecastData.inputSalesForecast || [];
          var weeks = marketForecastData.weeks || [];
          var installsAvailable = marketForecastData.installsAvailable || [];
          newSalesForecastData[marketId] = weeks.map((week, i) => {
            return {
              week: week,
              forecast: inputSalesForecast[i] || 0,
              installsAvailable: installsAvailable[i] || 0,
            }
          });

          newLast6WeeksForecast[marketId] = marketForecastData.last6WeeksForecast || [];
        });


        setLast6WeeksForecast(newLast6WeeksForecast);
        setSalesForecastData(newSalesForecastData);
      }
      else {
        console.error("Error fetching sales forecast");
      }
    })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * Updates the current sales forecast for the given market and week.
   * Sets state on server response.
   */
  function saveCurrentSalesForecast(marketId, colIndex, value, salesForecastData) {

    // make a copy of the salesForecastData
    var newForecast = Object.assign({}, salesForecastData);

    // make a copy of the week object
    var newWeek = Object.assign({}, newForecast[marketId][colIndex]);

    // update the forecast value
    newWeek.forecast = value;

    // update the week object in the newForecast object
    newForecast[marketId][colIndex] = newWeek;

    UserManager.makeAuthenticatedRequest(
      "/api/sales/forecast/save",
      "POST",
      {
        marketId: marketId,
        // pull out the forecast values from the newForecast object into an array
        newForecast: newForecast[marketId].map((week) => week.forecast),
      }
    ).then((res) => {
      if (res.data.status === "ok") {
        setSalesForecastData(newForecast);
      }
      else {
        console.error("Error saving sales forecast");
      }
    })
      .catch((err) => {
        console.error(err);
      });
  }
  const debouncedSaveCurrentSalesForecast = useCallback(debounce(saveCurrentSalesForecast, 500), [])

  /**
   * Opens the submit modal button.
   */
  function handleSubmitButtonClicked() {
    setSubmitModalOpen(true);
  }

  const Skeleton = ({ width = "100%", height = "100%" }: { width?: number | string, height?: number | string }) => <div className="bg-gray-300 rounded-lg animate-pulse" style={{ height, width }} />

  return (
    <>
      {/* Submit Sales Forecast Modal */}
      <SubmitSalesForecastModal
        open={submitModalOpen}
        setOpen={setSubmitModalOpen}
        marketIds={selectedMarketIds}
      />
      <div className="flex flex-col max-h-screen dark:text-white grow">
        {/* Top Bar */}
        <TopBar>
          <div className="flex items-center gap-3">
            Sales Forecast
            <BetaBadge />
          </div>
        </TopBar>

        {/* Actions */}
        <div className="flex flex-row flex-wrap items-center justify-between gap-4 p-2 mx-2 my-3">
          <div>
            <MultiSelectListbox
              itemType="Market"
              options={
                markets.map((market) => {
                  return {
                    label: market.name,
                    value: market._id,
                  }
                })
              }
              selectedOptionsValues={selectedMarketIds}
              onChange={(values) => {
                setSelectedMarketIds(values)
              }}
            />
          </div>
          <Button
            onClick={() => {
              handleSubmitButtonClicked();
            }}
            variant="primary"
          >
            Submit All Markets
          </Button>
        </div>

        {/* Body */}
        <div className="flex flex-col flex-1 space-y-2 overflow-auto">

          <div className="3xl:justify-center 3xl:flex 3xl:flex-col 3xl:items-center">
            <div className=''>
              {last6WeeksTotalSales.length > 0
                ? <Row
                  marketId="total"
                  futureData={totalFutureData}
                  historicalData={last6WeeksTotalSales}
                  installsAvailable={totalFutureData?.map(o => o.available)}
                  plannedJobs={{
                    forecast: arrayToWeekMap(totalFutureData?.map(o => o.installForecast), moment().utc().startOf("week").toDate()),
                    historical: last6WeeksTotalSales?.map(o => o.historicalInstallForecast)
                  }}
                />
                : <Skeleton height={350} width={1600} />
              }
            </div>
            <div className='w-[1592px] px-4 my-6 text-xl font-semibold leading-7 text-left align-middle'>
              Sales Forecast by Market
            </div>
            {/* make a row for each market */}
            {selectedMarketIds.map((marketId, i) => {
              // only render the row if we have already received the historical sales and the sales forecast data
              if (
                last6WeeksSales[marketId]
                && last6WeeksSales[marketId].length > 0
                && salesForecastData[marketId]
                && salesForecastData[marketId].length > 0
              ) {
                return (
                  <Row
                    key={i}
                    marketId={marketId}
                    marketName={markets.find((market) => market._id === marketId).name}
                    futureData={salesForecastData[marketId]}
                    historicalData={last6WeeksSales[marketId]?.map(
                      (weekData, i) => {
                        let last6WeeksForecastData = last6WeeksForecast[marketId];
                        let last6WeeksValue = last6WeeksForecastData?.[i] ?? 0;
                        return { ...weekData, forecast: last6WeeksValue }
                      }
                    )}
                    onSalesForecastChange={(marketId, colIndex, value) => {
                      // --- Optimistically Update UI --- //
                      // make a copy of the salesForecastData
                      var newForecast = Object.assign({}, salesForecastData);

                      // make a copy of the week object
                      var newWeek = Object.assign({}, newForecast[marketId][colIndex]);

                      // update the forecast value
                      newWeek.forecast = value;

                      // update the week object in the newForecast object
                      newForecast[marketId][colIndex] = newWeek;

                      // --- Save --- //
                      debouncedSaveCurrentSalesForecast(marketId, colIndex, value, newForecast);
                      sumTotalFutureData();
                    }}
                    installsAvailable={jobAvailabilityBreakdown?.availableJobs?.[marketId]}
                    plannedJobs={{
                      forecast: jobAvailabilityBreakdown?.installsByWeek?.[marketId],
                      historical: jobAvailabilityBreakdown?.historicalForecastInstalls?.[marketId]
                    }}
                  />
                )
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>
    </>
  )
}
