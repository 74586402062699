import React from "react";
import IconDropdown from "src/components/input/IconDropdown";
import {
  AgreementStatus,
  Order,
  OrderStatus,
} from "src/hooks/data/orders/useOrders.ts";
import useCurrentUser from "src/hooks/data/users/useCurrentUser.ts";
import { OrderAction } from "./OrderActionButton.tsx";

type Props = {
  order: Order;
  onRunAction: (action: OrderAction, opts?: any) => void;
  hideViewOrder?: boolean;
};

/**
 * Displays a dropdown of secondary actions for an order.
 * These include:
 * - View Order
 * - Add Note
 * - Add Issue
 * - Reschedule
 * - Approve for Installer (if able to as distributor)
 * - Change Status (if admin)
 * - Cancel Order
 *
 * Props:
 * - order: Order - The order to display issues for
 * - onRunAction: (action: OrderAction, opts?: any) => void - Function to run an order action
 * - hideViewOrder?: boolean - Whether to hide the "View Order" option
 */
export default function SecondaryOrderActions({
  order,
  onRunAction,
  hideViewOrder = false,
}: Props) {
  const user = useCurrentUser();

  // Check if distributor can review for installer
  const waitingOnInstaller =
    order.quote?.agreements.deliveryDate.installer ===
      AgreementStatus.PENDING ||
    order.quote?.agreements.material.installer === AgreementStatus.PENDING ||
    order.quote?.agreements.amount?.installer === AgreementStatus.PENDING;
  const canReviewForInstaller =
    user?.company?.type === "distributor" && // Is distributor
    (order.status === OrderStatus.WAITING_FOR_QUOTE_APPROVAL || // Waiting for initial installer review
      (waitingOnInstaller &&
        order.status === OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT)); // Waiting for installer review after distributor review

  // Define row actions based on user company type
  const rowActions =
    {
      // --- INSTALLER ACTIONS --- //
      installer: [
        ...(!hideViewOrder
          ? [
              {
                label: "View Order",
                href: `../details/${order._id}`,
              },
            ]
          : []),
        {
          label: "Add Note",
          value: OrderAction.ADD_NOTE,
        },
        {
          label: "Add Issue",
          value: OrderAction.ADD_ISSUE,
        },
        {
          label: "Reschedule",
          value: OrderAction.RESCHEDULE_ORDER,
        },
        // If admin, allow manual status change
        ...(user?.company?.role === "admin" &&
        order.status !== OrderStatus.WAITING_FOR_QUOTE
          ? [
              {
                label: "Change Status",
                value: OrderAction.CHANGE_STATUS,
              },
            ]
          : []),
        {
          label: "Cancel Order",
          value: OrderAction.CANCEL_ORDER,
        },
      ],

      // --- DISTRIBUTOR ACTIONS --- //
      distributor: [
        ...(!hideViewOrder
          ? [
              {
                label: "View Order",
                href: `../details/${order._id}`,
              },
            ]
          : []),
        {
          label: "Add Note",
          value: OrderAction.ADD_NOTE,
        },
        {
          label: "Add Issue",
          value: OrderAction.ADD_ISSUE,
        },
        {
          label: "Reschedule",
          value: OrderAction.RESCHEDULE_ORDER,
        },
        // If distributor and order needs review,
        // allow to review on installer's behalf
        ...(canReviewForInstaller
          ? [
              {
                label: "Review for Installer",
                value: OrderAction.APPROVE_ORDER_FOR_INSTALLER,
              },
            ]
          : []),
        // If admin, allow manual status change
        ...(user?.company?.role === "admin" &&
        order.status !== OrderStatus.WAITING_FOR_QUOTE
          ? [
              {
                label: "Change Status",
                value: OrderAction.CHANGE_STATUS,
              },
            ]
          : []),
        ...([OrderStatus.READY_TO_DELIVER, OrderStatus.IN_TRANSIT].includes(
          order.status,
        )
          ? [
              {
                label: "Mark as Delivered",
                value: OrderAction.MARK_AS_DELIVERED,
              },
            ]
          : []),
        {
          label: "Cancel Order",
          value: OrderAction.CANCEL_ORDER,
        },
      ],

      // --- ACCOUNT ACTIONS --- //
      account: [
        ...(!hideViewOrder
          ? [
              {
                label: "View Order",
                href: `../details/${order._id}`,
              },
            ]
          : []),
        {
          label: "Add Note",
          value: OrderAction.ADD_NOTE,
        },
      ],
    }[user?.company?.type] || []; // Default to empty array if no type

  return (
    <IconDropdown
      options={rowActions}
      onSelected={async (s: { label: string; value: any }) => {
        onRunAction(s.value as OrderAction);
      }}
      horizontalAlign="end"
    />
  );
}
