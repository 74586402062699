import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SubNav from "src/components/nav/SubNav";
import Button from "../components/input/Button";
import Input from "../components/input/Input";
import TopBar from "../components/nav/TopBar";
import UserManager from "../tools/UserManager";

/**
 * Parent page for user settings
 * Has a subnav for:
 * - Profile
 * - Notifications
 * - Appearance
 */
export default function UserPage() {

  const [subNavTabs, setSubNavTabs] = useState([
    { name: "Profile", href: "profile", current: true },
    { name: "Notifications", href: "notifications", current: false },
    { name: "Appearance", href: "appearance", current: false },
  ]);

  const location = useLocation();

  // Set the current tab based on the URL
  useEffect(() => {
    // Get the current tab from the URL
    const currentTab = location.pathname.split("/")[3];

    // Get name by href
    const currentTabName = subNavTabs.find((tab) => tab.href == currentTab)?.name ?? subNavTabs[0].name;

    // Set tab
    selectTab(currentTabName);
  }, [location]);

  /**
   * Selects a tab by name. This adjusts subNavTabs.
   * @param {string} tabName Name of tab to select
   */
  function selectTab(tabName) {
    var temp = [...subNavTabs];
    temp.forEach((tab) => {
      tab.current = tab.name == tabName ? true : false;
    });
    setSubNavTabs(temp);
  }

  return (
    <div className="flex flex-col gap-2 dark:text-white">
      {/* Top Bar */}
      <TopBar>User</TopBar>

      <div className="px-8 py-2">
        <div className="mb-6">
          <SubNav
            tabs={subNavTabs}
            onSelected={(tabName) => {
              selectTab(tabName);
            }}
          />
        </div>

        <Outlet />
      </div>
    </div>
  );
}
