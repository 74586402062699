import React, { useState, useEffect, Fragment, ReactNode, useMemo, useRef } from 'react'
import Input from './Input'
import moment from 'moment';
import Dropdown from './Dropdown';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { ChevronDownIcon, PlusIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import classNames from 'src/tools/classNames';
import { CheckIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import UserManager from 'src/tools/UserManager';
import Spinner from '../Spinner';
import IconDropdown from './IconDropdown';

type DropdownOption = {
    label: string,
    value: string,
}

type Props = {
    onChange?: (dropdownOption: DropdownOption) => void,

    label?: string,
    justifyRight?: boolean,
    wide?: boolean,
    className?: string,
    placeholder?: string,
    fetchDropdownOptionsRequest: () => Promise<DropdownOption[]>,
    createNewDropdownOptionRequest: (dropdownOption: DropdownOption) => Promise<void>,
    secondaryDropdownOptions: { label: string, onSelected: (value: string) => void }[],
    dropdownType: string,
}

/**
  * Input component for selecting a dropdown option.
  * Handles fetching and creating dropdown options that tie to the company
  * currently, dropdown options can only be strings.
  *
  * Props:
  * - `onChange`: Callback function to call when the selected dropdown option is changed
  * - `label`: Label for the input
  * - `justifyRight`: If true, the dropdown will be right-aligned
  * - `wide`: If true, the dropdown will be wider
  * - `className`: Additional classes to apply to the dropdown
  * - `placeholder`: Placeholder text for the dropdown
  * - `fetchDropdownOptionsRequest`: Function to fetch the dropdown options from the server
  * - `createNewDropdownOptionRequest`: Function to create a new dropdown option on the server
  * - `secondaryDropdownOptions`: another horizontal ellipsis dropdown exists inside of each dropdown option, this is the options for that dropdown
  * - `dropdownType`: The type of dropdown (e.g. "Issue Type"), this is a string to display in the dropdown
  */
export default function CustomizableDropdown({
    onChange,
    label,
    justifyRight = false,
    wide = false,
    className,
    placeholder = "Select an option",
    fetchDropdownOptionsRequest,
    createNewDropdownOptionRequest,
    secondaryDropdownOptions,
    dropdownType,
}: Props) {
    const [selectedDropdownOption, setSelectedDropdownOption] = useState<DropdownOption>();
    const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
    const [customOpen, setCustomOpen] = useState(false);
    const [loadingDropdownOptions, setLoadingDropdownOptions] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    // Add click outside handler
    useEffect(() => {
        // Fetch dropdown options on mount
        fetchDropdownOptions()

        // check if the user clicks outside the dropdown component, if so, close it
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setCustomOpen(false);
            }
        }

        // add the event listener to the document
        document.addEventListener('mousedown', handleClickOutside);

        // remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    /**
     * Toggle the dropdown
     */
    function buttonClicked() {
        setCustomOpen(prev => !prev);
    }

    /**
     * Fetch the user's dropdown options from the server
     */
    async function fetchDropdownOptions() {
        setLoadingDropdownOptions(true)
        const dropdownOptions = await fetchDropdownOptionsRequest()
        setLoadingDropdownOptions(false)
        setDropdownOptions(dropdownOptions)
    }

    /**
     * Add a new dropdown option
     * Optimistically updates the state and sends a request to the server.
     * Reverts if the request fails.
     */
    function createNewDropdownOption(dropdownOption: DropdownOption) {
        let oldDropdownOptions = dropdownOptions;
        let newDropdownOptions = [...oldDropdownOptions, dropdownOption]
        setDropdownOptions(newDropdownOptions)

        createNewDropdownOptionRequest(dropdownOption)
            .then(() => {
                // Success
                setSelectedDropdownOption(dropdownOption)
                onChange?.(dropdownOption)
                setCustomOpen(false)
            }).catch((err) => {
                // Revert if failed
                setDropdownOptions(oldDropdownOptions)
            });
    }

    return <div ref={dropdownRef}>
        {label && (
            <label
                className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200"
            >
                {label}
            </label>
        )}
        <Menu 
        as="div" 
        className={classNames(
            "relative inline-block text-left",
            className,
        )}
        >
            {({ open }) => (
                <>
                    <div>
                        <MenuButton
                            onClick={buttonClicked}
                            className={classNames(
                                "inline-flex w-full gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                                wide ? "justify-between" : "justify-center",
                                placeholder && !selectedDropdownOption ? "text-gray-400" : "text-gray-900",
                            )}
                        >
                            {selectedDropdownOption ? selectedDropdownOption.label : placeholder}
                            <ChevronDownIcon
                                aria-hidden="true"
                                className="-mr-1 text-gray-400 size-5" />
                        </MenuButton>
                    </div>
                    {customOpen && (
                        <MenuItems
                            static
                            className={classNames(
                                "absolute z-10 mt-2 min-w-56 rounded-md bg-white shadow-lg ring-1",
                                "ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform",
                                "data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in",

                                justifyRight ? "right-0 origin-top-right" : "left-0 origin-top-left"
                            )}
                        >
                            <div className="py-2">
                                <NewDropdownOption
                                    onCreate={createNewDropdownOption}
                                    dropdownType={dropdownType}
                                />
                                {!loadingDropdownOptions
                                    ? (
                                        dropdownOptions?.map((dropdownOption, index) =>
                                            <Item
                                                key={dropdownOption.value}
                                                onClick={() => {
                                                    setSelectedDropdownOption(dropdownOption)
                                                    onChange?.(dropdownOption)
                                                    setCustomOpen(false)
                                                }}
                                            >
                                                {dropdownOption.label}
                                                <IconDropdown
                                                    icon = {<EllipsisHorizontalIcon className="w-5 h-5 text-gray-600 hover:text-gray-400" />}
                                                    options={secondaryDropdownOptions}
                                                    horizontalAlign='end'
                                                    verticalAlign='bottom'
                                                    dropdownClassName='!w-fit'
                                                    onSelected={async (option) => {
                                                        await option.onSelected(dropdownOption.value)
                                                        // re-fetch dropdown options
                                                        fetchDropdownOptions()
                                                    }}
                                                />
                                            </Item>
                                        )
                                    )
                                    : (
                                        <div className="py-3">
                                            <Spinner size={20} />
                                        </div>
                                    )
                                }
                            </div>
                        </MenuItems>)
                    }
                </>
            )
            }
        </Menu>
    </div>
}


type ItemProps = {
    children: ReactNode,
    hover?: boolean,
    onClick?: () => void,
}

/**
 * Sub-component used in the CustomizableDropdown component's dropdown.
 * Handles styling for menu items.
 *
 * Props:
 * - `children`: The content of the item
 * - `hover`: If true, the item will have a hover effect (default: true)
 * - `onClick`: Callback function to call when the item is clicked
 */
function Item({
    children,
    hover = true,
    onClick,
}: ItemProps) {
    return <MenuItem>
        {({ active, close }) => (
            <button
                type="button"
                onClick={onClick}
                className={classNames(
                    "px-4 py-2 text-sm text-gray-500",
                    hover ? "hover:bg-gray-100 cursor-pointer" : "",
                    "flex items-center justify-between w-full",
                )}
            >
                {children}
            </button>
        )}
    </MenuItem>
}

/**
 * Sub-component used in the CustomizableDropdown component's dropdown.
 * Item/MenuItem for creating a new dropdown option.
 * Houses everything for creating a new dropdown option (selection dropdowns; buttons)
 *
 * Props:
 * - `onCreate`: Callback function to call when the dropdown option is created
 */
function NewDropdownOption({ 
    onCreate,
    dropdownType,
}: { 
    onCreate: (dropdownOption: DropdownOption) => void,
    dropdownType: string
}) {

    const [newDropdownOption, setNewDropdownOption] = useState<DropdownOption>();

    const [creatingNewDropdownOption, setCreatingNewDropdownOption] = useState(false);

    /**
     * Start creating a new dropdown option.
     * Sets the state to a blank dropdown option.
     */
    function createDropdownOption() {
        setCreatingNewDropdownOption(true)

        setNewDropdownOption({
            label: '',
            value: ''
        })
    }

    /**
     * Cancels the creation of a new dropdown option.
     * Clears the state.
     */
    function cancel() {
        setCreatingNewDropdownOption(false)
        setNewDropdownOption(undefined)
    }

    /**
     * Confirms the creation of a new dropdown option.
     * Calls `onCreate` to notify parent.
     * Clears the state so another new dropdown option
     * can be created.
     */
    function confirm() {
        setCreatingNewDropdownOption(false)
        onCreate(newDropdownOption)
        setNewDropdownOption(undefined)
    }

    return creatingNewDropdownOption ?
        <Item
            hover={false}
        >
            <div className="flex flex-row items-center w-full gap-2" >
                <Input
                    value={newDropdownOption?.label}
                    onChange={(value) => setNewDropdownOption({ label: value, value: value.toLowerCase().replace(/ /g, '-') })}
                    placeholder=''
                    stopPropagation={true}
                />
                <button
                    type="button"
                    className="text-primary-green hover:text-primary-green-700 data-[disabled=true]:text-gray-400"
                    onClick={confirm}
                    data-disabled={!newDropdownOption}
                    disabled={!newDropdownOption}
                >
                    <CheckIcon className="w-5 h-5" />
                </button>
                <button
                    type="button"
                    className="text-primary-rose hover:text-primary-rose-900"
                    onClick={cancel}
                >
                    <XMarkIcon className="w-5 h-5" />
                </button>
            </div>
        </Item>
        :
        <Item
            onClick={createDropdownOption}
        >
            <div className="flex items-center justify-between w-full">
                <p>{`Create New ${dropdownType}`}</p>
                <PlusIcon className='w-5 h-5' />
            </div>
        </Item>

}
