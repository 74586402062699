import { useEffect, useMemo, useState } from "react";
import UserManager from "src/tools/UserManager";

export type User = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  company: {
    type: string;
    id: string;
    role: string;
  }
  role: string;
  settings: {
    inputPresets?: {
      timeRanges: { start: string, end: string }[]
    }
    notifications: NotificationsSettings;
  }
}

export type NotificationPref = {
  email: boolean,
}
export type NotificationsSettings = {
  all: NotificationPref,
  order: {
    newOrder: NotificationPref,
    newQuote: NotificationPref,
    reschedule: NotificationPref,
    agreementChange: NotificationPref,
    newIssue: NotificationPref,
    delivery: NotificationPref,
  }
}

export type UserMap = {
  [id: string]: User;
}

/**
 * Fetches users by id and returns a map of them.
 * If `keepOld` is true, the new users will be combined with the old users.
 * Essentially just caching them if they are needed again.
 */
export default function useUsersByIds(ids: string[], keepOld: boolean = true): UserMap {
  const [users, setUsers] = useState<UserMap>();
  const idSet = useMemo(() => new Set(ids), [ids?.join(",")]);


  useEffect(() => {
    const abortController = new AbortController();

    const url = "/api/user/find-by-ids?userIds=" + [...idSet].join(",");
    UserManager.makeAuthenticatedRequest(url, "GET", null, { signal: abortController.signal })
      .then(res => {
        if (res.data.status === "ok") {
          const newUsers = usersToMap(res.data.users);
          if (keepOld) {
            setUsers(combineMaps(newUsers, users))
          } else {
            setUsers(newUsers);
          }
        }
      }).catch(err => {
        console.error(err);
      });

    return () => {
      abortController.abort();
    }
  }, [idSet]);

  return users;
}

/**
  * Utility to convert an array of users to a map of users.
  */
function usersToMap(users: User[]): UserMap {
  return users.reduce((acc, user) => {
    acc[user._id] = user;
    return acc;
  }, {});
}

/**
 * Utility to combine multiple user maps into one.
 */
function combineMaps(...userMaps: UserMap[]): UserMap {
  return userMaps.reduce((acc, map) => {
    if (!map) return acc;
    // Add all users in map to acc
    for (const [id, user] of Object.entries(map)) {
      acc[id] = user;
    }
    return acc;
  }, {})
}
