import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";
import pluralize from "pluralize";
import React, { Fragment, useEffect, useState } from "react";
import useTheme from "src/hooks/styling/useTheme";
import classNames from "src/tools/classNames";

type Props = {
  itemType?: string; // E.g. "User", "Market", "product"
  options: (OptionType | OptionType[])[];
  selectedOptionsValues: string[];

  showSelectAll?: boolean;
  showSelectNone?: boolean;

  onChange?: (values: string[]) => void;

  noneTextOverride?: string;
  allTextOverride?: string;

  noneButtonText?: string;
  allButtonText?: string;

  minimizeChecks?: boolean;
};

type OptionType = {
  value: string;
  label: string;
  display?: React.ReactNode | string;
};

/**
 * A listbox that allows selecting multiple items.
 * Has options to select all or none.
 *
 * Props:
 * - itemType: The type of item being selected. E.g. "User", "Market", "product"
 * - options: The list of options to select from. Each option has a `value` and `label`.
 * - selectedOptionsValues: The values of the selected options.
 * - showSelectAll: Whether to show the "Select All" option. Default true.
 * - showSelectNone: Whether to show the "Select None" option. Default true.
 * - onChange: Callback when the selected options change.
 * - noneTextOverride: Text to display when no options are selected. Default "No [itemType]"
 * - allTextOverride: Text to display when all options are selected. Default "All [itemType]"
 * - noneButtonText: Text for the "Select None" button. Default "None"
 * - allButtonText: Text for the "Select All" button. Default "All"
 * - minimizeChecks: If none are selected, checks are hidden
 */
export default function MultiSelectListbox({
  itemType = "Item",
  options = [],
  selectedOptionsValues: selectedOptionsIds_prop,
  showSelectAll = true,
  showSelectNone = true,
  onChange = (values: string[]) => {},

  noneTextOverride,
  allTextOverride,

  noneButtonText = "None",
  allButtonText = "All",

  minimizeChecks = false,
}: Props): JSX.Element {
  const [selectedOptionsIds, setSelectedOptionsIds] = useState<string[]>(
    selectedOptionsIds_prop ?? [],
  );

  // E.g. "User" -> "Users"
  const pluralItemType = pluralize(itemType);

  // Update button label based on selected options
  const selectedOptions = options
    .flat()
    .filter((option) => selectedOptionsIds.includes(option.value));
  let buttonLabel = selectedOptions.map((option) => option.label).join(", ");
  if (selectedOptionsIds.length === 0 || options.length === 0) {
    buttonLabel = noneTextOverride ?? `No ${pluralItemType}`;
  } else if (selectedOptionsIds.length === options.length) {
    buttonLabel = allTextOverride ?? `All ${pluralItemType}`;
  }

  const noneSelected = selectedOptionsIds.length === 0;

  // Sync on prop change
  useEffect(() => {
    setSelectedOptionsIds(selectedOptionsIds_prop);
  }, [selectedOptionsIds_prop]);

  /**
   * Update the selected options when the user selects an option.
   * @param values The values of the selected options
   */
  function handleChange(values: string[]): void {
    setSelectedOptionsIds(values);
    onChange(values);
  }

  /**
   * Select all options.
   */
  function selectAll() {
    let allOptions = options.flat().map((option) => option.value);
    setSelectedOptionsIds(allOptions);
    onChange(allOptions);
  }

  /**
   * Select no options.
   */
  function selectNone() {
    setSelectedOptionsIds([]);
    onChange([]);
  }

  // Apply theme here as it appears outside of main App component
  const theme = useTheme();

  return (
    <Listbox value={selectedOptionsIds} onChange={handleChange} multiple>
      <ListboxButton
        className={classNames(
          "rounded-md ring-1 ring-gray-300 ring-inset bg-white dark:bg-gray-700 shadow-sm",
          "px-3 py-2",
          "inline-flex gap-x-1.5",
          "text-sm font-semibold",
        )}
      >
        <p className="truncate max-w-[300px]">{buttonLabel}</p>
        <ChevronDownIcon
          className="w-5 h-5 -mr-1 text-gray-400"
          aria-hidden="true"
        />
      </ListboxButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <ListboxOptions
          anchor="bottom start"
          className={classNames(
            "min-w-[150px] z-30",
            "mt-2 shadow-lg ring-1 ring-black ring-opacity-5 rounded-md dark:ring-gray-400",
            "bg-white dark:bg-gray-700 text-gray-900 dark:text-white",
            theme === "dark" ? "dark" : "", // Apply dark theme as popup is outside of main App component
          )}
        >
          <div className="divide-y">
            {/* All | None */}
            {(showSelectAll || showSelectNone) && (
              <ul className="flex gap-1 m-1">
                {showSelectAll && (
                  <li
                    className="px-2 text-center bg-gray-100 rounded-lg cursor-pointer dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200"
                    onClick={selectAll}
                  >
                    {allButtonText}
                  </li>
                )}
                {showSelectNone && (
                  <li
                    className="px-2 text-center bg-gray-100 rounded-lg cursor-pointer dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200"
                    onClick={selectNone}
                  >
                    {noneButtonText}
                  </li>
                )}
              </ul>
            )}

            {/* List */}
            <ul>
              {options.map((option, index) => {
                if (Array.isArray(option)) {
                  const subOptions: OptionType[] = option;
                  return (
                    <div
                      className={classNames(
                        index > 0 ? "border-t" : "",
                        "py-1",
                      )}
                    >
                      {subOptions.map((subOption) => (
                        <Option
                          key={subOption.value}
                          hideCheck={minimizeChecks && noneSelected}
                          {...subOption}
                        />
                      ))}
                    </div>
                  );
                } else {
                  return (
                    <Option
                      key={option.value}
                      hideCheck={minimizeChecks && noneSelected}
                      {...option}
                    />
                  );
                }
              })}
            </ul>

            {/* No Items */}
            {options.length === 0 && (
              <div className="p-2 text-center text-gray-500 dark:text-gray-400">
                No {pluralItemType}
              </div>
            )}
          </div>
        </ListboxOptions>
      </Transition>
    </Listbox>
  );
}

type OptionProps = {
  hideCheck?: boolean;
} & OptionType;

/**
 * A single option in the listbox. Has a checkbox and label.
 * Wrapper for ListboxOption so it works with the keyboard inputs used by Listbox.
 */
function Option({
  value,
  label,
  display,

  hideCheck = false,
}: OptionProps): JSX.Element {
  return (
    <ListboxOption
      className={classNames(
        "rounded-lg p-1 px-2 m-1 hover:bg-gray-100 data-[focus]:bg-gray-100 dark:hover:bg-gray-800 dark:data-[focus]:bg-gray-800 cursor-pointer flex gap-2 items-center group",
      )}
      value={value}
    >
      {!hideCheck && (
        <div
          className={classNames(
            "w-4 min-w-4 h-4",
            "border-2 border-gray-300 group-hover:border-gray-400 group-data-[selected]:border-0",
            "rounded group-data-[selected]:bg-primary-green transition-colors",
            "flex items-center justify-center",
          )}
        >
          <CheckIcon className="w-3 h-3 text-white opacity-0 group-data-[selected]:opacity-100 stroke-[5]" />
        </div>
      )}
      {/* <div>{label}</div> */}
      {display ?? label}
    </ListboxOption>
  );
}
