import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

import Home from "./pages/Home";
import HomeLayout from "./pages/HomeLayout";

import AppLayout from "./pages/AppLayout";

import Login from "./pages/LoginPages/Login";
import LoginLayout from "./pages/LoginPages/LoginLayout";
import LoginCheck from "./pages/LoginPages/LoginCheck.tsx";
import Register from "./pages/LoginPages/Register";

import DistributorPage from "./pages/DistributorPage";
import DistributorsPage from "./pages/DistributorsPage";
import ForecastPage from "./pages/Forecast/ForecastPage";
import IssuesPage from "./pages/IssuesPage";
import PipelinePage from "./pages/PipelinePage";
import SettingsPage from "./pages/SettingsPage";
import SupportPage from "./pages/SupportPage";

import UserPage from "./pages/UserPage";
import UserProfilePage from "./pages/User/UserProfilePage.tsx";
import UserNotificationsPage from "./pages/User/UserNotificationsPage.tsx";
import UserAppearancePage from "./pages/User/UserAppearancePage.tsx";

// import JobPage from "./pages/deprecated/JobPage";
import JobPage from "./pages/JobPage";
import PipelineJobsPage from "./pages/Pipeline/PipelineJobsPage";
import PipelineMarketsPage from "./pages/Pipeline/PipelineMarketsPage";
import DistributorPipelineViewPage from "./pages/Pipeline/DistributorPipelineViewPage.tsx";
import InvitePage from "./pages/InvitePage";

import SalesForecastPage from "./pages/SalesForecastPage.tsx";

import TeamPage from "./pages/Team/TeamPage";
import ManageTeamPage from "./pages/Team/ManageTeamPage";
import TeamActivityPage from "./pages/Team/TeamActivityPage";

import TrucksPage from "./pages/Trucks/TrucksPage.js";
import ManageTrucksPage from "./pages/Trucks/ManageTrucksPage.js";

import LocationsPage from "./pages/Locations/LocationsPage";
import ManageLocationsPage from "./pages/Locations/ManageLocationsPage";
import LocationsActivityPage from "./pages/Locations/LocationsActivityPage";

import ConnectionsPage from "./pages/Connections/ConnectionsPage";
import ManageConnectionsPage from "./pages/Connections/ManageConnectionsPage";
import ConnectionsActivityPage from "./pages/Connections/ConnectionsActivityPage";
import AcceptRejectConnectionPage from "./pages/Connections/AcceptRejectConnectionPage";

import DataConnectorsPage from "./pages/OperationsSetup/DataConnectorsPage";
import DataMappingPage from "./pages/OperationsSetup/DataMappingPage";
import MarketPage from "./pages/OperationsSetup/MarketPage";
import MarketsPage from "./pages/OperationsSetup/MarketsPage";
import MaterialGroupPage from "./pages/OperationsSetup/MaterialGroupPage";
import MaterialPage from "./pages/OperationsSetup/MaterialPage";
import MaterialsPage from "./pages/OperationsSetup/MaterialsPage";
import StagesPage from "./pages/OperationsSetup/StagesPage";
import OperationsSetupPage from "./pages/OperationsSetupPage";

import JobForecastPage from "./pages/Forecast/JobForecastPage";
import MaterialForecastPage from "./pages/Forecast/MaterialForecastPage";
import WeekForecastDetails from "./pages/Forecast/WeekForecastDetails";
import DistributorForecastViewPage from "./pages/Forecast/DistributorForecastViewPage.tsx";

import OrderJobsPage from "./pages/Orders/OrderJobsPage";

import SetupPage from "./pages/SetupPage";
import RequestPasswordResetPage from "./pages/LoginPages/RequestPasswordResetPage";
import PasswordResetPage from "./pages/LoginPages/PasswordResetPage";
import GetStartedPage from "./pages/GetStartedPage";
import ForecastHistoryPage from "./pages/Forecast/history/ForecastHistoryPage.tsx";
import ForecastSnapshotViewPage from "./pages/Forecast/history/snapshot/ForecastSnapshotViewPage.tsx";

import PerformanceScorecard from "./pages/Performance/PerformanceScorecard.tsx";

import useTheme from "./hooks/styling/useTheme";
import ComingSoon from "./pages/Temp/ComingSoon";
import CrewPlannerPage from "./pages/Labor/CrewPlanner/Page.tsx";
import Layout from "./pages/Labor/Layout.tsx";
import CrewPlannerMainView from "./pages/Labor/CrewPlanner/MainView.tsx";
import MarketForecasts from "./pages/Labor/MarketForecasts/Page.tsx";
import classNames from "./tools/classNames.js";
import OrderLayout from "./components/Orders/OrderLayout.tsx";
import OrderListPage from "./pages/Orders/OrderListPage.tsx";
import TopBar from "./components/nav/TopBar.js";
import OrderDetailsPage from "./pages/Orders/OrderDetailsPage.tsx";

function App() {
  const theme = useTheme();

  return (
    <>
      <div
        className={classNames(
          theme === "dark" ? "dark" : "",
          "dark:text-white dark:bg-gray-800"
        )}
      >
        <Routes>
          {/* / */}
          <Route index element={<Navigate to="/login" />} />
          {/* <Route path="/" element={<HomeLayout />}> */}
          {/*   <Route path="" element={<Home />}></Route> */}
          {/* </Route> */}

          {/* /app */}
          <Route path="/app" element={<AppLayout />}>
            <Route index element={<Navigate to="orders/list" />}></Route>
            {/* <Route path="" element={<AppHome />}></Route> */}

            {/* /app/forecast */}
            <Route path="forecast" element={<ForecastPage />}>
              <Route index element={<Navigate to="job" />}></Route>
              <Route path="job" element={<JobForecastPage />}></Route>
              <Route path="material" element={<MaterialForecastPage />}></Route>
            </Route>
            <Route
              path="forecast/week"
              element={<WeekForecastDetails />}
            ></Route>
            <Route path="forecast/history">
              <Route index element={<ForecastHistoryPage />}></Route>
              <Route path=":id" element={<ForecastSnapshotViewPage />}>
                <Route index element={<Navigate to="job" />}></Route>
                <Route
                  path="job"
                  element={<JobForecastPage isStatic />}
                ></Route>
                <Route
                  path="material"
                  element={<MaterialForecastPage isStatic />}
                ></Route>
              </Route>
            </Route>

            {/* /app/pipeline */}
            <Route path="pipeline" element={<PipelinePage />}>
              <Route index element={<Navigate to="jobs" />}></Route>
              <Route path="markets" element={<PipelineMarketsPage />}></Route>
              <Route path="jobs">
                <Route index element={<PipelineJobsPage />}></Route>
              </Route>
            </Route>

            {/* /app/sales */}
            <Route path="sales" element={<SalesForecastPage />}></Route>

            {/* /app/labor */}
            <Route path="labor" element={<ComingSoon />}></Route>

            {/* /app/jobs */}
            {/* TODO: improve jobs page */}
            <Route
              path="jobs"
              element={
                <div>
                  <TopBar>Jobs</TopBar>
                  <OrderJobsPage />
                </div>
              }
            ></Route>

            {/* /app/orders */}
            <Route path="orders" element={<OrderLayout />}>
              <Route index element={<Navigate to="list" />}></Route>
              <Route path="list" element={<OrderListPage />}></Route>
            </Route>
            <Route
              path="orders/details"
              element={<Navigate to="../orders" />}
            ></Route>
            <Route
              path="orders/details/:id"
              element={<OrderDetailsPage />}
            ></Route>

            {/* /app/schedule */}
            <Route path="schedule" element={<ComingSoon />}></Route>

            {/* /app/installer-forecasts for distributor view only */}
            <Route
              path="installer-forecasts"
              element={<DistributorForecastViewPage />}
            >
              <Route index element={<Navigate to="job" />}></Route>
              <Route path="job" element={<JobForecastPage isStatic />}></Route>
              <Route
                path="material"
                element={<MaterialForecastPage isStatic />}
              ></Route>
            </Route>

            {/* /app/crew-planner */}
            <Route path="labor" element={<Layout />}>
              <Route index element={<Navigate to="market-forecasts" />} />
              <Route path="crew-planner" element={<CrewPlannerPage />}>
                <Route index element={<CrewPlannerMainView />} />
              </Route>
              <Route path="market-forecasts" element={<MarketForecasts />} />
            </Route>

            {/* /app/material-planner */}
            {/* /app/installer-pipeline */}
            <Route
              path="installer-pipeline"
              element={<DistributorPipelineViewPage />}
            ></Route>

            {/* TODO: deprecate pages */}
            <Route path="user" element={<UserPage />}>
              <Route index element={<Navigate to="profile" />}></Route>
              <Route path="profile" element={<UserProfilePage />}></Route>
              <Route
                path="notifications"
                element={<UserNotificationsPage />}
              ></Route>
              <Route path="appearance" element={<UserAppearancePage />}></Route>
            </Route>
            <Route path="issues" element={<IssuesPage />}></Route>
            <Route path="distributors" element={<DistributorsPage />}></Route>
            <Route
              path="distributors/:id"
              element={<DistributorPage />}
            ></Route>
            <Route path="jobs/:id" element={<JobPage />} />
            {/* 
            // DEPRECATED
            <Route path="jobs">
              <Route index element={<JobsPage />} />
              <Route path=":id" element={<JobPage />}></Route>
            </Route> */}
            <Route
              path="crews"
              element={
                // <Col className="pt-3 pb-3">
                <div className="pb-3 flo-title">Crews</div>
                // </Col>
              }
            ></Route>
            <Route path="crews/:id" element={<div>TODO: crew</div>}></Route>
            <Route path="settings" element={<SettingsPage />}></Route>
            <Route path="support" element={<SupportPage />}></Route>
            <Route
              path="*"
              element={
                <div className="p-6 flo-title">Error 404: Page Not Found</div>
              }
            ></Route>

            {/* /app/operations-setup */}
            <Route path="operations-setup" element={<OperationsSetupPage />}>
              <Route index element={<Navigate to="material" />}></Route>
              <Route path="material" element={<MaterialsPage />}></Route>
              <Route path="material/:id" element={<MaterialPage />}></Route>
              <Route
                path="material/group/:id"
                element={<MaterialGroupPage />}
              ></Route>
              <Route path="markets" element={<MarketsPage />}></Route>
              <Route path="markets/:id" element={<MarketPage />}></Route>
              <Route path="stages" element={<StagesPage />}></Route>
              <Route
                path="data-connectors"
                element={<DataConnectorsPage />}
              ></Route>
              <Route path="data-mapping" element={<DataMappingPage />}></Route>
            </Route>

            {/* /app/alerts */}
            <Route path="alerts" element={<ComingSoon />}></Route>

            {/* /app/performance */}
            <Route
              path="performance"
              element={<PerformanceScorecard />}
            ></Route>

            {/* /app/automation */}
            <Route path="automation" element={<ComingSoon />}></Route>

            {/* /app/get-started */}
            <Route path="get-started" element={<GetStartedPage />}></Route>

            {/* /app/trucks */}
            <Route path="trucks" element={<TrucksPage />}>
              <Route index element={<Navigate to="manage" />}></Route>
              <Route path="manage" element={<ManageTrucksPage />}></Route>
              <Route path="activity" element={<ComingSoon />}></Route>
            </Route>

            {/* /app/team */}
            <Route path="team" element={<TeamPage />}>
              <Route index element={<Navigate to="manage" />}></Route>
              <Route path="manage" element={<ManageTeamPage />}></Route>
              <Route path="activity" element={<TeamActivityPage />}></Route>
            </Route>

            {/* /app/connections */}
            <Route path="connections" element={<ConnectionsPage />}>
              <Route index element={<Navigate to="manage" />}></Route>
              <Route path="manage" element={<ManageConnectionsPage />}></Route>
              <Route
                path="activity"
                element={<ConnectionsActivityPage />}
              ></Route>
            </Route>

            {/* /app/locations */}
            <Route path="locations" element={<LocationsPage />}>
              <Route index element={<Navigate to="manage" />}></Route>
              <Route path="manage" element={<ManageLocationsPage />}></Route>
              <Route
                path="activity"
                element={<LocationsActivityPage />}
              ></Route>
            </Route>
          </Route>

          {/* /login/check */}
          <Route path="login/check" element={<LoginCheck />}></Route>

          {/* /login */}
          <Route path="/login" element={<LoginLayout />}>
            <Route index element={<Login />}></Route>
            <Route path="register" element={<Register />}></Route>
            <Route
              path="request-password-reset"
              element={<RequestPasswordResetPage />}
            ></Route>
            <Route
              path="reset-password"
              element={<PasswordResetPage />}
            ></Route>
          </Route>

          <Route
            path="accept-reject-connection"
            element={<AcceptRejectConnectionPage />}
          ></Route>

          {/* /invite */}
          <Route path="/invite" element={<InvitePage />}></Route>

          <Route path="/setup" element={<SetupPage />}></Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
