import { TabletSmartphoneIcon } from "lucide-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import Button from "src/components/input/Button";
import UserContext from "src/contexts/user/UserContext.tsx";
import { AgreementStatus } from "src/hooks/data/orders/useOrders.ts";
import { OrderStatus } from "src/hooks/data/orders/useOrders.ts";
import { Order } from "src/hooks/data/orders/useOrders.ts";
import useCurrentUser from "src/hooks/data/users/useCurrentUser.ts";
import UserManager from "src/tools/UserManager";

/**
 * Actions that an order can take. For now, just adding/approving quote.
 * This structure should be nice down the road if each status has unique actions.
 * Could adjust to match status values, but for now, just keeping it simple.
 */
export enum OrderAction {
  ADD_QUOTE, // For a distributor to add a quote
  APPROVE_ORDER, // For an installer or distributor to approve/review an order
  APPROVE_ORDER_FOR_INSTALLER, // For distributors to approve for installers
  ADD_PICK_TICKET, // For a distributor to add a pick ticket
  ADD_PICK_TICKET_AND_ASSIGN_TRUCK, // For a distributor to add a pick ticket and assign a truck
  CANCEL_ORDER, // For an installer or distributor to cancel an order
  ASSIGN_TRUCK, // For a distributor to assign a truck to an order
  RESCHEDULE_ORDER, // For an installer or distributor to reschedule an order
  REVIEW_DATE, // For an installer or distributor to review a date change
  ADD_NOTE, // For an installer or distributor to add a note
  ADD_ISSUE, // For an installer or distributor to add an issue
  UPLOAD_PICK_TICKET, // For a distrubitior to upload a new pick ticket
  VIEW_ISSUES, // For an installer or distributor to view an issue
  CHANGE_STATUS, // For an admin to change an order's status
  MARK_AS_DELIVERED, // For a distributor to mark an order as delivered
}

type ContextType = {
  onRunAction?: (a: OrderAction) => void;
};

const Context = createContext<ContextType>({
  onRunAction: (a: OrderAction) => {},
});

type Props = {
  order: Order;
  onRunAction?: (a: OrderAction) => void;
};

/**
 * A simple component to conditionally render the next action for an order.
 * For example, given an order in the `WAITING_FOR_QUOTE` status this will
 * render a "Review" button for a distributor.
 * On a button press, this will call the `onRunAction` function with the
 * appropriate `OrderAction`.
 */
export default function OrderActionButton({
  order,
  onRunAction = (a: OrderAction) => {},
}: Props) {
  const { user, company } = useContext(UserContext);
  // const user = useCurrentUser();
  const companyType = user?.company?.type;
  // const [company, setCompany] = useState({}); // TODO: type this

  const waitingOnInstaller =
    order.quote?.agreements.deliveryDate.installer ===
      AgreementStatus.PENDING ||
    order.quote?.agreements.material.installer === AgreementStatus.PENDING ||
    order.quote?.agreements.amount?.installer === AgreementStatus.PENDING;
  const waitingOnDistributor =
    order.quote?.agreements.deliveryDate.distributor ===
      AgreementStatus.PENDING ||
    order.quote?.agreements.material.distributor === AgreementStatus.PENDING ||
    order.quote?.agreements.amount?.distributor === AgreementStatus.PENDING;

  /**
   * Actions specific to different user types
   * and specific statuses. For example, a distributor can
   * add a quote to an order that is WAITING_FOR_QUOTE.
   */
  const StatusActions = {
    installer: {
      [OrderStatus.WAITING_FOR_QUOTE_APPROVAL]: (
        <ActionButton text="Review" action={OrderAction.APPROVE_ORDER} />
      ),
      [OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT]: !waitingOnInstaller ? null : (
        <ActionButton text="Review" action={OrderAction.APPROVE_ORDER} />
      ),
    },
    distributor: {
      [OrderStatus.WAITING_FOR_QUOTE]: (
        <ActionButton
          text={order.quoteRequired ? "Add Quote" : "Review"}
          action={OrderAction.ADD_QUOTE}
        />
      ),
      [OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT]:
        !waitingOnDistributor ? null : (
          <ActionButton text="Review" action={OrderAction.APPROVE_ORDER} />
        ),
      [OrderStatus.CONFIRMED]:
        // if company.settings.orders.packagingVerificationEnabled, use ADD_PICK_TICKET< otherewise use ASSIGN_TRUCK
        company ? (
          company.settings?.orders?.packagingVerificationEnabled ? (
            <ActionButton
              text="Hand off to Packaging"
              action={OrderAction.ADD_PICK_TICKET}
            />
          ) : (
            <ActionButton
              text="Hand off to Delivery"
              action={OrderAction.ADD_PICK_TICKET_AND_ASSIGN_TRUCK}
            />
          )
        ) : (
          <div className="w-40 bg-gray-300 rounded-lg h-7 animate-pulse" />
        ),
      [OrderStatus.READY_TO_PACKAGE]: <MobileButton />,
      [OrderStatus.PACKAGING]: <MobileButton />,
      [OrderStatus.PACKAGED]: (
        <ActionButton text="Assign Truck" action={OrderAction.ASSIGN_TRUCK} />
      ),
      [OrderStatus.READY_TO_DELIVER]: <MobileButton />,
      [OrderStatus.IN_TRANSIT]: <MobileButton />,
    },
  };

  let statusAction = StatusActions[user?.company?.type]?.[order.status];

  // --- Special cases for actions and variants --- //
  // Need to confirm reschedule
  if (
    // Before CONFIRMED
    ![
      OrderStatus.WAITING_FOR_QUOTE,
      OrderStatus.WAITING_FOR_QUOTE_APPROVAL,
      OrderStatus.WAITING_FOR_QUOTE_ADJUSTMENT,
    ].includes(order.status) &&
    // PENDING date
    order.quote?.agreements.deliveryDate[companyType] ===
      AgreementStatus.PENDING
  ) {
    statusAction = (
      <ActionButton text="Review Date" action={OrderAction.REVIEW_DATE} />
    );
  }

  // Render button
  return (
    <Context.Provider value={{ onRunAction }}>{statusAction}</Context.Provider>
  );
}

type ActionButtonProps = {
  text: string;
  action: OrderAction;
};

/**
 * Displays an action button with the given text. When clicked,
 * runs the given order action.
 */
function ActionButton({ text, action }: ActionButtonProps) {
  const { onRunAction } = useContext(Context);

  return (
    <Button
      variant="primary"
      className="!px-2 !py-1"
      onClick={(e) => {
        e.stopPropagation();
        onRunAction(action);
      }}
    >
      {text}
    </Button>
  );
}

/**
 * Displays "Waiting on Mobile" button to indicate that an action
 * is to be taken on the TOA mobile app.
 */
function MobileButton({}) {
  return (
    <div className="flex items-center gap-1 p-1 text-gray-500 border border-gray-200 rounded-lg bg-gray-50">
      <TabletSmartphoneIcon className="w-4 h-4 min-w-4" />
      <p>Waiting on Mobile</p>
    </div>
  );
}
