import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";
export type Distributor = {
  _id: string;
  name: string;
};

/**
 * Returns the visible distributors of the user's company.
 * For example, gets connected distributors if the user
 * is within an installer. If the user is within an account,
 * this gets all distributors' distributors. Etc.
 *
 * Before distributors are fetched, returns null.
 */
export default function useDistributors() {
  const [distributors, setDistributors] = useState<Distributor[]>();

  // Get distributors on load
  useEffect(() => {
    UserManager.makeAuthenticatedRequest("/api/distributors/list")
      .then((response) => {
        if (response.data.status === "ok") {
          setDistributors(response.data.distributors);
        } else {
          console.error("Failed to get distributors", response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return distributors;
}
