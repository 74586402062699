import { InformationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import classNames from "src/tools/classNames";

/**
  * Displays an information icon with a tooltip that appears when the icon is hovered over.
  */
export default function InfoTooltip({ text = "", justify = "center", verticalAlign = "bottom" }) {
  // TODO: implement better width for small text string
  return (
    <div className="relative group">
      <InformationCircleIcon className="w-5 h-5 text-gray-500 stroke-2 group-hover:text-gray-400 cursor-help" />
      <div
        className={classNames(
          "hidden group-hover:flex absolute w-64 origin-top text-white bg-black bg-opacity-70 font-normal text-sm p-3 rounded z-10",
          justify === "center"
            ? "left-1/2 -translate-x-1/2"
            : justify === "left"
              ? "left-0"
              : justify === "right"
                ? "right-0"
                : "left-1/2",
          verticalAlign === "top"
            ? "bottom-6"
            : ""
        )}
      >
        <div className="whitespace-break-spaces">{text}</div>
      </div>
    </div>
  );
}
