import React, { useEffect } from "react";
import Spinner from "src/components/Spinner";
import UserManager from "src/tools/UserManager";
import { useNavigate, useSearchParams } from "react-router-dom";

/**
 * Page to send users to when they click on a link from an invite email.
 * This page checks if the user is pending and sends them to the register page if so.
 * Otherwise, it sends them to the reroutePath.
 */
export default function LoginCheck() {
  const navigate = useNavigate();

  // Get query params like c/i/e/reroutePath
  const [searchParams] = useSearchParams();
  const c = searchParams.get("c");
  const i = searchParams.get("i");
  const e = searchParams.get("e");
  const u = searchParams.get("u");
  const reroutePath = searchParams.get("reroutePath");

  useEffect(() => {
    fetch(
      `/api/user/check-pending?userId=${u}&email=${encodeURIComponent(e)}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data: any) => {
        const isPending = data.isPending;
        if (isPending) {
          // Go to register page
          navigate(
            `/login/register?c=${c}&i=${i}&e=${encodeURIComponent(
              e
            )}&u=${u}&reroutePath=${reroutePath}`
          );
        } else {
          navigate(reroutePath); // Might need to decode URI
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <Spinner size={100} />
    </div>
  );
}
