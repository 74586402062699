import React from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Card({ children, className = "" }) {
  return (
    <div className={classNames(className, 'shadow rounded-lg bg-white dark:bg-gray-900 p-4 min-w-min')}>
      {children}
    </div>
  )
}

/**
 * Simple footer for a card. Handles styling a different background color.
 */
export function CardFooter({ children, className = "" }) {
  return <div
    className={classNames(
      className,
      "-m-4 mt-4 p-4 rounded-b-lg bg-gray-50 dark:bg-gray-800/30"
    )}
  >
    {children}
  </div>

}
