import React, { useContext } from "react";
import Card, { CardFooter } from "src/components/Card";
import CompanyPicture from "src/components/companies/CompanyPicture.tsx";
import InfoTooltip from "src/components/InfoTooltip";
import Button from "src/components/input/Button";
import Switch from "src/components/input/Switch.tsx";
import ProfilePicture from "src/components/users/ProfilePicture.tsx";
import UserContext from "src/contexts/user/UserContext.tsx";
import UserManager from "src/tools/UserManager";

type Props = {};

/**
 * The base user profile page. It displays their user and company information.
 * The user can logout from this page.
 * If the user is an admin of a distributor, it also displays company settings.
 */
export default function UserProfilePage({}: Props) {
  const { user, company, reloadCompany } = useContext<UserContext>(UserContext);

  function logout() {
    UserManager.logout();
  }

  /**
   * toggle packaging verification setting in the company object to newValue
   */
  async function changePackagingVerification(newValue) {
    const res = await UserManager.makeAuthenticatedRequest(
      "/api/distributors/change-settings",
      "POST",
      {
        setting: "orders.packagingVerificationEnabled",
        value: newValue,
      },
    );

    reloadCompany();
  }

  return (
    <div className="flex flex-col gap-3">
      {/* Info card */}
      <Card className="flex flex-col text-base font-normal">
        <div className="text-lg font-semibold">Personal Info</div>
        <div className="my-4">
          <ProfilePicture size={54} />
        </div>
        <div className="px-4 grid grid-cols-[auto,auto] w-min gap-x-10 gap-y-1 whitespace-nowrap">
          <div>Name:</div>
          <div className="font-semibold">
            {user.firstName} {user.lastName}
          </div>
          <div>Email:</div>
          <div className="text-gray-500">{user.email}</div>
          <div>Role:</div>
          <div className="capitalize text-gray-500">
            {user?.company?.role || "None"}
          </div>
        </div>

        <CardFooter>
          <div className="flex items-center justify-end">
            <Button onClick={logout}>Log Out</Button>
          </div>
        </CardFooter>
      </Card>

      {/* Company card // TODO: make it not just installers */}
      <Card className="flex flex-col text-base font-normal">
        <div className="text-lg font-semibold">Company Info</div>
        <div className="my-4">
          <CompanyPicture size={54} />
        </div>
        <div className="px-4 grid grid-cols-[auto,auto] w-min gap-x-10 gap-y-1 whitespace-nowrap">
          <div>Name:</div>
          <div className="font-semibold">{company.name}</div>
          <div>Type:</div>
          <div className="capitalize text-gray-500">{user.company?.type}</div>
        </div>
      </Card>

      {/* TODO: create a new settings page to move this to */}
      {user.company?.role === "admin" &&
        user?.company?.type === "distributor" && (
          <Card>
            <div className="pb-4 text-lg font-semibold">Company Settings</div>
            <div className="flex justify-between items-center gap-4">
              <div className="flex flex-row items-center gap-2">
                <p>Packaging Verification</p>
                <InfoTooltip text="When enabled, orders will go through packaging stages, and packaging verification photos will be required." />
              </div>
              <Switch
                checked={
                  company?.settings?.orders?.packagingVerificationEnabled
                }
                setChecked={async (value) => {
                  await changePackagingVerification(value);
                }}
              />
            </div>
          </Card>
        )}
    </div>
  );
}
