import React, { useEffect, useState } from 'react'
import { Field, Switch as HeadlessSwitch, Label } from '@headlessui/react'
import classNames from 'src/tools/classNames';

type Props = {
  checked?: boolean,
  onChange?: (v: boolean) => void
  variant?: Variant
  label?: string
  disabled?: boolean
}

export enum Variant {
  DEFAULT = 'default',
  BOXY = 'boxy',
}

/**
 * A simple switch/toggle component.
 * Calls the `onChange` callback when the switch is toggled.
 * Will update the switch's state when the `checked` prop is updated.
 *
 * Variants:
 * - `default` - A simple switch.
 * - `boxy` - A boxy switch. More compact and color indicated on handle not bg
 */
export default function Switch({
  checked: checked_prop = false,
  setChecked: setChecked_prop = (_v: boolean) => { checked_prop = !checked_prop },
  variant = Variant.DEFAULT,
  label = null,
  disabled = false,
}) {
  const [checked, setChecked] = useState<boolean>(checked_prop);

  useEffect(() => {
    setChecked(checked_prop)
  }, [checked_prop])

  function handleChange(v: boolean) {
    setChecked(v);
    setChecked_prop(v);
  }

  let switchElem = {
    switchClasses: '',
    handleClasses: '',
  }

  // TODO: handle dark mode
  switch (variant) {
    case Variant.BOXY:
      switchElem.switchClasses = classNames(
        'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-md border border-gray-300 transition-colors',
        'duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-green focus:ring-offset-2'
      )
      switchElem.handleClasses = classNames(
        checked ? 'translate-x-5' : 'translate-x-0.5',
        checked ? 'bg-primary-green' : 'bg-white',
        'pointer-events-none inline-block h-4 w-5 my-auto transform rounded shadow ring-0 transition duration-200 ease-in-out'
      )
      break;

    case Variant.DEFAULT:
    default:
      switchElem.switchClasses = classNames(
        checked ? 'bg-primary-green data-[disabled]:bg-gray-400' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-green focus:ring-offset-2'
      )

      switchElem.handleClasses = classNames(
        checked ? 'translate-x-5' : 'translate-x-0',
        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
      )
      break;
  }

  return <Field className={classNames(
    'flex'
  )}>
    {label && <Label className="pr-1.5">{label}</Label>}
    <HeadlessSwitch
      checked={checked}
      onChange={handleChange}
      className={switchElem.switchClasses}
      disabled={disabled}
    >
      <span className="sr-only"> Use setting </span>
      <span
        aria-hidden="true"
        className={switchElem.handleClasses}
      />

    </HeadlessSwitch>
  </Field>
}
