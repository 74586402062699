import React, { useState } from "react";
import { Order } from "src/hooks/data/orders/useOrders";
import StatusDisplay from "./StatusDisplay.tsx";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { MinorBadge } from "../OrderRowItem.tsx";
import AddressHelper from "src/utils/addressHelper.ts";

// define the props interface
type Props = {
  order: Order;
  companyType: "installer" | "distributor";
  connectionNames: string[] | undefined;
  marketName: string | undefined;
  deliveryDate: string | undefined;
  sharedWithUsers: any[];
  locationName: string | undefined;
};

/**
 * Universal component to display order metadata in modals
 * Used in various order action modals to display order metadata.
 */
export default function ModalOrderSummary({
  order,
  companyType,
  connectionNames,
  marketName,
  deliveryDate,
  sharedWithUsers,
  locationName,
}: Props) {
  const [sharedWithExpanded, setSharedWithExpanded] = useState(false);

  const companyFieldName =
    {
      installer: "Distributor",
      distributor: "Installer",
      account: "Companies",
    }[companyType] || "Companies";

  return (
    <div className="-mx-4 sm:-mx-6">
      <div className="flex flex-col items-start justify-start w-full gap-1 px-10 py-3 bg-gray-100 dark:bg-gray-900 border-t border-b border-gray-300 divide-y divide-gray-200">
        <div>
          <div className="flex flex-row items-center justify-start gap-4">
            <div className="text-base font-semibold">{order.name}</div>
            <StatusDisplay order={order} companyType={companyType} />
          </div>
          <div className="flex flex-col items-start justify-start w-full pb-2 text-sm font-normal text-gray-500">
            <div className="w-52">
              {AddressHelper.toString(order.orderAddress)}
            </div>
          </div>
        </div>
        <div className="grid w-full grid-cols-[auto_1fr] grid-rows-3 py-2 gap-x-6">
          <div className="col-start-1 row-start-1 text-sm font-medium">
            {companyFieldName}:
          </div>
          <div className="col-start-2 row-start-1 text-sm font-normal text-gray-500">
            {connectionNames?.join(", ") ?? "--"}
          </div>
          <div className="col-start-1 row-start-2 text-sm font-medium">
            {companyType === "installer" ? "Market" : "Location"}:
          </div>
          <div className="col-start-2 row-start-2 text-sm font-normal text-gray-500">
            {companyType === "installer"
              ? (marketName ?? "--")
              : (locationName ?? "--")}
          </div>
          <div className="col-start-1 row-start-3 text-sm font-medium">
            Delivery Date:
          </div>
          <div className="col-start-2 row-start-3 text-sm font-normal text-gray-500">
            {deliveryDate}
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex flex-row items-center justify-between w-full pt-2">
            <div className="text-sm font-medium">Shared With</div>
            <div
              className="px-4"
              onClick={() => setSharedWithExpanded(!sharedWithExpanded)}
            >
              {sharedWithExpanded ? (
                <ChevronUpIcon className="w-5 h-5 text-gray-300 cursor-pointer hover:text-gray-400" />
              ) : (
                <ChevronDownIcon className="w-5 h-5 text-gray-300 cursor-pointer hover:text-gray-400" />
              )}
            </div>
          </div>
          {sharedWithExpanded && (
            <div className="flex flex-row flex-wrap items-start justify-start w-full gap-2 pt-2">
              {sharedWithUsers.map((user, index) => (
                <div key={index} className="text-sm font-normal text-gray-500">
                  <MinorBadge>
                    <div className="px-2 py-1">{user.email}</div>
                  </MinorBadge>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
