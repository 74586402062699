import React from "react";
import { Editor } from "@tiptap/react";
import classNames from "src/tools/classNames";
import {
    Btn,
    Separator,
} from "./Utils.tsx";
import {
    BoldIcon,
    ItalicIcon,
    UnderlineIcon,
    StrikethroughIcon,
} from '@heroicons/react/24/outline';
import {
    ListIcon,
    ListOrderedIcon,
    ListTodoIcon,
    TextQuoteIcon,
} from 'lucide-react';
import "./TiptapStyle.css"


type ToolbarProps = {
    show: boolean;
    editor: Editor;
}

/**
 * Toolbar component for the editor
 * 
 * Contains buttons for:
 * - Bold, Italic, Underline, Strike
 * - Bullet List, Ordered List, Task List
 * - Blockquote
 * 
 * @boolean show - whether the toolbar is visible
 * @Editor editor - the editor instance
 */
export default function Toolbar({ show, editor }: ToolbarProps) {
    return <div
        data-show={show}
        className={classNames(
            "data-[show=true]:mt-2 data-[show=true]:py-1",
            "data-[show=true]:scale-y-100 data-[show=false]:scale-y-0 origin-top",
            "data-[show=true]:h-5 data-[show=false]:h-0 origin-top",
            "transition-all",
            "flex items-center gap-1"
        )}
    >
        {/* Bold Italic Underline Strike */}
        <Btn
            Icon={BoldIcon}
            onClick={() => editor.chain().focus().toggleBold().run()}
            active={editor.isActive("bold")}
        />
        <Btn
            Icon={ItalicIcon}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            active={editor.isActive("italic")}
        />
        <Btn
            Icon={UnderlineIcon}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            active={editor.isActive("underline")}
        />
        <Btn
            Icon={StrikethroughIcon}
            onClick={() => editor.chain().focus().toggleStrike().run()}
            active={editor.isActive("strike")}
        />

        <Separator />

        {/* Lists */}
        <Btn
            Icon={ListIcon}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            active={editor.isActive("bulletList")}
        />
        <Btn
            Icon={ListOrderedIcon}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            active={editor.isActive("orderedList")}
        />
        <Btn
            Icon={ListTodoIcon}
            onClick={() => editor.chain().focus().toggleTaskList().run()}
            active={editor.isActive("taskList")}
        />

        <Separator />

        {/* Blockquote */}
        <Btn
            Icon={TextQuoteIcon}
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            active={editor.isActive("blockquote")}
        />
    </div >
}