import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useInstallers, { Installer } from "./useInstallers.ts";

/**
 * Returns the visible installers of the user's company along with holding state
 * for selected installers.
 *
 * Before installers are fetched, returns null for installers and selected.
 */
export default function useMultiInstallerSelect(
  defaultAll: boolean = false,
): [Installer[], Installer[], Dispatch<SetStateAction<Installer[]>>] {
  const installers = useInstallers();
  const [selected, setSelected] = useState();
  useEffect(() => {
    if (installers && defaultAll) {
      setSelected(installers);
    }
  }, [installers, defaultAll]);
  return [installers, selected, setSelected];
}
