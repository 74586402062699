import { Fragment, useMemo } from "react";
import {
  Menu,
  Transition,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import classNames from "src/tools/classNames";
import { Link } from "react-router-dom";

const sampleOptions = [
  {
    label: "Edit",
    value: "edit",
  },
  {
    label: "Delete",
    value: "delete",
  },
];

/**
 * @options - an array of objects that represent the options in the dropdown
 * @onSelected - a function that is called when an option is selected
 * @icon - an icon to display as the dropdown button
 *
 * @returns - a dropdown component that allows users to select an option from a list, with a customizable icon
 */
export default function IconDropdown({
  options = [],
  onSelected = async (item) => {},
  onClick = () => {},
  icon = null,
  horizontalAlign = "start",
  verticalAlign = "bottom",
  dropdownClassName = "",
}) {
  const anchor = useMemo(() => {
    return `${verticalAlign} ${horizontalAlign}`;
  }, [horizontalAlign, verticalAlign]);
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton
          className="flex items-center text-gray-400 hover:text-gray-600"
          onClick={onClick}
        >
          <span className="sr-only">Open options</span>
          {/* <EllipsisVerticalIcon className="w-5 h-5" aria-hidden="true" /> */}
          {icon ? (
            icon
          ) : (
            <EllipsisVerticalIcon
              className="w-5 h-5 text-gray-600 hover:text-gray-400"
              aria-hidden="true"
            />
          )}
        </MenuButton>
      </div>

      {/* BUG: HeadlessUI has a known issue with this transition. See their #3476 or #3507. Reimplement when resolved */}
      {/* <Transition */}
      {/*   as={Fragment} */}
      {/*   enter="transition ease-out duration-100" */}
      {/*   enterFrom="transform opacity-0 scale-95" */}
      {/*   enterTo="transform opacity-100 scale-100" */}
      {/*   leave="transition ease-in duration-75" */}
      {/*   leaveFrom="transform opacity-100 scale-100" */}
      {/*   leaveTo="transform opacity-0 scale-95" */}
      {/* > */}
      <MenuItems
        anchor={anchor}
        className={classNames(
          "w-56 mt-2 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
          "z-10",
          dropdownClassName,
        )}
      >
        <div className="py-1">
          {options.map((item, itemIndex) => (
            <MenuItem key={itemIndex}>
              {({ active }) => (
                <Link
                  to={item.href ?? "#"}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm",
                  )}
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (!item.href) {
                      await onSelected(item);
                    }
                  }}
                >
                  {item.icon && (
                    <item.icon
                      className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  )}
                  {item.label}
                </Link>
              )}
            </MenuItem>
          ))}
        </div>
      </MenuItems>
      {/* </Transition> */}
    </Menu>
  );
}
