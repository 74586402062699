import React from 'react'
import Card from 'src/components/Card';
import InfoTooltip from 'src/components/InfoTooltip';
import StyleThemeSelector from 'src/components/UserPage/StyleThemeSelector.tsx';

type Props = {}

/**
 * Page for a user to customize the appearance of TOA.
 * Currently this is just a light/dark mode selector.
 */
export default function UserAppearancePage({ }: Props) {
  return (
    <div>
      {/* Dark Mode Selector */}
      <Card>
        <div className="flex flex-col gap-1">
          <div className="text-lg font-semibold flex gap-2 items-center">
            <p>Theme</p>
            <InfoTooltip
              // TODO: update tooltip text when system theme is implemented
              text={
                "Select your preferred theme.\n\nDark mode is currently in beta/experimental so " +
                "there may be components that are unstyled for dark mode. We appreciate your patience " +
                "and apologize for any inconvenience."
              }
            />
          </div>
          <StyleThemeSelector />
        </div>
      </Card >
    </div>
  )
}
