import React, { useState, useEffect } from 'react'
import { redirect, useLocation, useNavigate } from 'react-router-dom'
import logo from "../../assets/logos/lightMode/logo.svg";
import logo_white from "../../assets/logos/darkMode/logo_white.svg";
import toa from "../../assets/logos/lightMode/toa.svg";
import toa_white from "../../assets/logos/darkMode/toa_white.svg";
import logo_toa_under from "../../assets/logos/lightMode/logo_toa_under.svg";
import logo_toa_under_white from "../../assets/logos/darkMode/logo_toa_under_white.svg";
import toa_semi_bold from "../../assets/logos/lightMode/toa_semi-bold.svg";
import toa_semi_bold_white from "../../assets/logos/darkMode/toa_semi-bold_white.svg";
import useTheme from "../../hooks/styling/useTheme";
import Button from "../../components/input/Button";
import UserManager from "../../tools/UserManager";


/**
 * When a company requests to connect with another company, the receiving company will get an email with a link to this page.
 * This page will allow the receiving company to accept or reject the connection request.
 */
export default function AcceptRejectConnectionPage() {
  // get params from url
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const requestingCompanyId = searchParams.get('reqCID')
  const requestingCompanyName = searchParams.get('reqCName')
  const receivingCompanyId = searchParams.get('recCID')
  const receivingCompanyName = searchParams.get('recCName')
  const recipientEmail = searchParams.get('recEmail')

  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});

  const theme = useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    UserManager.getUser()
      .then((user) => {
        setUser(user.userData);
        // Get company
        if (user.userData.company) {
          UserManager.makeAuthenticatedRequest(
            "/api/company/findmy",
            "GET"
          ).then(async (res) => {
            // if logged in users company id is not equal to the receiving company id, redirect to login page
            if (res.data.company._id !== receivingCompanyId) {
              // encode the redirect url to include the query params
              const encodedRedirectUrl = encodeURIComponent("/accept-reject-connection?reqCID=" + requestingCompanyId + "&reqCName=" + requestingCompanyName + "&recCID=" + receivingCompanyId + "&recCName=" + receivingCompanyName + "&recEmail=" + recipientEmail);
              // add a redirect url to the login page url as a query param, so that the user can be redirected back to this page after logging in to the correct account
              navigate("/login?redirect=" + encodedRedirectUrl);
            }
            setCompany(res.data.company);
          });
        }
      })
      .catch((err) => {
        console.log("Error:", err)
        // redirect to login page
        navigate("/login");
      });
  }, []);


  function acceptRequest(requestingCompanyId) {
    UserManager.makeAuthenticatedRequest(
      "/api/company/connect-accept",
      "POST",
      {
        companyId: requestingCompanyId,
      }
    ).then((res) => {
      if (res.data?.status === 'ok') {
      }
      else {
        console.error("Error accepting connection request", res.data)
      }
      navigate("/app/connections")
    })
  }


  function rejectRequest(requestingCompanyId) {
    UserManager.makeAuthenticatedRequest(
      "/api/company/connect-reject",
      "POST",
      {
        companyId: requestingCompanyId,
      }
    ).then((res) => {
      if (res.data?.status === 'ok') {
      }
      else {
        console.error("Error rejecting connection request", res.data)
      }
      navigate("/app/connections")
    })
  }


  return (
    <div className="flex flex-col min-h-screen gap-2 bg-gray-200 dark:bg-gray-800">
      <div className='flex flex-col items-center justify-center gap-12 p-4 grow'>
        <img
          className='w-20'
          src={theme === "dark" ? logo_toa_under_white : logo_toa_under}
          alt="Toa"
        />
        <div className='flex flex-col items-center justify-center'>
          <div className='items-center justify-center mb-16 flex-flex-col'>
            <div className='flex flex-row items-center gap-3 mb-1'>
              <div className='text-4xl font-semibold leading-10 text-center text-gray-700 align-middle'>
                Connect with {requestingCompanyName} on
              </div>

              <img
                className='h-7'
                src={theme === "dark" ? toa_semi_bold_white : toa_semi_bold}
                alt="Toa"
              />
            </div>
            <div className='text-xl font-medium leading-7 text-center align-middle'>
              <span className='font-semibold text-primary-rose'>{requestingCompanyName}</span> is requesting to connect with <span className='font-semibold text-primary-rose'>{receivingCompanyName}</span>
            </div>
          </div>

          <div className='flex flex-col items-center justify-center w-64 gap-2 mb-8'>
            <Button
              variant='primary-green'
              onClick={() => {
                acceptRequest(requestingCompanyId)
              }}
              className='w-full !text-xl !leading-7 !py-2.5 !shadow-none'
            >
              Connect
            </Button>
            <Button
              variant=''
              onClick={() => {
                rejectRequest(requestingCompanyId)
              }}
              className='w-full !text-xl !leading-7 !py-2.5 !bg-gray-200 !text-gray-500 !border-gray-400 !border !shadow-none'
            >
              Deny
            </Button>
          </div>

          <div className='text-sm font-normal leading-5 text-center text-gray-400 align-middle'>
            You're accepting a connection sent to <span className='font-bold'>{recipientEmail}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-center gap-3 mb-4 ml-4 sm:justify-start">
        <img
          className="flex-none w-auto h-11"
          src={theme === "dark" ? logo_white : logo}
          alt="Toa"
        />
        <img
          className="w-auto h-8"
          src={theme === "dark" ? toa_white : toa}
          alt="Toa"
        />
      </div>
    </div>
  )
}
