import { CheckIcon, HomeIcon, TruckIcon } from "lucide-react";
import moment from "moment";
import React, { useContext, useState } from "react";
import Button from "src/components/input/Button";
import Input from "src/components/input/Input";
import SwitchCase from "src/components/utils/SwitchCase.tsx";
import UserContext from "src/contexts/user/UserContext.tsx";
import useS3URLs from "src/hooks/data/files/useS3.ts";
import {
  Agreement,
  AgreementStatus,
  Order,
} from "src/hooks/data/orders/useOrders.ts";
import useCurrentUser from "src/hooks/data/users/useCurrentUser.ts";
import StringHelper from "src/utils/stringHelper.ts";
import {
  AgreementBubble,
  AgreementTypes,
  getAgreementStatus,
} from "../OrderAgreementIcons.tsx";
import DeliveryDateInput, {
  dateToString,
  DeliveryDateTypes,
  getRelativeDateOption,
  timeToString,
} from "./DeliveryDateInput.tsx";

type Props = {
  order: Order;
  originalOrder: Order;
  onChange: React.Dispatch<React.SetStateAction<Order>>;

  materialNote: string;
  setMaterialNote: React.Dispatch<React.SetStateAction<string>>;

  forInstaller?: boolean; // For a distributor to handle an installer's agreement
};

/**
 * Displays 3 rows to handle order agreement changes.
 * - Delivery Date
 * - Material Availability
 * - Order Amount
 *
 *  Each row has a status bubble for the status of its agreement along with relevant actions.
 *
 *  `onChange` is called with the updated order state when an agreement is changed.
 *
 *  `originalOrder` should be the original order state before any changes were made.
 *  This is used for resetting the order state when an agreement is cancelled.
 *  This could be improved within this component to keep the original state.
 */
export default function AgreementInputRows({
  order,
  originalOrder,
  onChange,
  materialNote,
  setMaterialNote,
  forInstaller = false,
}: Props) {
  const { user } = useContext<UserContext>(UserContext);
  let companyType = user?.company?.type; // TODO: handle null company type. Loading state? Need it for agreement displays

  // If distributor is reviewing on an installer's behalf, set the company type to installer
  if (forInstaller && companyType === "distributor") {
    companyType = "installer";
  }

  let otherCompanyType = null;
  switch (companyType) {
    case "distributor":
      otherCompanyType = "installer";
      break;
    case "installer":
      otherCompanyType = "distributor";
      break;
  }

  const files = useS3URLs(
    order.files.billOfMaterials.length
      ? [order.files.billOfMaterials.at(-1).filePath]
      : [],
  );
  const bomLink = files.length ? files.at(-1).getObjectSignedUrl : null;

  const [agreements, setAgreements] = useState<Agreement>(
    order.quote.agreements,
  );

  // ----------------- //
  // --- Variables --- //
  // ----------------- //

  // Date strings
  const installDate = originalOrder.installationDate
    ? moment(originalOrder.installationDate).utc().format("ddd, MMM D")
    : "--";
  let deliveryDate = moment(originalOrder.requestedDelivery.deliveryDate)
    .utc()
    .format("ddd, MMM D @ ");
  deliveryDate += moment(
    originalOrder.requestedDelivery.deliveryWindow.start,
  ).format("h:mmA");
  deliveryDate += "-";
  deliveryDate += moment(
    originalOrder.requestedDelivery.deliveryWindow.end,
  ).format("h:mmA");

  // Bill of materials filename
  let bomName = order.files.billOfMaterials.length
    ? order.files.billOfMaterials.at(-1).filePath
    : null;
  // Get filename from filepath
  if (bomName) {
    bomName = bomName.split("/").pop();
  }

  const hasNoAmount = [
    !originalOrder.quote.value,
    companyType === "distributor",
    originalOrder.quote.agreements.amount?.distributor == null,
  ].every(Boolean);

  const quoteValueDisplay =
    order.quote.value != null
      ? Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(order.quote.value)
      : "--";

  // ----------------- //
  // --- Functions --- //
  // ----------------- //

  /**
   * Set the agreement status for the given agreement type.
   * Updates the order state.
   *
   * Example: `setAgreementStatus("deliveryDate", AgreementStatus.ACCEPTED)`
   */
  function setAgreementStatus(
    which: "deliveryDate" | "material" | "amount",
    status: AgreementStatus,
  ) {
    let o = { ...order };

    // Update the agreements
    if (!o.quote.agreements[which]) {
      o.quote.agreements[which] = {};
    }

    // Set statuses
    o.quote.agreements[which][companyType] = status;
    let otherStatus = o.quote.agreements[which][otherCompanyType];
    // If other company has rejected,
    if (otherStatus === AgreementStatus.REJECTED) {
      // If this company is accepting the adjustment, set other company to confirmed as they set the adjustment
      // If this company is not accepting (i.e. rejecting/adjusting), set other company as pending so they can review
      o.quote.agreements[which][otherCompanyType] =
        status === AgreementStatus.ACCEPTED
          ? AgreementStatus.ACCEPTED
          : AgreementStatus.PENDING;
    }
    // If other company has accepted,
    if (otherStatus === AgreementStatus.ACCEPTED) {
      // If this company is accepting, don't need to adjust other company's agreement
      // If this company is rejecting, need to set other company's to pending.
      if (status === AgreementStatus.REJECTED) {
        o.quote.agreements[which][otherCompanyType] = AgreementStatus.PENDING;
      }
    }

    onChange(o);
  }

  /**
   * Update the order's quote amount on input
   * Also updates the quote agreements
   */
  function handleQuoteAmountChange(value) {
    let o = { ...order };
    let thisCompanyAgreement = hasNoAmount
      ? AgreementStatus.ACCEPTED
      : AgreementStatus.REJECTED;
    let otherCompanyAgreement =
      value != originalOrder.quote?.amount
        ? AgreementStatus.PENDING
        : AgreementStatus.ACCEPTED;

    // If the amount is empty, set the quote to null and agreements to null
    if (value === "") {
      o.quote.value = null;
      // If no amount exists yet and input is set back to null, reset agreements to null
      // This should only happen when initially adding a quote as a distributor
      if (hasNoAmount) {
        thisCompanyAgreement = null;
        otherCompanyAgreement = null;
      }
    }
    // Set the quote value
    else {
      o.quote.value = +value;
    }

    // Update the agreements
    if (!o.quote.agreements.amount) {
      o.quote.agreements.amount = {};
    }
    o.quote.agreements.amount[companyType] = thisCompanyAgreement;
    o.quote.agreements.amount[otherCompanyType] = otherCompanyAgreement;

    onChange(o);
  }

  /**
   * Sets the relative date information based on the "<amount>-<unit>" string (e.g. "1-day")
   */
  function handleSelectedRelativeDateOptionChange(value: string) {
    if (!value) return;
    if (!order.requestedDelivery.relativeToInstall) return; // Must be relative date

    let o = { ...order };
    let [amount, unit] = value.split("-");
    o.requestedDelivery.relativeToInstall = {
      amount: +amount,
      unit: unit as "day" | "week" | "month",
    };
    onChange(o);
  }

  /**
   * Sets the requested delivery date based on the given YYYY-MM-DD string.
   */
  function setRequestedDeliveryDate(value: string) {
    let o = { ...order };
    o.requestedDelivery.deliveryDate = moment(value, "YYYY-MM-DD").toDate();
    onChange(o);
  }

  /**
   * Sets the delivery time range start based on the given HH:mm:ss.sssZ string.
   */
  function setDeliveryTimeRangeStart(value: string) {
    let o = { ...order };
    o.requestedDelivery.deliveryWindow.start = moment(
      value,
      "HH:mm:ss.sssZ",
    ).toDate();
    onChange(o);
  }

  /**
   * Sets the delivery time range end based on the given HH:mm:ss.sssZ string.
   */
  function setDeliveryTimeRangeEnd(value: string) {
    let o = { ...order };
    o.requestedDelivery.deliveryWindow.end = moment(
      value,
      "HH:mm:ss.sssZ",
    ).toDate();
    onChange(o);
  }

  /**
   * Sets the delivery date type to either relative or specified.
   * If setting to specified, removes relative date information.
   */
  function setDeliveryDateType(value: DeliveryDateTypes) {
    let o = { ...order };
    if (value === DeliveryDateTypes.RELATIVE) {
      o.requestedDelivery.deliveryDate = null;
      o.requestedDelivery.deliveryWindow = {
        start: null,
        end: null,
      };
      o.requestedDelivery.relativeToInstall = {
        amount: null,
        unit: null,
      };
    } else if (value === DeliveryDateTypes.DAY_OF) {
      o.requestedDelivery.deliveryDate = o.installationDate;
      o.requestedDelivery.deliveryWindow = {
        start: null,
        end: null,
      };
      o.requestedDelivery.relativeToInstall = {
        amount: 0,
        unit: "day",
      };
    } else {
      o.requestedDelivery.deliveryDate = null;
      o.requestedDelivery.deliveryWindow = {
        start: null,
        end: null,
      };
      o.requestedDelivery.relativeToInstall = null;
    }

    onChange(o);
  }

  /**
   * Resets the given agreement type to its initial state.
   */
  function cancelAgreementAction(
    which: "deliveryDate" | "material" | "amount",
  ) {
    let o = { ...order };
    o.quote.agreements[which].installer =
      originalOrder.quote.agreements[which].installer;
    o.quote.agreements[which].distributor =
      originalOrder.quote.agreements[which].distributor;

    // Reset values if rejected
    switch (which) {
      case "deliveryDate":
        o.requestedDelivery = structuredClone(originalOrder.requestedDelivery);
        break;
      case "material":
        setMaterialNote("");
        break;
      case "amount":
        o.quote.value = originalOrder.quote.value;
        break;
    }

    onChange(o);
  }

  return (
    <div className="grid grid-cols-[auto,auto,1fr,auto] gap-x-3 divide-y">
      {/* Delivery Date */}
      <div className="grid items-center py-3 grid-cols-subgrid col-span-full">
        <AgreementBubble
          status={getAgreementStatus(order.quote?.agreements.deliveryDate)}
          icon={AgreementTypes.DELIVERY_DATE}
        />
        <div className="text-sm font-medium">Delivery Date</div>
        <div className="grid grid-cols-[auto,1fr] gap-x-2 gap-y-1 text-gray-500 text-sm font-normal">
          <TruckIcon className="w-5 h-5 text-gray-600" />
          <div>{deliveryDate}</div>
          <HomeIcon className="w-5 h-5 text-gray-600" />
          <div>{installDate}</div>
        </div>
        <div>
          <SwitchCase test={order.quote?.agreements.deliveryDate[companyType]}>
            <div
              data-case={AgreementStatus.ACCEPTED}
              className="flex items-center justify-between gap-2"
            >
              <div className="flex items-center justify-center w-8 h-8 mr-4 rounded-full bg-primary-green">
                <CheckIcon className="w-5 h-5 text-white" />
              </div>
              <button
                type="button"
                className="text-sm font-semibold text-gray-400 hover:text-primary-green"
                onClick={() =>
                  setAgreementStatus("deliveryDate", AgreementStatus.REJECTED)
                }
              >
                Make Adjustment
              </button>
            </div>
            <div data-case={AgreementStatus.PENDING} className="flex gap-2">
              <Button
                variant="primary"
                onClick={() =>
                  setAgreementStatus("deliveryDate", AgreementStatus.ACCEPTED)
                }
              >
                Confirm
              </Button>
              <Button
                variant="secondary"
                onClick={() =>
                  setAgreementStatus("deliveryDate", AgreementStatus.REJECTED)
                }
              >
                Adjust
              </Button>
            </div>
            <div data-case={AgreementStatus.REJECTED} className="flex gap-2">
              <Button
                variant="secondary"
                onClick={() => cancelAgreementAction("deliveryDate")}
              >
                Cancel Adjustment
              </Button>
            </div>
          </SwitchCase>
        </div>
        {/* Adjustment */}
        {order.quote?.agreements.deliveryDate[companyType] ===
          AgreementStatus.REJECTED && (
          <div className="col-span-full py-3">
            <DeliveryDateInput
              requestedDeliveryDate={dateToString(
                order.requestedDelivery.deliveryDate,
              )}
              deliveryTimeRangeStart={timeToString(
                order.requestedDelivery.deliveryWindow.start,
              )}
              deliveryTimeRangeEnd={timeToString(
                order.requestedDelivery.deliveryWindow.end,
              )}
              // TODO: apply day_of option
              deliveryDateType={
                order.requestedDelivery.relativeToInstall
                  ? order.requestedDelivery.relativeToInstall.amount == 0
                    ? DeliveryDateTypes.DAY_OF
                    : DeliveryDateTypes.RELATIVE
                  : DeliveryDateTypes.SPECIFIED
              }
              selectedRelativeDateOption={getRelativeDateOption(
                order.requestedDelivery.relativeToInstall,
              )}
              installationDate={dateToString(order.installationDate)}
              onRequestedDeliveryDateChange={setRequestedDeliveryDate}
              onDeliveryTimeRangeStartChange={setDeliveryTimeRangeStart}
              onDeliveryTimeRangeEndChange={setDeliveryTimeRangeEnd}
              onDeliveryDateTypeChange={setDeliveryDateType}
              onSelectedRelativeDateOptionChange={
                handleSelectedRelativeDateOptionChange
              }
            />
          </div>
        )}
      </div>

      {/* Material */}
      <div className="grid items-center py-3 grid-cols-subgrid col-span-full">
        <AgreementBubble
          status={getAgreementStatus(order.quote?.agreements.material)}
          icon={AgreementTypes.MATERIAL}
        />
        <div className="text-sm font-medium">Material Availability</div>
        <div className="text-sm text-gray-600">
          <span className="font-semibold">BOM:</span>{" "}
          {StringHelper.truncate(bomName, 25)}{" "}
          <a
            data-disabled={bomLink == null}
            className="font-semibold cursor-pointer text-primary-green hover:text-primary-green-700 data-[disabled=true]:text-gray-300"
            href={bomLink ?? "#"}
            target="_blank"
          >
            View
          </a>
        </div>
        <div className="flex justify-end">
          <SwitchCase test={order.quote?.agreements.material[companyType]}>
            <div
              data-case={AgreementStatus.ACCEPTED}
              className="flex items-center justify-between gap-2"
            >
              <div className="flex items-center justify-center w-8 h-8 mr-4 rounded-full bg-primary-green">
                <CheckIcon className="w-5 h-5 text-white" />
              </div>
              <button
                type="button"
                className="text-sm font-semibold text-gray-400 hover:text-primary-green"
                onClick={() =>
                  setAgreementStatus("material", AgreementStatus.REJECTED)
                }
              >
                Make Adjustment
              </button>
            </div>
            <div data-case={AgreementStatus.PENDING} className="flex gap-2">
              <Button
                variant="primary"
                onClick={() =>
                  setAgreementStatus("material", AgreementStatus.ACCEPTED)
                }
              >
                Confirm
              </Button>
              <Button
                variant="secondary"
                onClick={() =>
                  setAgreementStatus("material", AgreementStatus.REJECTED)
                }
              >
                Adjust
              </Button>
            </div>
            <div data-case={AgreementStatus.REJECTED} className="flex gap-2">
              <Button
                variant="secondary"
                onClick={() => cancelAgreementAction("material")}
              >
                Cancel Adjustment
              </Button>
            </div>
          </SwitchCase>
        </div>
        {/* Adjustment */}
        {order.quote?.agreements.material[companyType] ===
          AgreementStatus.REJECTED && (
          <div className="py-3 col-span-full">
            <div className="col-span-4 text-sm">
              <Input
                label="Material Note:"
                placeholder="Add a note regarding material availability here..."
                type="textarea"
                value={materialNote}
                onChange={(value) => setMaterialNote(value)}
              />
            </div>
          </div>
        )}
      </div>

      {/* Amount */}
      <div className="grid items-center py-3 grid-cols-subgrid col-span-full">
        <AgreementBubble
          status={getAgreementStatus(order.quote?.agreements.amount)}
          icon={AgreementTypes.AMOUNT}
        />
        <div className="text-sm font-medium">Order Amount</div>
        {hasNoAmount && companyType == "distributor" ? (
          <div className="flex justify-end col-span-2 gap-4">
            <div className="flex items-center gap-2">
              {/* TODO: improve input component to have currency type */}
              <div className="text-gray-600">$</div>
              <Input
                required
                hideArrows
                type="number"
                min="0"
                step="0.01"
                placeholder="0.00"
                value={order.quote.value ?? ""}
                onChange={handleQuoteAmountChange}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="text-sm text-gray-600">
              {/* Only allow distributor to edit */}
              {order.quote?.agreements.amount?.[companyType] ===
                AgreementStatus.REJECTED && companyType === "distributor" ? (
                <div className="flex items-center gap-2">
                  <span className="text-gray-600">$</span>
                  <Input
                    required
                    hideArrows
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                    value={order.quote.value ?? ""}
                    onChange={handleQuoteAmountChange}
                  />
                </div>
              ) : (
                quoteValueDisplay
              )}
            </div>
            <SwitchCase test={order.quote?.agreements.amount?.[companyType]}>
              <div
                data-case={AgreementStatus.ACCEPTED}
                className="flex items-center justify-between gap-2"
              >
                <div className="flex items-center justify-center w-8 h-8 mr-4 rounded-full bg-primary-green">
                  <CheckIcon className="w-5 h-5 text-white" />
                </div>
                <button
                  type="button"
                  className="text-sm font-semibold text-gray-400 hover:text-primary-green"
                  onClick={() =>
                    setAgreementStatus("amount", AgreementStatus.REJECTED)
                  }
                >
                  Make Adjustment
                </button>
              </div>
              <div data-case={AgreementStatus.PENDING} className="flex gap-2">
                <Button
                  variant="primary"
                  onClick={() =>
                    setAgreementStatus("amount", AgreementStatus.ACCEPTED)
                  }
                >
                  Confirm
                </Button>
                <Button
                  variant="secondary"
                  onClick={() =>
                    setAgreementStatus("amount", AgreementStatus.REJECTED)
                  }
                >
                  {companyType === "distributor" ? "Adjust" : "Reject"}
                </Button>
              </div>
              <div
                data-case={AgreementStatus.REJECTED}
                className="flex justify-end gap-2"
              >
                <Button
                  variant="secondary"
                  onClick={() => cancelAgreementAction("amount")}
                >
                  Cancel{companyType === "distributor" ? " Adjustment" : ""}
                </Button>
              </div>
            </SwitchCase>
          </>
        )}
      </div>
    </div>
  );
}
