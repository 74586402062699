import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext.tsx";
import useOrderModals from "src/hooks/actions/useOrderModals.tsx";
import { Distributor } from "src/hooks/data/connections/distributors/useDistributors.ts";
import { Installer } from "src/hooks/data/connections/installers/useInstallers.ts";
import useS3URLs from "src/hooks/data/files/useS3.ts";
import { LocationType } from "src/hooks/data/locations/useLocations.ts";
import {
  AgreementStatus,
  Order,
  OrderStatus,
  TimestampTypes,
} from "src/hooks/data/orders/useOrders.ts";
import useCurrentUser from "src/hooks/data/users/useCurrentUser.ts";
import classNames from "src/tools/classNames";
import StringHelper from "src/utils/stringHelper.ts";
import IconDropdown from "../input/IconDropdown.js";
import OrderActionButton, { OrderAction } from "./common/OrderActionButton.tsx";
import SecondaryOrderActions from "./common/SecondaryOrderActions.tsx";
import StatusDisplay from "./common/StatusDisplay.tsx";
import OrderAgreementIcons from "./OrderAgreementIcons.tsx";

type Props = {
  order: Order;
  market?: MarketType;
  location?: LocationType;
  distributor?: Distributor;
  installer?: Installer;
  view: "rows" | "list";
  isSelected?: boolean;
  onClick?: () => void;
  onAction?: (action: OrderAction) => void;
};

/**
 * This component displays a single order in a row or list view.
 * Used in `OrderList`.
 * Row view has more data and larger items. List view is more compact.
 *
 * `SpecialStatusLabels`, `SpecialStatusVariant`, and `StatusActions` make it
 * super easy to customize the row item (in style and action) based on order input.
 */
export default function OrderRowItem({
  order,
  market,
  location,
  distributor,
  installer,
  view,
  isSelected,
  onClick = () => {},
  onAction = () => {},
}: Props) {
  const user = useCurrentUser();

  const isInstaller = user?.company?.type === "installer";
  const isDistributor = ["distributor", "account"].includes(
    user?.company?.type,
  );

  const deliveryDateString = getDeliveryDateString(order);
  const shortDateString = getDeliveryDateString(order, true);

  const installDateString = getInstallDateString(order);

  const [orderModals, runAction] = useOrderModals(order, onAction);

  // -------------- //
  // --- Consts --- //
  // -------------- //

  // TODO: improve this. This ends up doing lots of requests but combined it fails both instead of individually
  // so if no quote, then bom doesn't work
  const bomLinkArr = useS3URLs(
    order.files.billOfMaterials.at(-1)?.filePath
      ? [order.files.billOfMaterials.at(-1)?.filePath]
      : []
  );
  const quoteLinkArr = useS3URLs(
    order.quote?.file?.filePath ? [order.quote?.file?.filePath] : []
  );
  const pickTicketLinkArr = useS3URLs(
    order.files.pickTickets.at(-1)?.filePath
      ? [order.files.pickTickets.at(-1)?.filePath]
      : []
  );

  const bomLink = bomLinkArr?.length ? bomLinkArr[0].getObjectSignedUrl : null;
  const quoteLink = quoteLinkArr?.length
    ? quoteLinkArr[0].getObjectSignedUrl
    : null;
  const pickTicketLink = pickTicketLinkArr?.length
    ? pickTicketLinkArr[0].getObjectSignedUrl
    : null;

  const viewRender =
    view === "rows" ? (
      // ----------------- //
      // --- ROWS VIEW --- //
      // ----------------- //
      //
      // >=2xl Layout
      //   1fr          | 1fr           | auto     | auto   | auto  | auto | auto
      // +--------------+---------------+----------------------------------+---------------+
      // | Link/Name    | Badges        | Agreement Icons     <>      <>   | Actions       |
      // +--------------+---------------+----------+--------+-------+------+---------------+
      // | Address      | Install Date  | "BOM"    | BOM    | "PO#" | PO#  | <>            |
      // +--------------+---------------+----------+--------+-------+------+---------------+
      // | Contact Name | Delivery Date | "Quote"  | Quote  | "SO#" | SO#  | <>            |
      // +--------------+---------------+----------+--------+-------+------+---------------+
      // | Status       | Pick Ticket   | "Amount" | Amount | <>      <>   | Status Action |
      // +--------------+---------------+----------+--------+--------------+---------------+
      //
      // <2xl layout
      //   1fr          | 1fr           | auto     | auto   | auto
      // +--------------+---------------+-------------------+---------------+
      // | Link/Name    | Badges        | Agreement Icons   | Actions       |
      // +--------------+---------------+----------+--------+---------------+
      // | Address      | Install Date  | "BOM"    | BOM    | <>            |
      // +--------------+---------------+----------+--------+---------------+
      // | Contact Name | Delivery Date | "Quote"  | Quote  | <>            |
      // +--------------+---------------+----------+--------+---------------+
      // | Status       | Pick Ticket   | "Amount" | Amount | Status Action |
      // +--------------+---------------+----------+--------+---------------+
      <div
        data-disabled={isSelected}
        className={classNames(
          // Grid layout: Name/address/status, badges/dates, BOM/Quote/amount, PO#/SO# (>=2xl), actions
          "group/row grid grid-cols-subgrid col-span-full cursor-pointer gap-y-0.5",

          // Padding
          "p-4",

          // Coloring
          "bg-white hover:bg-gray-200 data-[disabled=true]:bg-primary-green-100 border-y border-200 text-gray-600",
          "dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 dark:data-[disabled=true]:bg-primary-green-700 dark:text-white"
        )}
        onClick={onClick}
      >
        {/* ----- Row 1 ----- */}

        {/* Link/Name */}
        <div className="flex items-center gap-1">
          <Link
            to={`../details/${order._id}`}
            className="relative bottom-0.5 text-primary-green cursor-pointer hover:text-primary-green-700"
          >
            <ArrowTopRightOnSquareIcon className="inline w-5 h-5 stroke-2" />
          </Link>
          <p className="text-sm font-semibold line-clamp-1">
            {order.name ?? <span className="italic">No Name</span>}
          </p>
        </div>

        {/* Badges */}
        <div className="flex gap-2">
          {isInstaller && (
            <MinorBadge>
              {market?.name ?? <span className="italic">No Market</span>}
            </MinorBadge>
          )}
          {isDistributor && (
            <MinorBadge>
              {location?.name ?? <span className="italic">No Location</span>}
            </MinorBadge>
          )}
          {distributor && <MinorBadge>{distributor.name}</MinorBadge>}
          {installer && <MinorBadge>{installer.name}</MinorBadge>}
        </div>

        {/* Agreement Icons */}
        <div className="col-span-2 2xl:col-span-4">
          <OrderAgreementIcons order={order} />
        </div>

        {/* Actions */}
        <div className="flex justify-end" onClick={(e) => e.stopPropagation()}>
          <SecondaryOrderActions order={order} onRunAction={runAction} />
        </div>

        {/* ----- Row 2 ----- */}

        {/* Address */}
        <p className="text-sm font-normal line-clamp-1">
          {addressToString(order.orderAddress)}
        </p>

        {/* Install Date */}
        <div className="text-sm font-normal">
          <span className="font-semibold">Install Date: </span>
          {installDateString}
        </div>

        {/* BOM Label */}
        <div className="text-sm font-semibold">BOM:</div>

        {/* BOM Value */}
        <p className="text-sm font-normal">
          {order.files.billOfMaterials ? (
            <a
              data-disabled={bomLink == null}
              className="line-clamp-1 cursor-pointer text-primary-green hover:text-primary-green-700 data-[disabled=true]:text-gray-300"
              href={bomLink ?? "#"}
              target="_blank"
            >
              {getFileName(order.files.billOfMaterials.at(-1)?.filePath)}
            </a>
          ) : (
            "--"
          )}
        </p>

        {/* PO# Label */}
        <div className="hidden text-sm font-normal 2xl:block">PO#:</div>

        {/* PO# Value */}
        <div className="hidden text-sm font-normal 2xl:block whitespace-nowrap">
          {order.poNumber ?? "--"}
        </div>

        {/* Spacing */}
        <div></div>

        {/* ----- Row 3 ----- */}

        {/* Contact Name */}
        <p className="text-sm font-normal line-clamp-1">
          {order.contact?.name ?? (
            <span className="italic text-gray-400">No Contact Name</span>
          )}
        </p>

        {/* Delivery Date */}
        <div className="text-sm font-normal">
          <span className="font-semibold">Delivery: </span>
          {deliveryDateString}
        </div>

        {/* Quote Label */}
        <div className="text-sm font-semibold">Quote:</div>

        {/* Quote Value */}
        <p className="text-sm font-normal">
          {order.quote?.file ? (
            <a
              data-disabled={quoteLink == null}
              className="line-clamp-1 cursor-pointer text-primary-green hover:text-primary-green-700 data-[disabled=true]:text-gray-300"
              href={quoteLink ?? "#"}
              target="_blank"
            >
              {getFileName(order.quote.file.filePath)}
            </a>
          ) : (
            "--"
          )}
        </p>

        {/* SO# Label */}
        <div className="hidden text-sm font-normal 2xl:block">SO#:</div>

        {/* SO# Value */}
        <div className="hidden text-sm font-normal 2xl:block whitespace-nowrap">
          {order.soNumber ?? "--"}
        </div>

        {/* Spacing */}
        <div></div>

        {/* ----- Row 4 ----- */}

        {/* Status Badge */}
        <StatusDisplay order={order} companyType={user?.company?.type} />

        {/* Pick Ticket */}
        <div className="text-sm font-normal">
          <span className="font-semibold">Pick Ticket: </span>
          {order.files.pickTickets && order.files.pickTickets.length ? (
            <a
              data-disabled={pickTicketLink == null}
              className="inline line-clamp-1 cursor-pointer text-primary-green hover:text-primary-green-700 data-[disabled=true]:text-gray-300"
              href={pickTicketLink ?? "#"}
              target="_blank"
            >
              {getFileName(order.files.pickTickets.at(-1)?.filePath)}
            </a>
          ) : (
            "--"
          )}
        </div>

        {/* Amount Label */}
        <div className="text-sm font-semibold">Amount:</div>

        {/* Amount Value */}
        <div className="text-sm font-normal">
          {order.quote?.value
            ? Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(order.quote.value)
            : "--"}
        </div>

        {/* Spacing */}
        <div className="hidden col-span-2 2xl:block"></div>

        {/* Action */}
        <div className="flex justify-end whitespace-nowrap">
          <OrderActionButton order={order} onRunAction={runAction} />
        </div>
      </div>
    ) : (
      // ----------------- //
      // --- LIST VIEW --- //
      // ----------------- //
      <div
        data-disabled={isSelected}
        className={classNames(
          "group/row grid grid-cols-subgrid",
          "col-span-full items-center py-1 px-2",
          "bg-white hover:bg-gray-200 data-[disabled=true]:bg-primary-green-100 border-y border-200",
          "dark:bg-gray-700 dark:hover:bg-gray-600 dark:data-[disabled=true]:bg-primary-green-700 dark:border-gray-400",
          "cursor-pointer",
          "text-sm whitespace-nowrap",
          "min-w-max"
        )}
        onClick={onClick}
      >
        {/* TODO: add TOA ID */}
        <div
          className={classNames(
            "flex items-center gap-1 sticky left-0 h-full",
            "bg-white group-hover/row:bg-gray-200 group-data-[disabled=true]/row:bg-primary-green-100 ",
            "dark:bg-gray-700 dark:group-hover/row:bg-gray-600 dark:group-data-[disabled=true]/row:bg-primary-green-700"
          )}
        >
          <Link
            to={`../details/${order._id}`}
            className="cursor-pointer text-primary-green hover:text-primary-green-700"
          >
            <ArrowTopRightOnSquareIcon className="inline w-5 h-5 stroke-2" />
          </Link>
          {order.name ? (
            <p title={order.name}>{StringHelper.truncate(order.name, 30)}</p>
          ) : (
            <span className="italic">No Name</span>
          )}
        </div>
        <StatusDisplay order={order} companyType={user?.company?.type} />
        {/* Issues */}
        <div
          className={classNames(
            "w-10 h-5 text-sm font-medium text-center text-white align-middle",
            // if there are unresolved issues that are visible to the user, show a red badge
            order.issues?.filter(
              (issue) =>
                issue.resolved === false &&
                issue.visibility.includes(user?.company?.id)
            ).length ?? 0 > 0
              ? "bg-primary-rose rounded-3xl"
              : ""
          )}
          onClick={
            // open modal
            (e) => {
              if (
                order.issues?.filter(
                  (issue) =>
                    issue.resolved === false &&
                    issue.visibility.includes(user?.company?.id)
                ).length ??
                0 > 0
              ) {
                e.stopPropagation();
                runAction(OrderAction.VIEW_ISSUES);
              }
            }
          }
        >
          {/* if there are unresolved issues that are visible to the user, show the number of issues */}
          {order.issues?.filter(
            (issue) =>
              issue.resolved === false &&
              issue.visibility.includes(user?.company?.id)
          ).length ?? 0 > 0
            ? order.issues?.filter(
                (issue) =>
                  issue.resolved === false &&
                  issue.visibility.includes(user?.company?.id)
              ).length
            : ""}
        </div>

        {isInstaller && (
          <div>{market?.name ?? <span className="italic">--</span>}</div>
        )}
        {isDistributor && (
          <div>{location?.name ?? <span className="italic">--</span>}</div>
        )}
        <div className="flex gap-1">
          {distributor && <MinorBadge>{distributor.name}</MinorBadge>}
          {installer && <MinorBadge>{installer.name}</MinorBadge>}
        </div>
        <div>{shortDateString}</div>
        <div className="">
          <OrderAgreementIcons order={order} />
        </div>
        <div className="flex items-center justify-end">
          <p className="-my-1">
            <OrderActionButton order={order} onRunAction={runAction} />
          </p>
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex items-center justify-end"
          >
            <SecondaryOrderActions order={order} onRunAction={runAction} />
          </div>
        </div>
      </div>
    );

  return (
    <>
      {viewRender}
      {orderModals}
    </>
  );
}

/**
 * Returns a formatted string for the delivery date and time range
 * of the given order object.
 * Format examples:
 * - if not short: "Monday 1/2 @ 1pm - 3pm"
 * - if short: "1/2 1-3pm"
 */
export function getDeliveryDateString(
  order: Order,
  short: boolean = false
): string {
  let date = moment(order.requestedDelivery.deliveryDate).utc(); // UTC to ignore time
  let startTime = moment(order.requestedDelivery.deliveryWindow.start); // TODO: we'll need to handle timezones better with these time ranges
  let endTime = moment(order.requestedDelivery.deliveryWindow.end);

  const dateFormat = !short ? "ddd M/D" : "M/D";
  const timeFormat = !short ? "h:mm A" : "h A";

  let dateStr = date.format(dateFormat);
  let startTimeStr = startTime.format(timeFormat);
  let endTimeStr = endTime.format(timeFormat);

  return !short
    ? `${dateStr} @ ${startTimeStr}-${endTimeStr}`
    : `${dateStr} ${startTimeStr}-${endTimeStr}`;
}

/**
 * Returns a formatted string for the date when the order was actually delivered.
 */
export function getActualDeliveryDateString(order: Order) {
  // find the most recent timestamp of type STATUS_CHANGED, with a newStatus of DELIVERED
  const deliveredTimestamp = order.timestamps.find(
    (timestamp) =>
      timestamp.type === TimestampTypes.STATUS_CHANGED &&
      timestamp.typeInfo.newStatus === OrderStatus.DELIVERED
  )?.stamp;

  // if deliveryTimestamp exists, return the string formatted date of the deliveryTimestamp, otherwise use deliveredTimestamp
  return order.deliveryTimestamp
    ? moment(order.deliveryTimestamp).format("ddd M/D @ h:mm A")
    : moment(deliveredTimestamp).format("ddd M/D @ h:mm A");
}

/**
 * Returns a formatted string for the installation date of the given order object.
 */
function getInstallDateString(order: Order) {
  if (!order.installationDate) return "--";

  let date = moment(order.installationDate).utc(); // UTC to ignore time
  return date.format("ddd M/D");
}

type MinorBadgeProps = {
  children: ReactNode;
  variant?: MinorBadgeVariant;
};
export enum MinorBadgeVariant {
  DEFAULT = "DEFAULT",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
}

/**
 * Minor badge for displaying things like tags.
 * Used for status, market/location, and connection.
 */
export function MinorBadge({
  children,
  variant = MinorBadgeVariant.DEFAULT,
}: MinorBadgeProps): JSX.Element {
  let color = "";
  switch (variant) {
    case MinorBadgeVariant.WARNING:
      color = "bg-orange-100 text-orange-700 border-orange-200";
      break;
    case MinorBadgeVariant.SUCCESS:
      color =
        "bg-primary-green-100 text-primary-green-700 border-primary-green-200";
      break;
    case MinorBadgeVariant.DEFAULT:
    default:
      color = "bg-gray-50 text-gray-700 border-gray-200";
      break;
  }

  return (
    <div
      className={classNames(
        "border text-sm font-medium rounded w-max h-max px-1",
        color
      )}
    >
      {children}
    </div>
  );
}

/**
 * Converts an address object to a string.
 * No new lines are added.
 */
export function addressToString(address: {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: number;
}): string {
  const { line1, line2, city, state, postalCode } = address;
  return `${line1}, ${
    line2 ? line2 + ", " : ""
  }${city}, ${state} ${postalCode}`;
}

/**
 * Handles getting a file name from a file path.
 * Truncates at 20 characters by default.
 */
function getFileName(filePath: string, truncate: boolean = true): string {
  let fileName = filePath.split("/").pop();
  if (truncate) {
    fileName = StringHelper.truncate(fileName, 20);
  }
  return fileName;
}
