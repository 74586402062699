// Author: Ethan Christensen
// Date: 2025-01-14
//
// This file implements the TOA nav bar that is used app wide. See the exported
// component's comments for more information on the component's functionality.
//
// I broke this file into a whole bunch of subcomponents to make it easier to
// understand and maintain. It might be a little overkill in terms of component
// count, but I think it's helpful since it's a whole bunch of CSS and JSX that
// are pretty crucial to the app's look and feel.
//
// References:
// - Tailwind UI > Sidebar Layouts: https://tailwindui.com/components/application-ui/application-shells/sidebar
// - Headless UI
//   > Dialog:     https://headlessui.com/react/dialog
//   > Transition: https://headlessui.com/react/transition

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useContext, useState } from "react";
import classNames from "src/tools/classNames";
import {
  BellAlertIcon,
  CalendarIcon,
  CalendarDaysIcon,
  ChartBarSquareIcon,
  ChartBarIcon,
  ChartPieIcon,
  ChatBubbleLeftRightIcon,
  Cog8ToothIcon,
  ExclamationTriangleIcon,
  HomeIcon,
  RectangleStackIcon,
  SparklesIcon,
  UserGroupIcon,
  Bars3BottomRightIcon,
  ShoppingCartIcon,
  ChatBubbleBottomCenterTextIcon,
  BuildingOffice2Icon,
  MapIcon,
  Square3Stack3DIcon,
  WrenchScrewdriverIcon,
  CurrencyDollarIcon,
  UserCircleIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import {
  BellAlertIcon as BellAlertIconSolid,
  CalendarIcon as CalendarIconSolid,
  CalendarDaysIcon as CalendarDaysIconSolid,
  ChartBarSquareIcon as ChartBarSquareIconSolid,
  ChartBarIcon as ChartBarIconSolid,
  ChartPieIcon as ChartPieIconSolid,
  ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconSolid,
  Cog8ToothIcon as Cog8ToothIconSolid,
  ExclamationTriangleIcon as ExclamationTriangleIconSolid,
  HomeIcon as HomeIconSolid,
  RectangleStackIcon as RectangleStackIconSolid,
  SparklesIcon as SparklesIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  ShoppingCartIcon as ShoppingCartIconSolid,
  ChatBubbleBottomCenterTextIcon as ChatBubbleBottomCenterTextIconSolid,
  BuildingOffice2Icon as BuildingOffice2IconSolid,
  MapIcon as MapIconSolid,
  Square3Stack3DIcon as Square3Stack3DIconSolid,
  WrenchScrewdriverIcon as WrenchScrewdriverIconSolid,
  CurrencyDollarIcon as CurrencyDollarIconSolid,
  UserCircleIcon as UserCircleIconSolid,
  TruckIcon as TruckIconSolid,
} from "@heroicons/react/24/solid";
import logo_toa from "src/assets/logos/lightMode/logo_toa.svg";
import logo from "src/assets/logos/lightMode/logo.svg";
import toa from "src/assets/logos/lightMode/toa.svg";
import logo_white from "src/assets/logos/darkMode/logo_white.svg";
import toa_white from "src/assets/logos/darkMode/toa_white.svg";
import SubmitFeedback from "../input/feedback/SubmitFeedback";
import useTheme from "../../hooks/styling/useTheme";
import { Link } from "react-router-dom";
import UserContext from "src/contexts/user/UserContext.tsx";
import ProfilePicture from "../users/ProfilePicture.tsx";
import { BetaBadge } from "../Badge.tsx";
import { UserContextType } from "src/contexts/user/UserContext.tsx";

type Item = {
  name: string;
  href: string;
  icon: React.ElementType;
  selectedIcon: React.ElementType;
  current: boolean;
  separator?: false;
  beta?: boolean;
};

type Separator = {
  separator: true;
  header?: string;
  num: number;
};

type NavItemType = Item | Separator;

const performance: Item = {
  name: "Performance",
  href: "performance",
  icon: ChartPieIcon,
  selectedIcon: ChartPieIconSolid,
  current: false,
};
const marketIntel: Item = {
  name: "Market Intel",
  href: "pipeline",
  icon: ChartBarIcon,
  selectedIcon: ChartBarIconSolid,
  current: false,
};
const sales: Item = {
  name: "Sales",
  href: "sales",
  icon: CurrencyDollarIcon,
  selectedIcon: CurrencyDollarIconSolid,
  current: false,
  beta: true,
};
const labor: Item = {
  name: "Labor",
  href: "labor",
  icon: WrenchScrewdriverIcon,
  selectedIcon: WrenchScrewdriverIconSolid,
  current: false,
  beta: true,
};
const materialForecast: Item = {
  name: "Material",
  href: "forecast",
  icon: Square3Stack3DIcon,
  selectedIcon: Square3Stack3DIconSolid,
  current: false,
};
const jobs: Item = {
  name: "Jobs",
  href: "jobs",
  icon: UserCircleIcon,
  selectedIcon: UserCircleIconSolid,
  current: false,
};
const schedule: Item = {
  name: "Schedule",
  href: "schedule",
  icon: CalendarDaysIcon,
  selectedIcon: CalendarDaysIconSolid,
  current: false,
};
const orders: Item = {
  name: "Orders",
  href: "orders",
  icon: ShoppingCartIcon,
  selectedIcon: ShoppingCartIconSolid,
  current: false,
};
const operationsSetup: Item = {
  name: "Operations Setup",
  href: "operations-setup",
  icon: Cog8ToothIcon,
  selectedIcon: Cog8ToothIconSolid,
  current: false,
};
const team: Item = {
  name: "Team",
  href: "team",
  icon: UserGroupIcon,
  selectedIcon: UserGroupIconSolid,
  current: false,
};
const connections: Item = {
  name: "Connections",
  href: "connections",
  icon: BuildingOffice2Icon,
  selectedIcon: BuildingOffice2IconSolid,
  current: false,
};
const alerts: Item = {
  name: "Alerts",
  href: "alerts",
  icon: BellAlertIcon,
  selectedIcon: BellAlertIconSolid,
  current: false,
};
const installerForecasts: Item = {
  name: "Installer Forecasts",
  href: "installer-forecasts",
  icon: ChartBarSquareIcon,
  selectedIcon: ChartBarSquareIconSolid,
  current: false,
};
const installerPipeline: Item = {
  name: "Installer Pipeline",
  href: "installer-pipeline",
  icon: RectangleStackIcon,
  selectedIcon: RectangleStackIconSolid,
  current: false,
};
const trucks: Item = {
  name: "Trucks",
  href: "trucks",
  icon: TruckIcon,
  selectedIcon: TruckIconSolid,
  current: false,
};
const locations: Item = {
  name: "Locations",
  href: "locations",
  icon: MapIcon,
  selectedIcon: MapIconSolid,
  current: false,
};

const navigation: {
  [companyType: string]: {
    mainNav: NavItemType[];
    lowerNav: NavItemType[];
  };
} = {
  noCompany: {
    mainNav: [],
    lowerNav: [],
  },
  installer: {
    mainNav: [
      { separator: true, header: "ANALYZE", num: 0 },
      performance,
      marketIntel,
      { separator: true, header: "PLAN", num: 1 },
      sales,
      labor,
      materialForecast,
      { separator: true, header: "EXECUTE", num: 2 },
      jobs,
      schedule,
      orders,
    ],
    lowerNav: [
      { separator: true, header: "SETTINGS", num: 0 },
      operationsSetup,
      team,
      connections,
    ],
  },
  distributor: {
    mainNav: [
      alerts,
      performance,
      { separator: true, num: 1 },
      orders,
      installerForecasts,
      installerPipeline,
    ],
    lowerNav: [
      { separator: true, header: "SETTINGS", num: 0 },
      team,
      trucks,
      locations,
      connections,
    ],
  },
  account: {
    mainNav: [orders],
    lowerNav: [
      { separator: true, header: "SETTINGS", num: 0 },
      team,
      // connections,
    ],
  },
};

const rightNavigation: NavItemType[] = [
  {
    name: "Alerts",
    href: "#",
    icon: ExclamationTriangleIcon,
    selectedIcon: ExclamationTriangleIconSolid,
    current: false,
  },
  {
    name: "Messages",
    href: "#",
    icon: ChatBubbleLeftRightIcon,
    selectedIcon: ChatBubbleLeftRightIconSolid,
    current: false,
  },
  {
    name: "People",
    href: "#",
    icon: UserGroupIcon,
    selectedIcon: UserGroupIconSolid,
    current: false,
  },
];

type Props = {
  children: React.ReactNode;
};

/**
 * Navigation component for the entire app. Handles the sidebars on small and
 * large screens. On large screens, the sidebars are always visible. On small
 * screens, the sidebars are hidden by default and slide in from the side when
 * opened.
 */
export default function ToaNav({ children }: Props): JSX.Element {
  // Big screen nav states so content can use them
  // NOTE: Small screen nav states are separate. See SmallScreenNav
  // for more info
  const [leftOpen, setLeftOpen] = useState<boolean>(false);
  const [rightOpen, setRightOpen] = useState<boolean>(false);
  const [forceLeftOpen, setForceLeftOpen] = useState<boolean>(false);
  const [forceRightOpen, setForceRightOpen] = useState<boolean>(false);

  const navProps: NavProps = {
    leftOpen,
    setLeftOpen,
    rightOpen,
    setRightOpen,

    forceLeftOpen,
    setForceLeftOpen,
    forceRightOpen,
    setForceRightOpen,
  };
  return (
    <>
      <SmallScreenNav />
      <BigScreenNav {...navProps} />
      <Content forceLeftOpen={forceLeftOpen} forceRightOpen={forceRightOpen}>
        {children}
      </Content>
    </>
  );
}

type NavProps = {
  leftOpen: boolean;
  setLeftOpen: React.Dispatch<React.SetStateAction<boolean>>;
  rightOpen: boolean;
  setRightOpen: React.Dispatch<React.SetStateAction<boolean>>;

  forceLeftOpen: boolean;
  setForceLeftOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  forceRightOpen: boolean;
  setForceRightOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Subcomponent for navigation on small screens.
 * This uses `SmallSidebar` which is only visible when opened.
 */
function SmallScreenNav({}): JSX.Element {
  // NOTE: This uses its own open states due to the nature of HeadlessUI Dialog.
  // When the small screen nav is hidden (lg:hidden), it would keep setting
  // the open states to false constantly. Having separate states for small and
  // big screens prevents this issue. There were some other potential solutions
  // that didn't seem to work (maybe due to Headless updates?).
  // Ref: https://stackoverflow.com/questions/69526971/dont-close-dialogmodal-when-click-outside-in-react-headlessui
  const [leftOpen, setLeftOpen] = useState<boolean>(false);
  const [rightOpen, setRightOpen] = useState<boolean>(false);

  // TODO: condense some of the nav logic between big and small
  const { user } = useContext(UserContext);
  const theme = useTheme();

  const companyType = user?.company?.type || "noCompany";
  const { mainNav, lowerNav } = navigation[companyType] || {
    mainNav: [],
    lowerNav: [],
  };

  return (
    <div className="block inset-0 lg:hidden">
      {/* Top Bar */}
      <div className="flex px-5 items-center justify-between h-16 border-b dark:border-gray-600 bg-gray-50 dark:bg-gray-800">
        <button onClick={() => setLeftOpen(!leftOpen)}>
          <Bars3Icon className="h-6 w-6" />
        </button>
        <div className="flex items-center gap-2">
          {/* Logo */}
          <img
            className={classNames("h-8 w-auto")}
            src={theme === "dark" ? logo_white : logo}
            alt="Toa"
          />
          {/* Header */}
          <div className="text-xl font-semibold sm:truncate sm:text-3xl sm:tracking-tight">
            {mainNav.concat(lowerNav).find((item) => item?.current)?.name}
          </div>
        </div>
        <button onClick={() => setRightOpen(!rightOpen)}>
          <Bars3Icon className="h-6 w-6" />
        </button>
      </div>

      {/* Side Bars */}
      <SmallSidebar
        position="left"
        open={leftOpen}
        setOpen={setLeftOpen}
        upperNav={mainNav}
        lowerNav={lowerNav}
        footer={
          <Link
            to="/app/user"
            data-open={leftOpen}
            className="group flex items-center gap-3"
          >
            <div className="group-data-[open=true]:-ml-1.5 ml-0 transition-all duration-200">
              <ProfilePicture size={leftOpen ? 36 : 24} />
            </div>

            <div
              className={classNames(
                "whitespace-nowrap",
                "group-data-[open=true]:visible invisible",
                "group-data-[open=true]:scale-x-100 scale-x-0 origin-left",
                "transition-all duration-200",
              )}
            >
              <p className="text-sm font-medium text-gray-700 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-gray-500">
                {user?.firstName} {user?.lastName}
              </p>
              <p className="text-xs font-medium text-gray-500 dark:text-gray-500 group-hover:text-gray-700">
                View profile
              </p>
            </div>
          </Link>
        }
      />
      <SmallSidebar
        position="right"
        open={rightOpen}
        setOpen={setRightOpen}
        // upperNav={upperRightNav}
        // lowerNav={lowerRightNav}
        footer={<SubmitFeedback wide={rightOpen} />}
      />
    </div>
  );
}

/**
 * Subcomponent for navigation on big screens.
 * This uses `BigSidebar` which is always visible.
 */
function BigScreenNav({
  leftOpen,
  setLeftOpen,
  rightOpen,
  setRightOpen,
  forceLeftOpen,
  setForceLeftOpen,
  forceRightOpen,
  setForceRightOpen,
}: NavProps): JSX.Element {
  const theme = useTheme();

  const { user } = useContext<UserContextType>(UserContext);

  const companyType = user?.company?.type || "noCompany";
  const { mainNav, lowerNav } = navigation[companyType] || {
    mainNav: [],
    lowerNav: [],
  };

  return (
    <>
      <BigSidebar
        position="left"
        open={forceLeftOpen || leftOpen}
        setOpen={(v) => {
          if (!forceLeftOpen) setLeftOpen(v);
        }}
        header={
          <button
            data-open={leftOpen}
            className="flex gap-x-2 items-center group h-full ml-2"
            onClick={() => setForceLeftOpen(!forceLeftOpen)}
          >
            <img
              className="h-6 w-auto"
              src={theme === "dark" ? logo_white : logo}
              alt="TOA Logo"
            />
            <img
              className={classNames(
                "h-4 w-auto",
                "group-data-[open=true]:visible invisible",
                "group-data-[open=true]:scale-x-100 scale-x-0 origin-left",
                "transition-all duration-200",
              )}
              src={theme === "dark" ? toa_white : toa}
              alt="TOA"
            />
          </button>
        }
        upperNav={mainNav}
        lowerNav={lowerNav}
        footer={
          <Link
            to="/app/user"
            data-open={leftOpen}
            className="group flex items-center gap-3"
          >
            <div className="group-data-[open=true]:-ml-1.5 ml-0 transition-all duration-200">
              <ProfilePicture size={leftOpen ? 36 : 24} />
            </div>

            <div
              className={classNames(
                "whitespace-nowrap",
                "group-data-[open=true]:visible invisible",
                "group-data-[open=true]:scale-x-100 scale-x-0 origin-left",
                "transition-all duration-200",
              )}
            >
              <p className="text-sm font-medium text-gray-700 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-gray-500">
                {user?.firstName} {user?.lastName}
              </p>
              <p className="text-xs font-medium text-gray-500 dark:text-gray-500 group-hover:text-gray-700">
                View profile
              </p>
            </div>
          </Link>
        }
      />
      <BigSidebar
        position="right"
        open={forceRightOpen || rightOpen}
        setOpen={setRightOpen}
        header={
          <button
            className="flex items-center justify-end p-2 w-full"
            onClick={() => setForceRightOpen(!forceRightOpen)}
          >
            <Bars3BottomRightIcon
              className="flex-shrink-0 w-6 h-6 text-gray-500 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </button>
        }
        // upperNav={upperRightNav}
        // lowerNav={lowerRightNav}
        footer={
          <SubmitFeedback
            wide={forceRightOpen || rightOpen}
            onToggleCollapsed={(collapsed) => {
              if (!collapsed) {
                setForceRightOpen(true);
              }
            }}
          />
        }
      />
    </>
  );
}

type SidebarProps = {
  position: "left" | "right";
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  header?: React.ReactNode;
  upperNav?: NavItemType[];
  lowerNav?: NavItemType[];
  footer?: React.ReactNode;
};

/**
 * Subcomponent for a small sidebar on the left or right side of the page.
 * Visible on small screens.
 * Hidden but slides in from the side on click.
 */
function SmallSidebar({
  position,
  open,
  setOpen,
  upperNav,
  lowerNav,
  footer,
}: SidebarProps): JSX.Element {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={setOpen}
      className={classNames(
        "relative z-30 lg:hidden group",
        theme === "dark" ? "dark" : "",
      )}
      data-left={position === "left"}
      data-right={position === "right"}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 flex group-data-[right=true]:justify-end">
        <DialogPanel
          transition
          className={classNames(
            "relative group-data-[left=true]:mr-16 group-data-[right=true]:ml-16 flex w-full max-w-xs flex-1",
            "transform transition duration-300 ease-in-out",
            "group-data-[left=true]:data-[closed]:-translate-x-full",
            "group-data-[right=true]:data-[closed]:translate-x-full",
          )}
        >
          <TransitionChild>
            {/* TODO: make for right pos */}
            <div className="absolute group-data-[left=true]:left-full group-data-[right=true]:right-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="-m-2.5 p-2.5"
              >
                <span className="sr-only">Close sidebar</span>
                <XMarkIcon aria-hidden="true" className="size-6 text-white" />
              </button>
            </div>
          </TransitionChild>

          {/* Panel */}
          <div className="bg-white dark:bg-gray-800 flex flex-col items-stretch w-full">
            <nav className="flex flex-col overflow-y-auto grow gap-y-1 px-2 py-3">
              {upperNav?.map((item, i) => (
                <NavItem key={i} open={open} navItem={item} />
              ))}
              {/* Spacer (-mb-1 to account for gap size) */}
              <div className="grow -mb-1"></div>
              {lowerNav?.map((item, i) => (
                <NavItem key={i} open={open} navItem={item} />
              ))}
            </nav>
            {footer && (
              <div className="p-2 border-t dark:border-gray-600">{footer}</div>
            )}
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}

/**
 * Subcomponent for a large sidebar on the left or right side of the page.
 * Visible on large screens.
 * Collapsed to show just icons by default. On hover, will expand to show text.
 */
function BigSidebar({
  position,
  open,
  setOpen,
  header,
  upperNav,
  lowerNav,
  footer,
}: SidebarProps): JSX.Element {
  return (
    <div
      data-open={open}
      className={classNames(
        // Layout
        "hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex lg:flex-col",
        // Width
        "lg:w-14 lg:data-[open=true]:w-64",
        // Animation
        "lg:transition-all lg:duration-200",
        // Position dependent
        position === "left" ? "left-0 border-r" : "right-0 border-l",
        // Colors
        "bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-600",
      )}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {header && (
        <div className="min-h-14 max-h-14 p-2 border-b dark:border-gray-600">
          {header}
        </div>
      )}
      <nav className="flex flex-col overflow-y-auto overflow-x-hidden grow gap-y-1 px-2 py-3">
        {upperNav?.map((item, i) => (
          <NavItem key={i} open={open} navItem={item} />
        ))}
        {/* Spacer (-mb-1 to account for gap size) */}
        <div className="grow -mb-1"></div>
        {lowerNav?.map((item, i) => (
          <NavItem key={i} open={open} navItem={item} />
        ))}
      </nav>
      {footer && (
        <div className="p-2 border-t dark:border-gray-600">{footer}</div>
      )}
    </div>
  );
}

type ContentProps = {
  children: React.ReactNode;
  forceLeftOpen: boolean;
  forceRightOpen: boolean;
};

/**
 * Subcomponent for the main content of the page.
 */
function Content({
  children,
  forceLeftOpen,
  forceRightOpen,
}: ContentProps): JSX.Element {
  return (
    <main
      data-left={forceLeftOpen}
      data-right={forceRightOpen}
      className={classNames(
        "lg:ml-14 lg:mr-14",
        "data-[left=true]:lg:ml-64",
        "data-[right=true]:lg:mr-64",
        "transition-all duration-200",
        "h-screen overflow-scroll flex flex-col",
      )}
    >
      {children}
    </main>
  );
}

type NavItemProps = {
  navItem: NavItemType;
  open: boolean;
};

/**
 * Subcomponent for a one item within a nav bar
 */
function NavItem({ navItem, open }: NavItemProps): JSX.Element {
  if (navItem.separator) {
    let separator = navItem as Separator;
    return (
      <div
        data-open={open}
        className={classNames(
          // "flex items-center group",
          "group relative",
        )}
      >
        <div className="absolute inset-x-0 top-1/2 border-t dark:border-gray-600" />
        <div className="flex">
          <div
            className={classNames(
              "text-sm font-semibold text-gray-500",
              "bg-white dark:bg-gray-800",
              "group-data-[open=true]:pl-1 group-data-[open=true]:pr-2 ",
              "group-data-[open=true]:visible group-data-[open=true]:w-auto invisible w-0",
              "relative group-data-[open=true]:scale-x-100 scale-x-0 origin-left",
              "transition-all duration-200",
            )}
          >
            {separator.header}
          </div>
        </div>
        {/* <div className="transition-all duration-200 w-full border-t dark:border-gray-600" /> */}
      </div>
    );
  } else {
    let item = navItem as Item;
    let path = window.location.pathname.split("/");
    // Match on item href being the second token in location pathname
    // E.g. href as "orders" and window.location.pathname as "/app/orders/list"
    if (item.href === "") {
      item.current = path.length == 2 || path[2] == "";
    } else {
      item.current = item.href === path[2];
    }
    var Icon = item.current ? item.selectedIcon : item.icon;
    return (
      <Link
        to={item.href}
        data-open={open}
        data-current={item.current}
        className={classNames(
          "group",
          "flex items-center gap-3",
          "rounded-md p-2 text-sm font-medium",
          item.current
            ? "bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white"
            : "text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-300 dark:text-gray-300",
        )}
      >
        {/* Icon */}
        <Icon
          className={classNames(
            item.current
              ? "text-gray-500 dark:text-gray-100"
              : "text-gray-400 dark:text-gray-300 group-hover:text-gray-500 dark:group-hover:text-gray-400",
            "h-6 w-6 flex-shrink-0",
          )}
        />
        {/* Text */}
        <div
          className={classNames(
            "group-data-[open=true]:visible invisible",
            "whitespace-nowrap",
            "relative group-data-[open=true]:scale-x-100 scale-x-0 origin-left",
            "transition-all duration-200",
          )}
        >
          {item.name}
        </div>
        <div
          className={classNames(
            "ml-auto",
            "group-data-[open=true]:visible invisible",
            "whitespace-nowrap",
            "relative group-data-[open=true]:opacity-100 opacity-0 origin-left",
            "transition-all duration-100",
          )}
        >
          {item.beta && <BetaBadge />}
        </div>
      </Link>
    );
  }
}
