import { useEffect, useState } from "react";
import UserManager from "src/tools/UserManager";
import useCurrentUser from "../users/useCurrentUser.ts";

export type LocationType = {
  _id: string;
  name: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    postalCode: string;
  };
};

/**
 *  A hook that returns the locations of the user's distributor.
 *
 *  Before locations are loaded, `locations` is null.
 *
 *  Simple example usage:
 *  ```tsx
 *  const [locations] = useLocations();
 *  if (!locations) return <div>Loading...</div>;
 *  return <ul>{locations.map(location => <li>{location.name}</li>)}</ul>;
 *  ```
 *
 *  Other hooks:
 *  - `useMultiLocationSelect` - If you're looking for more than one location selected at a time, use `useMultiLocationSelect`
 */
export default function useLocations(): readonly LocationType[] | null {
  const [locations, setLocations] = useState<LocationType[]>();
  const user = useCurrentUser();

  // Get locations on load
  useEffect(() => {
    // Ensure distributor or account user
    if (!user || !["distributor", "account"].includes(user.company?.type))
      return;

    UserManager.makeAuthenticatedRequest("/api/location/distributor/list")
      .then((res) => {
        if (res.data.status === "ok") {
          const locations = res.data.locations;
          setLocations(locations);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [user]);

  return locations;
}
