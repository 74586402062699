import {
  BubbleMenu,
  Editor,
  EditorContent,
  EditorProvider,
  FloatingMenu,
  useCurrentEditor,
  useEditor,
} from "@tiptap/react";
import {
  ListIcon,
  ListOrderedIcon,
  ListTodoIcon,
  TextQuoteIcon,
  FileIcon,
  ExpandIcon,
} from "lucide-react";
import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import Button from "src/components/input/Button";
import classNames from "src/tools/classNames";
import {
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  StrikethroughIcon,
  ListBulletIcon,
  NumberedListIcon,
  PhotoIcon,
  AtSymbolIcon,
  LinkIcon,
  PaperClipIcon,
  ArrowUpCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import useLocalStorage from "src/hooks/useLocalStorage.tsx";
import { ImageDisplay, FileDisplay, Separator, Btn } from "./Utils.tsx";
import Toolbar from "./Toolbar.tsx";
import Footer from "./Footer.tsx";

import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Underline from "@tiptap/extension-underline";
import Strike from "@tiptap/extension-strike";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Blockquote from "@tiptap/extension-blockquote";
import Image from "@tiptap/extension-image";
import Mention from "@tiptap/extension-mention";
import Heading from "@tiptap/extension-heading";
import Typography from "@tiptap/extension-typography";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Code from "@tiptap/extension-code";
import CodeBlock from "@tiptap/extension-code-block";
import History from "@tiptap/extension-history";
import getSuggestion from "./suggestion.ts";
import { User } from "src/hooks/data/users/useUsersByIds";
import "./TiptapStyle.css";
import S3, { S3File } from "src/tools/S3/s3.ts";

type Props = {
  placeholder?: string; // placeholder text for the editor
  useFiles?: boolean; // if true, the editor will allow users to upload files and images
  onSubmit?: (html: string, images: File[], files: File[]) => void; // callback function for when the editor is submitted
  getImages?: () => Promise<
    {
      getObjectSignedUrl: string;
      deleteObjectSignedUrl: string;
    }[]
  >; // callback function for getting images to display
  getFiles?: () => Promise<
    {
      getObjectSignedUrl: string;
      deleteObjectSignedUrl: string;
    }[]
  >; // callback function for getting files to display
  uploadImage?: (image: File) => Promise<S3File[]>; // callback function for uploading images
  uploadImagesImmediately?: boolean; // if true, images are uploaded immediately
  uploadFile?: (file: File) => Promise<S3File[]>; // callback function for uploading files
  uploadFilesImmediately?: boolean; // if true, files are uploaded immediately
  mentionList?: User[]; // list of users that can be mentioned in the editor
  readOnly?: boolean; // if true, the editor will be read only
  initialContent?: string; // initial content for the editor
  reloadTrigger?: any; // when reloadTrigger changes, images and files are reloaded
  submitButtonText?: string; // text for the submit button
  submitButton?: boolean; // if true, the submit button will be shown, defaults to true
  minimize?: boolean; // if true, when the editor is not focused, the toolbar will be hidden, defaults to false
};

// Define the ref interface
export interface TiptapEditorRef {
  submit: () => void;
}

/**
 * Rich Text Editor component using Tiptap
 *
 * Functionality:
 * - Bold, Italic, Underline, Strike
 * - Bullet List, Ordered List, Task List
 * - Blockquote
 * - Image/File Upload
 * - Links
 * - Mentions
 * - Code Block
 * - Code
 * - Heading 1, 2, 3
 * - Highlight
 * - Placeholder
 * - History
 * - Typography
 */
const TiptapEditor = forwardRef<TiptapEditorRef, Props>(
  (
    {
      reloadTrigger,
      placeholder = "Type here...",
      useFiles = true,
      getImages = () => {
        return Promise.resolve([]);
      },
      getFiles = () => {
        return Promise.resolve([]);
      },
      uploadImage = (image: File) => {
        return new Promise((resolve, reject) => {
          resolve([]);
        });
      },
      uploadImagesImmediately = true,
      uploadFile = (file: File) => {
        return new Promise((resolve, reject) => {
          resolve([]);
        });
      },
      uploadFilesImmediately = true,
      mentionList = [],
      onSubmit = (
        html: string,
        images: File[] | S3File[],
        files: File[] | S3File[]
      ) => {},
      initialContent = "<p></p>",
      readOnly = false,
      submitButtonText = "",
      submitButton = true,
      minimize = false,
    },
    ref
  ) => {
    const [showToolbar, setShowToolbar] = useLocalStorage("showToolbar", true);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<any>(null);

    // Define the extensions that are used in the editor
    const extensions = [
      Document,
      Paragraph,
      Text,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      History,

      Bold,
      Italic,
      Underline,
      Strike,

      TaskItem.configure({
        HTMLAttributes: {
          class: "flex gap-1",
        },
        nested: true,
      }),
      OrderedList,
      BulletList,
      ListItem,
      TaskList.configure({
        HTMLAttributes: {
          class: "ps-0",
        },
      }),

      Blockquote.configure({
        HTMLAttributes: {
          class: "quotes-none",
        },
      }),

      // TODO: dont use mentions for now, add them later along with notifications
      // Mention.configure({
      //   HTMLAttributes: {
      //     class: 'bg-primary-green-200 rounded-md px-1 py-0.5',
      //   },
      //   suggestion: getSuggestion(mentionList),
      //   renderHTML: (node) => {
      //     const userId = node.options.HTMLAttributes["data-id"];

      //     // Return a placeholder that will be updated when mentionList is available
      //     return [
      //       'span',
      //       {
      //         'data-type': 'mention',
      //         'data-id': userId,
      //         'class': 'bg-primary-green-200 rounded-md px-1 py-0.5'
      //       },
      //       `--`
      //     ]
      //   },
      // }),

      Heading.configure({
        levels: [1, 2, 3],
      }),
      Typography,
      Highlight, // ==Example==
      Code.configure({
        HTMLAttributes: {
          class: "rounded-md bg-gray-200 text-sm px-1 py-0.5",
        },
      }), // `Example`
      // TODO: cant figure out styling for this
      // CodeBlock,      // ```Example```

      Link,
    ];

    // Create the editor instance
    const editor = useEditor({
      extensions,
      content: initialContent,
      editable: !readOnly,
      editorProps: {
        attributes: {
          class: "prose dark:prose-invert prose-p:m-0 m-1 focus:outline-none",
        },
      },
    });

    // Expose the submit method via ref
    // allows the parent component to call the submit method of the editor
    useImperativeHandle(ref, () => ({
      submit: () => {
        if (footerRef.current) {
          footerRef.current.submitContent();
        }
      },
    }));

    // Update the mention text when the content of the editor or the mentionList changes
    useEffect(() => {
      if (editor && mentionList.length > 0) {
        // Find all mention nodes in the document
        const mentions = document.querySelectorAll('[data-type="mention"]');

        mentions.forEach((mention) => {
          const userId = mention.getAttribute("data-id");
          const user = mentionList.find((user) => user._id === userId);

          if (user) {
            mention.textContent = `${user.firstName} ${user.lastName}`;
          }
        });
      }
    }, [editor.getHTML(), mentionList]);

    // Handle clicks outside the editor container
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target as Node)
        ) {
          setIsFocused(false);
        } else {
          setIsFocused(true);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    /**
     * Handle the submission of the editor, passing the content to the onSubmit callback
     */
    function handleSubmit(images: File[] | S3File[], files: File[] | S3File[]) {
      let content: string = editor.getHTML();
      onSubmit(editor.getHTML(), images as File[], files as File[]);
      editor.commands.clearContent();
    }

    /**
     * Toggle the toolbar visibility, and save the state to local storage in the browser
     */
    function handleToolbarToggle() {
      setShowToolbar(!showToolbar);
    }

    return (
      <div
        ref={containerRef}
        className={classNames(
          readOnly
            ? ""
            : "px-2 rounded-lg border-2 dark:border-gray-500 bg-white dark:bg-gray-900 w-full flex flex-col gap-2"
        )}
      >
        {!readOnly && !(minimize && !isFocused) && (
          <Toolbar show={showToolbar} editor={editor} />
        )}
        <EditorContent editor={editor} readOnly={readOnly} />
        {!(minimize && !isFocused) && (
          <Footer
            ref={footerRef}
            readOnly={readOnly}
            reloadTrigger={reloadTrigger}
            toggleToolbar={handleToolbarToggle}
            showToolbar={showToolbar}
            editor={editor}
            onSubmit={(images, files) => handleSubmit(images, files)}
            useFiles={useFiles}
            getImages={getImages}
            getFiles={getFiles}
            uploadImage={uploadImage}
            uploadImagesImmediately={uploadImagesImmediately}
            uploadFile={uploadFile}
            uploadFilesImmediately={uploadFilesImmediately}
            submitButtonText={submitButtonText}
            submitButton={submitButton}
          />
        )}
      </div>
    );
  }
);

// Add display name for debugging
TiptapEditor.displayName = "TiptapEditor";

export default TiptapEditor;
