import React, { useContext, useEffect, useState } from 'react'
import Modal, { ModalFooter } from 'src/components/Modal';
import { Order, OrderStatusLabels } from 'src/hooks/data/orders/useOrders.ts';
import OrderModalHeader from '../common/OrderModalHeader.tsx';
import {
    CircleChevronRight,
    CircleChevronLeft,
    EyeIcon,
    CircleArrowUp,
    PencilIcon,
    Undo2Icon
} from 'lucide-react'
import {
    CheckCircleIcon
} from '@heroicons/react/20/solid'
import {
    CheckCircleIcon as CheckCircleOutlineIcon
} from '@heroicons/react/24/outline'
import classNames from 'src/tools/classNames.js';
import Button from 'src/components/input/Button.js';
import Input from 'src/components/input/Input.js';
import useCurrentUser from 'src/hooks/data/users/useCurrentUser.ts';
import UserManager from 'src/tools/UserManager.js';
import useUsersByIds from 'src/hooks/data/users/useUsersByIds.ts';
import moment from 'moment';
import { IssueSeverity, severityMap } from 'src/hooks/data/orders/useOrders.ts';
import UserContext from 'src/contexts/user/UserContext.tsx';
import useIssueTypes from 'src/hooks/data/orders/useIssues.ts';
import IconDropdown from 'src/components/input/IconDropdown.js';
import TiptapEditor from 'src/components/input/RichTextEditor/TiptapEditor.tsx';
import S3, { OrderFileType } from 'src/tools/S3/s3.ts';
import useUsersByCompany from 'src/hooks/data/users/useUsersByCompany.ts';

/**
 * Displays the details of an issue in a modal.
 * Allows for cycling through issues and replying to them.
 * Slightly different look between resolved and unresolved issues.
 */
export default function IssueDetailsModal({
    order,
    issue = null,
    open,
    setOpen,
}) {
    // get the user from the context
    const { user, company } = useContext(UserContext);

    const unresolvedIssues =
        order.issues.filter(i => !i.resolved && i.visibility.includes(company?._id))
            // sort critical severity first, then major severity, then minor severity
            .sort((a, b) => {
                if (a.severity === IssueSeverity.CRITICAL && b.severity !== IssueSeverity.CRITICAL) {
                    return -1;
                } else if (a.severity === IssueSeverity.MAJOR && b.severity === IssueSeverity.CRITICAL) {
                    return 1;
                } else if (a.severity === IssueSeverity.MAJOR && b.severity === IssueSeverity.MINOR) {
                    return -1;
                } else if (a.severity === IssueSeverity.MINOR && b.severity !== IssueSeverity.MINOR) {
                    return 1;
                }
                // sort by createdAt descending
                return b.createdAt - a.createdAt;
            })

    const [currentIssue, setCurrentIssue] = useState(issue ? issue : unresolvedIssues[0]);

    const currentIssueIndex = unresolvedIssues.findIndex(i => i._id === currentIssue?._id);

    const distributorUsers = useUsersByCompany(order.distributorId, "distributor") ?? [];
    const installerUsers = useUsersByCompany(order.installerId, "installer") ?? [];

    const users = [...distributorUsers, ...installerUsers]

    var repliedUsers = useUsersByIds(currentIssue?.replies.map(r => r.addedBy));

    useEffect(() => {
        setCurrentIssue(order.issues.find(i => i._id === issue?._id) ?? unresolvedIssues[0]);
        getIssueTypes();
    }, [order]);

    const [currentReplyValue, setCurrentReplyValue] = useState('');

    const [error, setError] = useState('');

    const [resolvingIssue, setResolvingIssue] = useState(false);
    const [resolveNotes, setResolveNotes] = useState('');

    const [reopeningIssue, setReopeningIssue] = useState(false);
    const [reopeningReply, setReopeningReply] = useState('');

    const [issueTypes, getIssueTypes] = useIssueTypes(order._id);

    /**
     * Add a reply to the current issue.
     * A reply can be a comment or a resolve.
     * 
     * @param {string} replyType - The type of reply, either 'comment' or 'resolve'
     * @param {string} reply - The reply text
     */
    async function addReply(replyType, reply) {
        UserManager.makeAuthenticatedRequest(
            '/api/orders/issue-reply',
            'POST',
            {
                orderId: order._id,
                issueId: currentIssue._id,
                replyType: replyType,
                reply: reply,
            }
        )
            .then((res) => {
                if (res.data.status === "ok") {
                    // close the modal
                    onClose();
                } else {
                    setError("There was an error replying to the issue");
                }
            })
            .catch((err) => {
                console.error(err);
                setError("There was an error replying to the issue");
            });
    }

    /**
     * Update the severity of the current issue.
     * 
     * @param {string} severity - The severity of the issue, either 'critical', 'major', or 'minor'
     */
    async function updateIssueSeverity(severity) {
        UserManager.makeAuthenticatedRequest(
            '/api/orders/update-issue-severity',
            'POST',
            { orderId: order._id, issueId: currentIssue._id, severity: severity }
        )
            .then((res) => {
                if (res.data.status === "ok") {
                    // update the current issue with the new severity
                    setCurrentIssue({ ...currentIssue, severity: severity });
                } else {
                    setError("There was an error updating the issue severity");
                }
            })
            .catch((err) => {
                console.error(err);
                setError("There was an error updating the issue severity");
            });
    }

    /**
     * Go to the next unresolved issue.
     * If the current issue is the last issue, do nothing.
     */
    function nextIssue() {
        if (currentIssueIndex < unresolvedIssues.length - 1) {
            setCurrentIssue(unresolvedIssues[currentIssueIndex + 1]);
        }
    }

    /**
     * Go to the previous unresolved issue.
     * If the current issue is the first issue, do nothing.
     */
    function prevIssue() {
        if (currentIssueIndex > 0) {
            setCurrentIssue(unresolvedIssues[currentIssueIndex - 1]);
        }
    }

    /**
     * Close the modal and reset the states
     */
    function onClose() {
        setOpen(false);
        setCurrentReplyValue('');
        setResolvingIssue(false);
        setResolveNotes('');
        setReopeningIssue(false);
        setReopeningReply('');
    }

    /**
     * Get the images for the current issue
     * 
     * @returns the get and delete presigned urls of the images
     */
    async function getImages() {
        try {
            let files = await S3.getOrderImageLinks(OrderFileType.ISSUE_IMG, order._id, currentIssue._id);
            return files;
        } catch (err) {
            console.error("error getting images", err);
            return null;
        }
    }

    /**
     * Get the files for the current issue
     * 
     * @returns the files in the issue
     */
    async function getFiles() {
        try {
            let files = await S3.getOrderImageLinks(OrderFileType.ISSUE_FILE, order._id, currentIssue._id);
            return files;
        } catch (err) {
            console.error("error getting files", err);
            return null;
        }
    }

    /**
     * Upload an image to the current issue
     * 
     * @param image - the image to upload
     * @returns the get and delete presigned urls of the image
     */
    async function uploadImage(image: File) {
        let putObjectSignedUrl = await S3.getOrderFileUploadUrl(OrderFileType.ISSUE_IMG, order._id, currentIssue._id, image.name);

        // create a blob from the image
        let blob = new Blob([image], { type: image.type });

        // convert blob to arrayBuffer
        let arrayBuffer = await blob.arrayBuffer();

        // upload image to s3
        if (putObjectSignedUrl !== "") {
            await fetch(putObjectSignedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/jpeg'
                },
                body: arrayBuffer
            })
                .catch((error) => {
                    console.error("error uploading image", error);
                    return null;
                });

            return getImages();
        }
    }

    /**
     * Upload a file to the current issue
     * 
     * @param file - the file to upload
     * @returns the get and delete presigned urls of the file
     */
    async function uploadFile(file: File) {
        const fileName = file.name;

        let putObjectSignedUrl = await S3.getOrderFileUploadUrl(OrderFileType.ISSUE_FILE, order._id, currentIssue._id, fileName);

        if (!putObjectSignedUrl) {
            return null;
        }
        
        try {
          await fetch(putObjectSignedUrl, {
            method: 'PUT',
            body: file
          })
        } catch (error) {
          console.error(error)
          return null;
        }
    
        return getFiles();
    }

    return (
        <Modal
            open={open}
            setOpen={onClose}
            wide
        >
            {currentIssue.resolved
                ? (
                    <div
                        className='w-[600px] text-lg font-semibold'
                    >
                        Resolved Issue
                    </div>
                )
                : (
                    <div
                        className='flex flex-row items-center justify-start w-[600px] gap-4'
                    >
                        <div
                            className='text-lg font-semibold'
                        >
                            Issues
                        </div>
                        <div
                            className='w-10 h-5 text-sm font-medium text-center text-white align-middle bg-primary-rose rounded-3xl'
                        >
                            {unresolvedIssues.length}
                        </div>
                    </div>
                )}
            <OrderModalHeader
                order={order}
            />
            <div
                className='flex flex-col items-start justify-start w-full py-2'
            >
                <div
                    className='flex flex-row items-center justify-between w-full gap-4 mb-4'
                >
                    {!currentIssue.resolved && (
                        <div
                            className='flex flex-row items-center justify-start gap-2 py-2'
                        >
                            <CircleChevronLeft
                                className={classNames(
                                    currentIssueIndex === 0
                                        ? '!text-gray-300'
                                        : 'text-primary-green hover:text-primary-green-400 cursor-pointer'
                                )}
                                onClick={() => {
                                    if (currentIssueIndex > 0) {
                                        prevIssue();
                                    }
                                }}
                            />
                            <div
                                className='text-sm font-medium text-nowrap'
                            >
                                {currentIssueIndex + 1} / {unresolvedIssues.length}
                            </div>
                            <CircleChevronRight
                                className={classNames(
                                    currentIssueIndex === unresolvedIssues.length - 1
                                        ? '!text-gray-300 !cursor-auto'
                                        : 'text-primary-green hover:text-primary-green-400 cursor-pointer'
                                )}
                                onClick={() => {
                                    if (currentIssueIndex < unresolvedIssues.length - 1) {
                                        nextIssue();
                                    }
                                }}
                            />
                        </div>
                    )}
                    <div
                        className='flex flex-row items-center justify-between w-full'
                    >
                        <div
                            className='flex flex-row items-center gap-3'
                        >
                            {currentIssue?.issueType && (
                                <div
                                    className='px-3 py-0.5 text-sm font-semibold text-gray-700 bg-gray-300 border border-gray-600 rounded-md min-w-32 max-w-52 text-ellipsis overflow-hidden text-nowrap'
                                >
                                    {issueTypes?.[currentIssue?.issueType]}
                                </div>
                            )}
                            {currentIssue?.severity && (
                                <div
                                    className={classNames(
                                        'flex flex-row justify-between items-center gap-1 px-3 py-0.5 rounded-md w-32 border',
                                        currentIssue.severity === IssueSeverity.CRITICAL
                                            ? 'bg-rose-100 border-primary-rose'
                                            : currentIssue.severity === IssueSeverity.MAJOR
                                                ? 'bg-orange-100 border-secondary-orange'
                                                : 'bg-gray-100 border-gray-600'
                                    )}
                                >
                                    <div
                                        className={classNames(
                                            'text-sm font-semibold text-ellipsis overflow-hidden',
                                            currentIssue?.severity === IssueSeverity.CRITICAL
                                                ? 'text-primary-rose-800'
                                                : currentIssue?.severity === IssueSeverity.MAJOR
                                                    ? 'text-secondary-orange'
                                                    : 'text-gray-700'
                                        )}
                                    >
                                        {severityMap[currentIssue?.severity]}
                                    </div>
                                    <IconDropdown
                                        options={[
                                            {
                                                label: 'Critical',
                                                value: 'critical',
                                            },
                                            {
                                                label: 'Major',
                                                value: 'major',
                                            },
                                            {
                                                label: 'Minor',
                                                value: 'minor',
                                            }
                                        ]}
                                        onSelected={async (item) => {
                                            updateIssueSeverity(item.value);
                                        }}
                                        icon={<PencilIcon className='w-4 h-4 text-gray-700 hover:text-gray-900' />}
                                        horizontalAlign=''
                                    />
                                </div>
                            )}
                        </div>
                        {currentIssue.resolved && (
                            <IconDropdown
                                options={[
                                    {
                                        label: 'Re-open Issue',
                                        value: 'reopen',
                                    }
                                ]}
                                onSelected={async (item) => {
                                    if (item.value === 'reopen') {
                                        setReopeningIssue(true);
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
                <div
                    className='flex flex-row items-center justify-start gap-1'
                >
                    <EyeIcon className='w-4 h-4 text-gray-400' />
                    <div
                        className='text-xs font-normal text-gray-400'
                    >
                        {currentIssue?.visibility.includes(order.distributorId) && currentIssue?.visibility.includes(order.installerId)
                            ? 'Visible to Both Teams'
                            : 'Internal Team Only'}
                    </div>
                </div>
                <div
                    className='py-1 text-xl font-semibold max-w-[600px]'
                >
                    {currentIssue?.description}
                </div>
                {currentIssue?.resolved && (
                    <div
                        className='flex flex-row items-start justify-start w-full gap-2 px-2 pt-2 pb-3 rounded-md hover:bg-gray-100'
                    >
                        <CheckCircleIcon
                            className='w-11 h-11 text-primary-green'
                        />
                        <div
                            className='flex flex-col items-start justify-start'
                        >
                            <div
                                className='text-xs font-semibold'
                            >
                                Resolved
                            </div>
                            <div
                                className='text-sm font-normal text-gray-800'
                            >
                                <TiptapEditor
                                    reloadTrigger={currentIssue._id}
                                    initialContent={currentIssue?.replies.filter(r => r.type === 'resolve').pop()?.reply}
                                    readOnly
                                    getImages={() => {
                                        return getImages();
                                    }}
                                    getFiles={() => {
                                        return getFiles();
                                    }}
                                    mentionList={users ?? []}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {!currentIssue.resolved && (
                    <div
                        className='w-full py-2'
                    >
                        {resolvingIssue
                            ? (
                                <div
                                    className='flex flex-row items-center justify-start w-full gap-2'
                                >
                                    <TiptapEditor
                                        reloadTrigger={currentIssue._id}
                                        getImages={() => {
                                            return getImages();
                                        }}
                                        getFiles={() => {
                                            return getFiles();
                                        }}
                                        uploadImage={(image) => {
                                            return uploadImage(image);
                                        }}
                                        uploadFile={(file) => {
                                            return uploadFile(file);
                                        }}
                                        mentionList={users ?? []}
                                        submitButtonText='Resolve'
                                        onSubmit={(html, images, files) => {
                                            addReply('resolve', html);
                                        }}
                                    />
                                </div>
                            )
                            : (
                                <Button
                                    variant='primary'
                                    onClick={
                                        () => {
                                            setResolvingIssue(true);
                                        }
                                    }
                                    disabled={currentReplyValue.length > 0}
                                >
                                    Resolve Issue
                                </Button>
                            )}
                    </div>
                )}
                <div
                    className={classNames(
                        'w-full gap-2 mb-2 overflow-y-scroll max-h-96',
                        currentIssue.resolved ? 'pl-6' : ''
                    )}
                >
                    {currentIssue?.replies.map((reply, index) => (
                        <div
                            key={index}
                            className='flex flex-col items-start justify-start w-full gap-2 px-2 py-2 rounded-md hover:bg-gray-100'
                        >
                            <div
                                className='flex flex-row items-start justify-start w-full gap-2'
                            >
                                {reply.type === 'resolve'
                                    ? (
                                        <CheckCircleOutlineIcon
                                            className='w-12 h-12 -mt-1 -ml-1 text-primary-green'
                                        />
                                    )
                                    : reply.type === 'reopen'
                                        ? (
                                            <Undo2Icon
                                                className='w-10 h-10 -mt-1 text-gray-600'
                                            />
                                        )
                                        : (
                                            <div
                                                className='flex items-center justify-center px-4 text-sm font-medium text-white bg-gray-700 rounded-full h-9 w-9'
                                            >
                                                {repliedUsers && (repliedUsers[reply.addedBy]?.firstName[0] || "") + (repliedUsers[reply.addedBy]?.lastName[0] || "")}
                                            </div>
                                        )}
                                <div
                                    className='w-full'
                                >
                                    <div
                                        className='flex flex-row items-center justify-start gap-3'
                                    >
                                        <div
                                            className='text-sm font-semibold align-middle'
                                        >
                                            {repliedUsers && (repliedUsers[reply.addedBy]?.firstName || "")}
                                            {reply.type === 'resolve' && (
                                                <span>
                                                    {` marked as Resolved`}
                                                </span>
                                            )}
                                        </div>
                                        <div
                                            className='text-xs font-normal text-gray-400 align-middle'
                                        >
                                            {/* format reply.addedAt like "7/20 1:52 pm" */}
                                            {moment(reply.addedAt).format("M/D h:mm a")}
                                        </div>
                                    </div>
                                    <div
                                        className='max-w-[400px] text-sm font-normal text-gray-500 text-wrap'
                                    >
                                        <TiptapEditor
                                            initialContent={reply.reply}
                                            readOnly
                                            mentionList={users ?? []}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {reopeningIssue && (
                    <div
                        className='flex flex-row items-center justify-start w-full gap-2 py-2 pl-6'
                    >
                        <div
                            className='grow'
                        >
                            <Input
                                placeholder='Reply'
                                value={reopeningReply}
                                onChange={value => setReopeningReply(value)}
                            />
                        </div>
                        <Button
                            variant='primary'
                            onClick={() => {
                                addReply('reopen', reopeningReply);
                            }}
                        >
                            Re-open
                        </Button>
                    </div>
                )}
                {!currentIssue.resolved && (
                    <div
                        className='flex flex-row items-start justify-start w-full gap-2 pl-6'
                    >
                        {/* get the users initials */}
                        <div
                            className='flex items-center justify-center text-sm font-medium text-white bg-gray-700 rounded-full h-9 w-9'
                        >
                            {(user?.firstName?.length > 0 && user?.lastName?.length > 0) && user?.firstName[0] + user?.lastName[0]}
                        </div>
                        <div
                            className='grow'
                        >
                            <TiptapEditor
                                placeholder='Reply'
                                mentionList={users ?? []}
                                useFiles={false}
                                onSubmit={(html, images, files) => {
                                    if (html.length > 0) {
                                        addReply('comment', html);
                                    }
                                }}
                                minimize={true}
                            />
                        </div>
                    </div>
                )}
            </div>
            <ModalFooter>
                <div
                    className='flex flex-row items-center justify-end gap-2 -my-4'
                >
                    <div
                        className='text-sm font-normal text-red-600'
                    >
                        {error}
                    </div>
                    <Button
                        variant='secondary'
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Close
                    </Button>
                </div>
            </ModalFooter>
        </Modal >
    )
}
